import {render} from "react-dom";
import {createElement} from "react";
import App from "./app";
import "./common/styles/variables.css";
import "./common/styles/style.css";
import "./styles/react-leaflet-markercluster.css";
import "./styles/react-leaflet.css";
import "./common/styles/tooltip-style.css";
import * as serviceWorker from "./serviceWorker";
import enLocaleData from "./assets/locale/en.json";
import {_ENV_MODE} from "./util/env";
// import generalJSON from "./assets/locale/general.json";
// import authJSON from "./assets/locale/auth.json";
// import contactsJSON from "./assets/locale/contacts.json";
// import companiesJSON from "./assets/locale/companies.json";
// import myCompanyJSON from "./assets/locale/my-company.json";
// import placesJSON from "./assets/locale/places.json";

/*
 * Globals
 =========================================================================================*/
window._DEV_MODE = (_ENV_MODE === 'local');

/*
 * Locale data preload
 =====================================================================================*/
//const localeData = {...generalJSON, ...authJSON, ...contactsJSON, ...companiesJSON, ...myCompanyJSON, ...placesJSON};
const localeData = {...enLocaleData};

render(
    createElement(App, {locale: 'en', localeData: localeData}),
    window.document.getElementById('root')
);
/*
 * Service worker
 ===================================================================================*/
serviceWorker.unregister();
