import React from "react";
import Tippy from "@tippyjs/react";
import Card from "../index";
import TrashIcon from "@heroicons/react/24/outline/TrashIcon";
import {ArrowDownTrayIcon} from "@heroicons/react/24/outline";
import {getProp} from "../../../util/util-helpers";
import Buttons from "../../../components/buttons";
import {isTodayDate} from "../../../util/util-dates";

export default function DocumentCard(props) {
    const description = getProp(props, "description", "");
    const fileName = getProp(props, "fileName", "");
    const category = getProp(props, "category", "");
    const date = getProp(props, "date", "");
    let fileIcon = getProp(props, "fileIcon", "auto");

    const {iconClass, buttons} = props;

    const getFileExtension = (fileName) => {
        const ext = fileName.split('.').pop();
        const documentExt = ['doc', 'docx', 'xlsx', 'txt', 'pdf', 'png', 'jpg'];
        if (documentExt.includes(ext)) {
            return ext;
        }

        return "file"
    }

    const getFileIcon = (fileIcon) => {
        fileIcon = getFileExtension(fileName);
        switch (fileIcon) {
            case "doc":
            case "docx":
                return (<svg className={iconClass ?? "text-primary-tint"} xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 384 512">
                    <path style={{fill: "currentColor"}}
                          d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm220.1-208c-5.7 0-10.6 4-11.7 9.5-20.6 97.7-20.4 95.4-21 103.5-.2-1.2-.4-2.6-.7-4.3-.8-5.1.3.2-23.6-99.5-1.3-5.4-6.1-9.2-11.7-9.2h-13.3c-5.5 0-10.3 3.8-11.7 9.1-24.4 99-24 96.2-24.8 103.7-.1-1.1-.2-2.5-.5-4.2-.7-5.2-14.1-73.3-19.1-99-1.1-5.6-6-9.7-11.8-9.7h-16.8c-7.8 0-13.5 7.3-11.7 14.8 8 32.6 26.7 109.5 33.2 136 1.3 5.4 6.1 9.1 11.7 9.1h25.2c5.5 0 10.3-3.7 11.6-9.1l17.9-71.4c1.5-6.2 2.5-12 3-17.3l2.9 17.3c.1.4 12.6 50.5 17.9 71.4 1.3 5.3 6.1 9.1 11.6 9.1h24.7c5.5 0 10.3-3.7 11.6-9.1 20.8-81.9 30.2-119 34.5-136 1.9-7.6-3.8-14.9-11.6-14.9h-15.8z"/>
                </svg>)
            case "xlsx":
                return (<svg className={iconClass ?? "text-primary-tint"} xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 384 512">
                    <path style={{fill: "currentColor"}}
                          d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm212-240h-28.8c-4.4 0-8.4 2.4-10.5 6.3-18 33.1-22.2 42.4-28.6 57.7-13.9-29.1-6.9-17.3-28.6-57.7-2.1-3.9-6.2-6.3-10.6-6.3H124c-9.3 0-15 10-10.4 18l46.3 78-46.3 78c-4.7 8 1.1 18 10.4 18h28.9c4.4 0 8.4-2.4 10.5-6.3 21.7-40 23-45 28.6-57.7 14.9 30.2 5.9 15.9 28.6 57.7 2.1 3.9 6.2 6.3 10.6 6.3H260c9.3 0 15-10 10.4-18L224 320c.7-1.1 30.3-50.5 46.3-78 4.7-8-1.1-18-10.3-18z"/>
                </svg>)
            case "pdf":
                return (<svg className={iconClass ?? "text-primary-tint"} xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 384 512">
                    <path style={{fill: "currentColor"}}
                          d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm250.2-143.7c-12.2-12-47-8.7-64.4-6.5-17.2-10.5-28.7-25-36.8-46.3 3.9-16.1 10.1-40.6 5.4-56-4.2-26.2-37.8-23.6-42.6-5.9-4.4 16.1-.4 38.5 7 67.1-10 23.9-24.9 56-35.4 74.4-20 10.3-47 26.2-51 46.2-3.3 15.8 26 55.2 76.1-31.2 22.4-7.4 46.8-16.5 68.4-20.1 18.9 10.2 41 17 55.8 17 25.5 0 28-28.2 17.5-38.7zm-198.1 77.8c5.1-13.7 24.5-29.5 30.4-35-19 30.3-30.4 35.7-30.4 35zm81.6-190.6c7.4 0 6.7 32.1 1.8 40.8-4.4-13.9-4.3-40.8-1.8-40.8zm-24.4 136.6c9.7-16.9 18-37 24.7-54.7 8.3 15.1 18.9 27.2 30.1 35.5-20.8 4.3-38.9 13.1-54.8 19.2zm131.6-5s-5 6-37.3-7.8c35.1-2.6 40.9 5.4 37.3 7.8z"/>
                </svg>)
            case "file":
                return (<svg className={iconClass ?? "text-primary-tint"} xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 384 512">
                    <path style={{fill: "currentColor"}}
                          d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48z"/>
                </svg>)
            case "jpg":
            case "png":
                return (<svg className={iconClass ?? "text-primary-tint"} xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 384 512">
                    <path style={{fill: "currentColor"}}
                          d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm32-48h224V288l-23.5-23.5c-4.7-4.7-12.3-4.7-17 0L176 352l-39.5-39.5c-4.7-4.7-12.3-4.7-17 0L80 352v64zm48-240c-26.5 0-48 21.5-48 48s21.5 48 48 48 48-21.5 48-48-21.5-48-48-48z"/>
                </svg>);
            default:
                return (<svg className={iconClass ?? "text-primary-tint"} xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 384 512">
                    <path style={{fill: "currentColor"}}
                          d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48z"/>
                </svg>)
        }
    }

    return (
        // <div className="card flex flex-row mb-3 pl-3">
        <Card addClass="py-2 px-4 overflow-hidden grow" bodyClass="">
            {props.layoutSmall ? (
                    <div className='flex justify-between items-center w-full'>
                        <div className='grow'>
                            <span className="w-6 min-w-[1.5rem] flex items-center mr-4"
                                  onClick={props.onPreviewDocument}
                            >
                                {getFileIcon(fileIcon.toLowerCase())}
                            </span>

                            <div className='flex flex-col max-w-full'>
                                <Tippy content={<p className="break-all">{fileName}</p>}>
                                    <button className="btn btn-text px-0"
                                            onClick={props.onPreviewDocument}>
                                        <p className="truncate">
                                            {fileName}
                                        </p>
                                    </button>
                                </Tippy>

                                <div className="">
                                    <div className="text-tm-gray-700">{description}</div>
                                </div>

                                <span className="">
                                    <span className="badge badge-pill badge-primary">{category}</span>
                                </span>

                                <span className="">
                                    <p className="mb-0 text-tm-gray-500 ">{isTodayDate(date, props.translate)}</p>
                                </span>
                            </div>
                        </div>

                        <div className="shrink-0 flex gap-2">
                            {!!buttons?.length && (
                                <Buttons
                                    buttons={buttons}
                                />
                            )}

                            <div>
                                <button className="btn btn-table-action m-0"
                                        title={props.translate("btn.download")}
                                        onClick={props.downloadDocument}>
                                    <ArrowDownTrayIcon className="w-5 h-5 text-tm-gray-400d"/>
                                </button>
                            </div>

                            <div className={`w-10 ${props.canNotDelete ? "hidden" : ""}`}>
                                <button className="btn btn-table-action m-0"
                                        title={props.translate("btn.delete")}
                                        onClick={props.onDeleteDocument}>
                                    <TrashIcon className="w-5 h-5 text-tm-gray-400d"/>
                                </button>
                            </div>
                        </div>
                    </div>
                ) :
                (
                    <>
                        <span className="w-6 flex items-center mr-2"
                              onClick={props.onPreviewDocument}>
                            {getFileIcon(fileIcon)}
                        </span>

                        <div className="pl-2 flex grow min-width-zero">
                            <div
                                className="card-body align-self-center flex flex-column flex-lg-row justify-between min-width-zero align-items-lg-center p-3">
                                <span
                                    className={`has-link hover w-25 w-sm-100 ${!props.onPreviewDocument ? "d-none" : ""}`}
                                    onClick={props.onPreviewDocument}>
                                    <p className="mb-0 truncate">{fileName}</p>
                                </span>

                                <span className="w-25 w-sm-100">
                                    <p className="mb-0 truncate">{description}</p>
                                </span>

                                <div className="w-20 w-sm-100">
                                    <span className="badge badge-pill badge-primary">{category}</span>
                                </div>

                                <p className="mb-0 text-tm-gray-500 text-right w-15 w-sm-100">{date}</p>
                                <p className={`mb-0 text-right w-5 w-sm-100 ${!props.onDeleteDocument ? "d-none" : ""}`}
                                   onClick={props.onDeleteDocument}>
                                    <i className="simple-icon-trash simple-icon-trash-hover p-2"/>
                                </p>
                            </div>
                        </div>
                    </>
                )
            }
        </Card>
        // </div>
    )
}
