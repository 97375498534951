import {checkPerm, classNames, getLookup, getProp, concatFullPhoneNumber, openInNewTab} from "../../../../util/util-helpers";
import {CalendarIcon, PhoneIcon} from "@heroicons/react/24/outline";
import {EllipsisVerticalIcon, EnvelopeIcon} from "@heroicons/react/24/outline";
import React, {useEffect} from "react";
import Resources from "../../../../../data/services/resources";
import PopOver from "../../../popover";
import DataCard from "../../../display-data/data-card";
import BriefcaseIcon from "@heroicons/react/20/solid/BriefcaseIcon";
import {Field} from "../../../../../data/services/fields";
import {cloneDeep} from "tailwindcss/lib/util/cloneDeep";
import PhoneArrowUpRightIcon from "@heroicons/react/24/solid/PhoneArrowUpRightIcon";
import DriverStatusBadge from "../../../badge/driver-status-badge";
import ResourceTable from "../../../resource-table";
import LocalStorage from "../../../../util/localStorage";
import {getDialogInfoResource} from "../../../../../data/actions/dialogInfoResource";
import LoaderLarge from "../../../loader/loader-large";
import CopyToClipboardButton from "../../../buttons/copy-to-clipboard-button";
import {fillFieldsFromData, includeFields} from "../../../../util/util-fields";
import TableCard from "../../../resource-table/table-components/table-card";
import ModalFooter from "../../modal-footer";
import Env from "../../../../../util/env";
import {UPDATE_PERM} from "../../../../util/util-consts";
import {getJWT} from "../../../../util/util-auth";


export default function DriverQuickCard({translate, dialogInfoResource, DriverID, onClose, dispatch}) {

    useEffect(() => {
        dispatch(getDialogInfoResource({
            user: LocalStorage.get("user"),
            resource: Resources.DriverInfo,
            query: {id: DriverID}
        }));
    }, [])

    const userData = getProp(dialogInfoResource, "data", {});
    const isLoading = getProp(dialogInfoResource, "isLoading", true);

    const phoneTypes = getLookup('PhoneType')
    const phones = userData.Phones && userData.Phones.map((phone, index) => {
        if (!index) return;
        const fullPhoneNumber = concatFullPhoneNumber(phone);

        return (
            <div key={index} className={classNames(
                "p-4 flex flex-between w-full",
                index > 1 ? "border-t border-dashed border-tm-gray-300" : undefined
            )}>
                <div>
                    <p className="font-bold leading-5">{fullPhoneNumber}</p>
                    <p className="text-tm-gray-500 text-xs font-medium leading-4">{phoneTypes[phone.PhoneTypeID]}</p>
                </div>

                <div className="ml-auto flex">
                    <CopyToClipboardButton
                        clipboardText={fullPhoneNumber}
                        translate={translate}
                    />
                    <button
                        className="btn btn-icon p-1 text-tm-gray-600 hover:text-tm-gray-900"
                        title={translate("btn.call")}
                        onClick={(e) => {
                            window.open('tel:' + fullPhoneNumber?.replace(/\D/g, ''))
                        }}
                    >
                        <PhoneArrowUpRightIcon className={"w-5 h-5"}/>
                    </button>
                </div>
            </div>
        )
    })
    const morePhones = () => {
        return phones?.length && phones.length > 1
            ? <PopOver
                className={"relative"}
                btnClass="btn-icon text-tm-gray-600 hover:text-tm-gray-900"
                widthClass={"max-w-xs"}
                positionClass="absolute translate-x-0 top-6 right-0"
                BtnIcon={EllipsisVerticalIcon}
                btnIconClass={classNames("h-4 w-4")}
            >
                <div className="bg-popup rounded-md">
                    {phones}
                </div>
            </PopOver>
            : null
    }

    const getFields = (item = {}) => {
        let fieldTemplates = {
            Email: new Field('Email', '', [], false, 'email', {icon: () => EnvelopeIcon}),
            PrivateEmail: new Field('PrivateEmail', '', [], false, "email", {icon: () => EnvelopeIcon}),
            Phone: new Field('Phone', '', [], false, "driverPhone", {
                icon: () => PhoneIcon,
                morePhones: morePhones
            }),
            DateOfBirth: new Field('DateOfBirth', '', [], false, 'date', {icon: () => CalendarIcon}),
            DriverPositionType: new Field('DriverPositionType', '', [], false, ''),
            PayTo: new Field('PayTo', '', [], false, ''),
        }

        fieldTemplates = fillFieldsFromData(fieldTemplates, item);

        return fieldTemplates;
    }

    const handleUpdateButtonClick = () => {
        openInNewTab("/drivers/info/" + DriverID)
    }

    const fields = getFields(userData);

    const Endorsement = getProp(userData, 'DriverEndorsement', []).map((item) => {
        return item
    })

    return (
        <div>
            <div className="gap-y-8 h-dialog-body">
                <div className="col-span-5 md:col-span-6 border-r bg-tm-gray-50 border-tm-gray-200 p-6">
                    <div className={classNames(isLoading ? "h-96" : undefined)}>

                    {isLoading && (
                        <LoaderLarge stripesBg/>
                    )}
                    </div>

                    {!isLoading && (
                    <div className="w-full max-w-md mx-auto text-tm-gray-700" >
                        <div className="sm:flex items-center space-x-4 mb-6">
                            <div className="flex-shrink-0">
                                {getImage(userData)}
                            </div>

                            <div className={"relative w-full"}>
                                <div className="text-2xl font-bold mr-6 text-tm-gray-900 flex">
                                    {getName(userData)}

                                    {(!!userData.PersonalTitle || !!userData.MiddleName || !!userData.Nickname) && (
                                        <PopOver
                                            className={"relative ml-auto"}
                                            btnClass="btn-icon"
                                            widthClass={"max-w-xs"}
                                            positionClass="absolute translate-x-0 right-0"
                                            BtnIcon={EllipsisVerticalIcon}
                                            btnIconClass={classNames("h-4 w-4 text-tm-gray-600")}
                                        >
                                            <div className="bg-popup rounded-md z-index-10">
                                                {getFullName(userData)}
                                            </div>
                                        </PopOver>
                                    )}
                                </div>

                                {userData.Title && (
                                    <div className="mt-1 flex items-center text-sm text-tm-gray-400">
                                        <BriefcaseIcon className="w-5 h-5 mr-2"/>
                                        <p className="text-tm-gray-500 font-medium">{userData.Title}</p>
                                    </div>
                                )}

                                <div className={"relative mx-auto top-7"}>
                                    <div className={"mx-2 text-lg font-bold leading-5"}>Status:</div>
                                    <div className={"mx-2 my-2"}>
                                        <DriverStatusBadge item={userData} translate={translate} addClass={"h-8"}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="space-y-2">
                            {Object.values(includeFields(cloneDeep(fields), ['Email', 'PrivateEmail', 'DateOfBirth', 'Phone'])).filter(it => it.type !== 'hidden' && (!!it?.value?.value || !!it.value)).map(field => {
                                return (
                                    <DataCard
                                        key={field.name}
                                        displayField={field}
                                        fieldsData={userData}
                                        translate={translate}
                                    />
                                )
                            })}

                            <div className="space-y-2">
                                {!!(fields.DriverPositionType.value || fields.PayTo.value) && (
                                    <div>
                                        <div className={"font-bold my-2"}>Info</div>
                                    </div>
                                )}

                                {Object.values(includeFields(cloneDeep(fields), ['DriverPositionType', 'PayTo'])).filter(it => it.type !== 'hidden' && (!!it?.value?.value || !!it.value)).map(field => {
                                    return (
                                        <DataCard
                                            key={field.name}
                                            displayField={field}
                                            fieldsData={userData}
                                            translate={translate}
                                        />
                                    )
                                })}
                            </div>
                        </div>

                        {Endorsement.length > 0 && (
                            <div className={"max-w-lg mx-auto my-2"}>
                                <p className="font-bold leading-5 mb-2">
                                    Endorsements
                                </p>
                                <TableCard className={"bg-inverse border-tm-gray-300 border shadow-card rounded-lg"}>
                                    <ResourceTable
                                        addClass={"rounded-card"}
                                        commonTable={true}
                                        data={Endorsement}
                                        count={Endorsement.length}
                                        isLoading={isLoading}
                                        fields={{

                                            DriverEndorsementTypeID: new Field('DriverEndorsementTypeID', '', ['empty'], false, 'select', {addContainerClass: 'col-span-full'}),
                                            Notes: new Field('Notes', '', [], false, 'textarea', {addContainerClass: 'col-span-full'}),
                                        }
                                        }
                                        translate={translate}
                                    />
                                </TableCard>
                            </div>
                        )}
                    </div>)}
                </div>
            </div>


            <ModalFooter
                buttonLabel={checkPerm(Resources.DriverInfo, UPDATE_PERM) && translate("btn.view")}
                onButtonClick={handleUpdateButtonClick}
                closeButtonLabel={translate("btn.cancel")}
                onClose={onClose}
            />
        </div>
    )
}

const getImage = (userData) => {
    if (userData.ImagePath) {
        return (
            <img
                className="inline-block h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32 bg-tm-gray-100"
                src={Env.getApiUrl("api/" + Resources.ContactImage, {
                    token: getJWT().access_token,
                    ContactID: userData.ContactID
                })}
                onLoad={() => null}
                onError={({currentTarget}) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "/images/avatar-light.png";
                }}
            />
        )
    } else {
        return (
            <img alt=""
                 className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
                // src={`/images/avatar-${ui.currentTheme}.png`}
                 src={`/images/avatar-light.png`}
            />
        )
    }
}

const getName = (item) => {
    return [item.PersonalTitle, item.FirstName, item.LastName].filter(it => !!it).join(" ");
}

const getFullName = (item) => {
    return <div>
        {!!item.PersonalTitle && (
            <div className="px-4 py-2 border-t border-dashed border-tm-gray-300">
                <p className="font-bold leading-5">
                    {item.PersonalTitle}
                </p>
                <p className="text-tm-gray-500 text-xs font-medium leading-4">Title</p>
            </div>
        )}

        <div className="px-4 py-2 border-t border-dashed border-tm-gray-300">
            <p className="font-bold leading-5">
                {[item.FirstName, item.MiddleName, item.LastName].filter(it => !!it).join(" ")}
            </p>
            <p className="text-tm-gray-500 text-xs font-medium leading-4">Full name</p>
        </div>

        {!!item.Nickname && (
            <div className="px-4 py-2 border-t border-dashed border-tm-gray-300">
                <p className="font-bold leading-5">
                    {item.Nickname}
                </p>
                <p className="text-tm-gray-500 text-xs font-medium leading-4">Nick name</p>
            </div>
        )}
    </div>
}
