import React, {Component} from "react";
import {createBrowserHistory} from "history";
import {Redirect, Route as NeutralRoute, Router, Switch} from "react-router-dom";
import Cookie from "./util/cookie";
import {_ENV_MODE} from "./util/env";
import Login from "./views/auth/login";
import NotFoundView from "./views/general/not-found";
import ResetPassword from "./views/auth/reset-password";
import ResetPasswordConfirm from "./views/auth/reset-password-confirm";
import ContactsView from "./views/contacts/contacts-view";
import GroupsView from "./views/my-company/groups-view";
import OfficesView from "./views/my-company/offices-view";
import DivisionsView from "./views/my-company/divisions-view";
import SystemUsersView from "./views/my-company/system-users-view";
import ContactPermissionsView from "./views/my-company/contact-permissions-view";
import CarriersView from "./views/companies/carriers-view";
import CustomerView from "./views/companies/customer";
import CarrierCreateView from "./views/companies/carrier-create";
import CarrierView from "./views/companies/carrier";
import LocationsView from "./views/places/locations";
import LocationZonesView from "./views/places/location-zones-view";
import DuplicatesView from "./views/places/duplicate-locations";
import TrackingView from "./views/tracking";
import TrackingSingle from "./views/tracking/tracking-single";
import AllTasksView from "./views/tasks/all-tasks";
import TasksSettingsView from "./views/tasks/tasks-settings-view";
import TaskGroupView from "./views/tasks/group-tasks";
import RepeatTasksView from "./views/tasks/repeatTasks";
import Resources from "./data/services/resources";
import ExpiryItemsView from "./views/general/expiry-items-view";
import Profiles from "./views/general/profiles";
import AccountView from "./views/general/account-view";
import LoggedDevicesView from "./views/general/logged-devices-view";
import LoggingInView from "./views/general/logging-in";
import LocalStorage from "./util/localStorage";
import {getUser} from "./util/util-auth";
import {generateCustomNotification} from "./common/util/util-helpers";
import TaskGrabData from "./common/components/layout/layout-components/task-grab-data";
import Notifications from "./common/components/notifications";
import UiToolsView from "./views/general/settings/ui-tools-view";
import IntegrationsTab from "./views/general/settings/integrations";
import TicketsView from "./views/general/tickets-view";


const PrivateRoute = ({component: Component, ...rest}) => (
    <NeutralRoute
        {...rest}
        render={props => {
            const token = Cookie.getCookie("access_token_" + _ENV_MODE);

            if (token) {
                if (!LocalStorage.has("user")) {
                    LocalStorage.set("redirect_path", props.match.url);

                    return <LoggingInView {...props} />;
                } else if (getUser("access_token") !== token) {
                    return <LoggingInView {...props} />;
                }

                return <Component {...props} />;
            }

            LocalStorage.set("redirect_path", props.match.url);
            return (
                <Redirect to={{
                    pathname: "/login",
                    state: {from: props.location}
                }}/>
            )
        }}
    />
);

const PublicRoute = ({component: Component, ...rest}) => {
    const token = Cookie.getCookie("access_token_" + _ENV_MODE);

    return (
        <NeutralRoute
            {...rest}
            render={props =>
                (!token) ? (<Component {...props}/>) : (
                    <Redirect to={{
                        pathname: "/home",
                        state: {from: props.location}
                    }}
                    />
                )
            }
        />
    )
};

class RouterListener extends Component {

    constructor(props) {
        super(props);

        this.unlisten = null;
        this.state = {}
    }

    componentDidMount() {
        this.unlisten = this.props.history.listen((location, action) => {

        });
    }

    componentWillUnmount() {
        if (this.unlisten) {
            this.unlisten();
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.props.children}
            </React.Fragment>
        );
    }
}

export default function Routes(store, translator) {
    function getComponent(Component, props) {
        return (
            <Component {...props} translate={(key, args) => translator.translate(key, args)}/>
        );
    }

    const history = createBrowserHistory();
    store.history = history;

    return (
        <Router history={history}>
            <RouterListener history={history} dispatch={store.dispatch} {...store.getState()}
                            translate={(key, args) => translator.translate(key, args)}>
                <Switch>
                    <PrivateRoute exact path="/" component={(props) => getComponent(TrackingView, props)}/>
                    <PublicRoute exact path="/logging-in" component={(props) => getComponent(LoggingInView, props)}/>
                    <PrivateRoute exact path="/home" component={(props) => getComponent(TrackingView, props)}/>

                    {/* Auth routes */}
                    <PublicRoute exact path="/login" component={(props) => getComponent(Login, props)}/>
                    <PublicRoute exact path="/reset-password"
                                 component={(props) => getComponent(ResetPassword, props)}/>
                    <NeutralRoute exact path="/reset-password-confirm/:email/:token/:is_new"
                                  component={(props) => getComponent(ResetPasswordConfirm, props)}/>

                    {/* Contact routes */}
                    <PrivateRoute exact path="/contacts" component={(props) => getComponent(ContactsView, props)}/>

                    {/* My Company routes */}
                    <PrivateRoute exact path="/offices" component={(props) => getComponent(OfficesView, props)}/>
                    <PrivateRoute exact path="/groups" component={(props) => getComponent(GroupsView, props)}/>
                    <PrivateRoute exact path="/divisions" component={(props) => getComponent(DivisionsView, props)}/>
                    <PrivateRoute exact path="/system-users"
                                  component={(props) => getComponent(SystemUsersView, props)}/>
                    <PrivateRoute exact path="/contacts/:id"
                                  component={(props) => getComponent(ContactPermissionsView, props)}/>

                    {/* Locations routes */}
                    <PrivateRoute exact path="/locations" component={(props) => getComponent(LocationsView, props)}/>
                    <PrivateRoute exact path="/location-zones"
                                  component={(props) => getComponent(LocationZonesView, props)}/>
                    <PrivateRoute exact path="/locations/duplicates"
                                  component={(props) => getComponent(DuplicatesView, props)}/>

                    {/*Task routes*/}
                    <PrivateRoute exact path="/all-tasks" component={(props) => getComponent(AllTasksView, props)}/>
                    <PrivateRoute exact path="/board-tasks" component={(props) => getComponent(TaskGroupView, props)}/>
                    <PrivateRoute exact path="/repeat-tasks"
                                  component={(props) => getComponent(RepeatTasksView, props)}/>
                    <PrivateRoute exact path="/tasks-settings"
                                  component={(props) => getComponent(TasksSettingsView, props)}/>


                    {/* Companies routes */}
                    {/*<PrivateRoute exact path="/customers" component={(props) => getComponent(CustomersView, props)}/>*/}
                    {/*<PrivateRoute exact path="/customer/create"*/}
                    {/*              component={(props) => getComponent(CustomerCreateNew, props)}/>*/}
                    {/*<PrivateRoute exact path="/vendors" component={(props) => getComponent(VendorsView, props)}/>*/}
                    {/*<PrivateRoute exact path="/vendor/create"*/}
                    {/*              component={(props) => getComponent(VendorCreateView, props)}/>*/}
                    {/*<PrivateRoute exact path="/vendors/info/:OrganizationID/:VendorID"*/}
                    {/*              component={(props) => getComponent(VendorView, props)}/>*/}

                    <PrivateRoute exact path="/carriers" component={(props) => getComponent(CarriersView, props)}/>
                    <PrivateRoute exact path="/carrier/create"
                                  component={(props) => getComponent(CarrierCreateView, props)}/>
                    <PrivateRoute exact path="/carriers/info/:OrganizationID/:id"
                                  component={(props) => getComponent(CarrierView, props)}/>
                    <PrivateRoute exact path="/customers/info/:OrganizationID/:CustomerID"
                                  component={(props) => getComponent(CustomerView, props)}/>
                    <PrivateRoute exact path="/route/:TrackingRoutesID"
                                  component={(props) => getComponent(TrackingSingle, props)}/>

                    <PrivateRoute exact path="/companies-expiry-items"
                                  component={(props) => getComponent(ExpiryItemsView, Object.assign({}, props, {resourceName: Resources.CompaniesExpiry}))}/>

                    {/*General routes*/}
                    <PrivateRoute exact path="/tools" component={(props) => getComponent(UiToolsView, props)}/>
                    <PrivateRoute exact path="/integrations-center"
                                  component={(props) => getComponent(IntegrationsTab, props)}/>
                    <PrivateRoute exact path="/ticketing" component={(props) => getComponent(TicketsView, props)}/>
                    <PrivateRoute exact path="/my-profile" component={(props) => getComponent(Profiles, props)}/>
                    <PrivateRoute exact path="/account" component={(props) => getComponent(AccountView, props)}/>
                    <PrivateRoute exact path="/logged-devices"
                                  component={(props) => getComponent(LoggedDevicesView, props)}/>
                    <NeutralRoute component={(props) => getComponent(NotFoundView, props)}/>
                </Switch>

                <Notifications
                    history={history} dispatch={store.dispatch} {...store.getState()}
                    translate={(key, args) => translator.translate(key, args)}
                    generateNotificationBaseOnTitle={generateCustomNotification}
                />

                <TaskGrabData
                    dispatch={store.dispatch}
                />

            </RouterListener>
        </Router>
    );
}
