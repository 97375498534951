import React, {Component} from 'react'
import {connect} from 'react-redux'
import LocalStorage from '../../util/localStorage'
import {
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    returnOffsetAndPagination,
    saveTableColumns,
    timePickerValueToServerTime,
} from '../../util/util'
import {Field, FieldsManager} from '../../data/services/fields'
import PageHeader from "../../common/components/layout/layout-components/page/page-header";
import ActiveFilters from "../../common/components/resource-table/table-components/active-filters";
import NoRecordsTable from "../../common/components/no-records-found/no-records-table";
import ModalConfirm from "../../common/components/modal/modal-confirm";
import {CREATE_PERM, DEFAULT_CRUD_STATE} from '../../util/util-constants'
import {checkPerm, classNames, getProp, mergeDeep} from '../../common/util/util-helpers'
import {cloneDeep} from '../../common/util/util-vanilla'
import PageHeaderInfo from "../../common/components/layout/layout-components/page/page-header-info";
import Pagination from "../../common/components/resource-table/table-components/pagination";
import Page from "../../common/components/layout/layout-components/page";
import TableSettingsPopOver from "../../common/components/resource-table/table-components/table-settings-popover";
import TableOptionsDialog from "../../common/components/resource-table/table-components/table-options-dialog";
import Layout from "../../common/components/layout";
import TableCard from "../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../common/components/resource-table/table-components/table-filters";
import ResourceTable from "../../common/components/resource-table";
import {createResource, deleteResource, getResource} from "../../data/actions/resource";
import ModalSaveResource from "../../common/components/modal/modal-save-resource";
import Resources from "../../data/services/resources";
import TableCardFooter from "../../common/components/resource-table/table-components/table-card-footer";


class TrackingView extends Component {
    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1)

        this.tablePageDefaults = {
            behaviour: {
                rowSelect: true,
                canAdjustWidth: true,
                hasMenu: true
            },
            style: {
                condensed: true,
                isGPUAccelerated: true
            }
        }

        this.state = {
            // Defaults and filters
            ...DEFAULT_CRUD_STATE,
            sortBy: 'LoadNumber',
            fields: this.getFields(),
            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate),
            queryFilterFields: this.getQueryFilterFields(),
            prefilled: '',
            isColumnsDialogVisible: false,

            // Dialogs
            confirmDialog: false,
            confirmDialogHideAnimation: false,
            breakpoint: {},
            isTableFullScreen: false,
            createTracking: false
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
        this.autoRefreshTimeout = undefined;
        this.autoRefresTime = 60000;
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }));

        if (this.state.isAutoRefreshEnabled) {
            clearTimeout(this.autoRefreshTimeout);
            this.autoRefreshTimeout = setTimeout(this.autoFetchData, this.autoRefresTime);
        }
    }

    autoFetchData = () => {
        this.setState({
            isAutoFetching: true
        }, () => {
            this.fetchData();
        });
    }

    archiveResource = (item) => {
        this.setState({text: `${this.props.translate('message.confirm_archive_generic')} ${item.TrackingRoutesID}?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(deleteResource({
                        user: LocalStorage.get('user'),
                        query: Object.assign({}, {id: item.TrackingRoutesID}),
                        piggyQuery: this.getQuery(),
                        errorMessage: true,
                        successMessage: this.props.translate(`message.archived_generic`, [item.TrackingRoutesID]),
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    // restoreFromArchive = (item) => {
    //     this.setState({text: `${this.props.translate('message.confirm_restore_generic')} ${item.LegalName}?`}, () => {
    //         this.handleToggleConfirmDialog(() => {
    //             this.setState({confirmDialog: false}, () => {
    //                 this.props.dispatch(updateResource({
    //                     user: LocalStorage.get('user'),
    //                     params: {
    //                         id: item.CustomerID,
    //                         ArchivedDate: 1
    //                     },
    //                     query: this.getQuery(),
    //                     errorMessage: true, successMessage: `Customer ${item.SCAC} restored`,
    //                     resource: this.getResource(),
    //                     piggyResource: this.getResource()
    //                 }))
    //             })
    //         }, item)
    //     })
    // }

    /** UI Events
     ================================================================= */
    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        }, () => {
            if (this.state.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
                this.handleFilterInputChange('limit', 10);
            }
        });
    }

    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        let sort = (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        if (!sortBy) {
            sortBy = 'LoadNumber'
        }
        this.setState({
            sortBy: sortBy,
            sort: sort
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleCreateUpdateResource = (item = null) => {
        if (item) {
            this.handleLinkClick(`/route/${item.TrackingRoutesID}`)
        } else {
            this.setState({createTracking: true})
        }
    }

    handleLinkClick = (link) => {
        this.props.history.push(link)
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            isColumnsDialogVisible: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate);
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    handleFullScreenToggle = () => {
        this.setState({
            isTableFullScreen: !this.state.isTableFullScreen
        })
    }

    toggleAutoRefresh = () => {
        this.setState({
                isAutoRefreshEnabled: !this.state.isAutoRefreshEnabled
            }, () => {
                this.saveFilters();

                if (this.state.isAutoRefreshEnabled) {
                    this.autoRefreshTimeout = setTimeout(this.autoFetchData, this.autoRefresTime);
                }
            }
        )
    }

    closeCreateTracking = () => {
        this.setState({createTracking: false})
    }

    /** Helpers
     ================================================================= */
    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields)
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            archived: new Field('archived', '', [''], false, 'checkbox'),
            limit: new Field('limit', 10, [''], false, 'select', {hideLabel: true}, {menuPlacement: "top"})
        }
    }

    getFields = () => {
        return {
            LoadNumber: new Field('LoadNumber', '', [''], false, 'text'),
            PickupCity: new Field('PickupCity', '', [''], false, 'text'),
            PickupState: new Field('PickupState', '', [''], false, 'text'),
            PickupPostalCode: new Field('PickupPostalCode', '', []),
            PickupDate: new Field('PickupDate', '', [], false, 'date'),

            DestinationCity: new Field('DestinationCity', '', [''], false, 'text'),
            DestinationState: new Field('DestinationState', '', [''], false, 'text'),
            DestinationPostalCode: new Field('DestinationPostalCode', '', []),
            DestinationDate: new Field('DestinationDate', '', [], false, 'date'),

            DriverName: new Field('DriverName', '', [''], false, "text"),
            Carrier: new Field('Carrier', '', [''], false, 'text'),
            LastDetectedAddress: new Field('LastDetectedAddress', '', [''], false, "text")
        }
    }

    getFieldsCreate = () => {
        return {
            LoadNumber: new Field('LoadNumber', '', ['empty'], false, 'text', {addContainerClass: "col-span-7"}),
            PickupLocationID: new Field('PickupLocationID', '', ['empty'], false, 'select-search', {addContainerClass: "col-span-7"}),


            PickupDate: new Field('PickupDate', "", ['empty'], false, 'date', {
                label: "PickupDate",
                addContainerClass: "col-span-6",
                formLabelClass: "flex items-center text-sm font-semibold text-tm-gray-900 whitespace-nowrap"
            }, {isClearable: false, addClass: "rounded-r-none", isCloseFocusable: false}),
            PickupDateTime: new Field('PickupDateTime', "", ['empty'], false, 'time-custom', {
                hideLabel: true,
                warnings: {},
                addContainerClass: "col-span-6 relative right-10 top-2.5",
                formLabelClass: "flex items-start text-sm font-semibold text-tm-gray-900 h-2.5"
            }, {addClass: "rounded-l-none"}),
            PickupEndDate: new Field('PickupEndDate', "", [''], false, 'date', {
                label: "PickupEndDate",
                addContainerClass: "col-span-6",
                formLabelClass: "flex items-center text-sm font-semibold text-tm-gray-900 whitespace-nowrap"
            }, {isClearable: false, addClass: "rounded-r-none", isCloseFocusable: false}),
            PickupEndDateTime: new Field('PickupEndDateTime', "", [''], false, 'time-custom', {
                hideLabel: true,
                warnings: {},
                addContainerClass: "col-span-6 relative right-10 top-2.5",
                formLabelClass: "flex items-start text-sm font-semibold text-tm-gray-900 h-2.5"
            }, {addClass: "rounded-l-none"}),
            DestinationDate: new Field('DestinationDate', "", ['empty'], false, 'date', {
                label: "DestinationDate",
                addContainerClass: "col-span-6",
                formLabelClass: "flex items-center text-sm font-semibold text-tm-gray-900 whitespace-nowrap"
            }, {isClearable: false, addClass: "rounded-r-none", isCloseFocusable: false}),
            DestinationDateTime: new Field('DestinationDateTime', "", ['empty'], false, 'time-custom', {
                hideLabel: true,
                warnings: {},
                addContainerClass: "col-span-6 relative right-10 top-2.5",
                formLabelClass: "flex items-start text-sm font-semibold text-tm-gray-900 h-2.5"
            }, {addClass: "rounded-l-none"}),
            DestinationEndDate: new Field('DestinationEndDate', "", [''], false, 'date', {
                label: "DestinationEndDate",
                addContainerClass: "col-span-6",
                formLabelClass: "flex items-center text-sm font-semibold text-tm-gray-900 whitespace-nowrap"
            }, {isClearable: false, addClass: "rounded-r-none", isCloseFocusable: false}),
            DestinationEndDateTime: new Field('DestinationEndDateTime', "", [''], false, 'time-custom', {
                hideLabel: true,
                warnings: {},
                addContainerClass: "col-span-6 relative right-10 top-2.5",
                formLabelClass: "flex items-start text-sm font-semibold text-tm-gray-900 h-2.5"
            }, {addClass: "rounded-l-none"}),
            DestinationLocationID: new Field('DestinationLocationID', '', ['empty'], false, 'select-search', {addContainerClass: "col-span-7"}),
            DriverName: new Field('DriverName', '', ['empty'], false, "text", {addContainerClass: "col-span-6"}),
            DriverPhone: new Field('DriverPhone', '', ['empty'], false, "text", {addContainerClass: "col-span-6"}),
            CarrierID: new Field('CarrierID', '', ['empty'], false, 'select-search', {addContainerClass: "col-span-7"}),
            InternalNotes: new Field('InternalNotes', '', [''], false, "textarea", {addContainerClass: "col-span-full"}),
            ExternalNotesDriverCarrier: new Field('ExternalNotesDriverCarrier', '', [''], false, "textarea", {addContainerClass: "col-span-full"}),
        }
    }

    saveFilters = () => {
        let filters = []
        Object.keys(this.getQuery()).map(it => filters.push(it))
        LocalStorage.persistState(this.pagePath, this.state, filters)
    }

    getResource = () => {
        return Resources.TrackingRoutes
    }

    getPrimaryKey = () => {
        return 'TrackingRoutesID'
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props
        const data = getProp(resource.data, 'list', [])

        const count = getProp(resource.data, 'count', 0)
        const isLoading = !!resource.isLoading;

        return (
            <Layout
                customPageTitle={"Home"}
                onBreakpointChange={this.handleBreakpointChange}
                {...this.props}
            >
                <Page>
                    <PageHeader
                        title={translate('page.heading.tracking')}
                        buttonLabel={translate('btn.create_new')}
                        onButtonClick={() => this.handleCreateUpdateResource()}
                        buttonHidden={false}
                        afterTitle={(
                            <PageHeaderInfo
                                dispatch={this.props.dispatch}
                            />
                        )}
                    />

                    <div className="flex">
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleClearFiltersClick}
                            translate={translate}
                        />

                        <div className="ml-auto flex">
                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>
                    </div>

                    <TableCard
                        addClass={
                            classNames(
                                this.state.isTableFullScreen
                                    ? "fixed inset-1 -top-1 z-50"
                                    : undefined
                            )}
                    >
                        <TableFilters
                            hideLimit
                            filterFields={this.state.queryFilterFields}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                            onRefreshTable={this.fetchData}
                            onFullScreenToggle={this.handleFullScreenToggle}
                            isTableFullScreen={this.state.isTableFullScreen}
                            isLoading={isLoading}
                            toggleAutoRefresh={this.toggleAutoRefresh}
                            isAutoRefreshEnabled={this.state.isAutoRefreshEnabled}
                        />

                        <ResourceTable
                            data={data}
                            fields={this.getFields()}
                            verticalTableIsVisible={this.state.breakpoint.index <= 1}

                            translate={translate}
                            isLoading={resource.isLoading}

                            options={this.state.tableOptions}

                            limit={this.state.queryFilterFields.limit.value}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}
                            saveTableOptions={this.setOptions}

                            onRowClick={true ? this.handleCreateUpdateResource : null}

                            onView={true ? this.handleShowDetails : null}
                            onEdit={true ? this.handleCreateUpdateResource : null}
                            onDelete={true ? this.archiveResource : null}
                            // onRestore={checkPerm(this.getResource(), UPDATE_PERM) ? this.restoreFromArchive : null}

                            tableKey={this.getPrimaryKey()}
                        />

                        {/*Table footer*/}
                        <TableCardFooter
                            show={!(!data.length && !resource.isLoading)}
                        >

                            <Pagination
                                count={count}
                                isLoading={resource.isLoading}
                                hideRowsPerPage={this.state.breakpoint.index <= 1}
                                handleQueryChange={
                                    (name, value, currentPage) => name === "offset"
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                            />
                        </TableCardFooter>


                        <NoRecordsTable
                            show={(data.length === 0) && !resource.isLoading}
                            canCreate={checkPerm(this.getResource(), CREATE_PERM)}
                            title={translate('text.no_matching_records')}
                            text={translate('text.create_new_resource', [this.state.queryFilterFields.query.value])}
                            btnLabel={translate('btn.create_new')}
                            onBtnClick={() => {
                                this.handleCreateUpdateResource()
                            }}
                            onClearFilterClick={this.handleClearFiltersClick}
                            clearFilterBtnLabel={translate('text.clear_all_filters')}
                            clearFilterText={translate('text.try_without_filters')}
                            filters={this.state.queryFilterFields}
                        />
                    </TableCard>

                    <ModalSaveResource
                        title={translate("text.creat_load_tracking")}
                        widthClass="max-w-lg"
                        gridColsClass="grid-cols-12"
                        show={this.state.createTracking}
                        onClose={this.closeCreateTracking}
                        fields={this.getFieldsCreate(this.state.selectedItem)}
                        metadata={{
                            PickupLocationID: {
                                api: 'api/' + Resources.LocationsQuick,
                                query: {},
                                searchMap: (it) => ({
                                    label: it.LocationName,
                                    value: it.LocationID,
                                    metadata: it
                                })
                            },
                            DestinationLocationID: {
                                api: 'api/' + Resources.LocationsQuick,
                                query: {},
                                searchMap: (it) => ({
                                    label: it.LocationName,
                                    value: it.LocationID,
                                    metadata: it
                                })
                            },
                            CarrierID: {
                                api: 'api/' + Resources.CarriersQuick,
                                query: {},
                                searchMap: (item) => ({
                                    value: item.CarrierID,
                                    label: item.LegalName,
                                    metaData: item
                                })
                            }
                        }
                        }
                        onSubmit={(params) => {
                            if (params) {
                                params.PickupDate = params.PickupDate.split(" ")[0] + " " + timePickerValueToServerTime(params.PickupDateTime)
                                params.PickupEndDate = params.PickupEndDate.split(" ")[0] + " " + timePickerValueToServerTime(params.PickupEndDateTime)
                                params.DestinationDate = params.DestinationDate.split(" ")[0] + " " + timePickerValueToServerTime(params.DestinationDateTime)
                                params.DestinationEndDate = params.DestinationEndDate.split(" ")[0] + " " + timePickerValueToServerTime(params.DestinationEndDateTime)
                                delete params.DestinationDateTime
                                delete params.DestinationEndDateTime
                                delete params.PickupDateTime
                                delete params.PickupEndDateTime
                                this.props.dispatch(createResource({
                                    user: LocalStorage.get('user'),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: this.getResource(),
                                    piggyResource: this.getResource(),
                                    errorMessage: true, successMessage: translate('text.tracking_route_created')
                                }))

                                this.closeCreateTracking();
                            }
                        }}
                        translate={translate}
                    />

                    <ModalConfirm
                        title={'Confirm'}
                        show={!!this.state.confirmDialog}
                        text={this.state.text}
                        onClose={() => this.setState({confirmDialog: false})}
                        buttonLabel={translate('btn.confirm')}
                        closeButtonLabel={'Cancel'}
                        translate={translate}
                        onConfirm={this.state.confirmModal}
                    />

                    <TableOptionsDialog
                        show={this.state.isColumnsDialogVisible}
                        pagePath={this.pagePath}
                        columns={this.state.tableOptions.columns}
                        setTableColumnOptions={this.handleSetTableColumnOptions}
                        getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                        onClose={this.handleToggleColumnSettingsDialog}
                        translate={translate}
                    />
                </Page>
            </Layout>
        )
    }
}

export default connect(state => state)(TrackingView)
