import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {
    checkPerm,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getProp,
    mergeDeep,
    returnOffsetAndPagination,
    saveTableColumns,
} from '../../../common/util/util-helpers'
import {cloneDeep} from '../../../common/util/util-vanilla'
import BoardMembersModal from '../all-tasks/dialogs/board-members-modal'
import {
    createSecondResource,
    deleteSecondResource,
    getSecondResource,
    updateSecondResource,
} from "../../../data/actions/secondResource";
import {getThirdResource} from "../../../data/actions/thirdResource";
import PencilIcon from "@heroicons/react/24/outline/PencilIcon";
import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DEFAULT_QUERY_LIMIT,
    DELETE_PERM,
    UPDATE_PERM
} from "../../../util/util-constants";
import {Field, FieldsManager} from "../../../data/services/fields";
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import Layout from "../../../common/components/layout";
import ResourceTable from "../../../common/components/resource-table";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import Modal from "../../../common/components/modal";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import {getUser} from "../../../common/util/util-auth";

class TaskGroupView extends Component {

    constructor(props) {
        super(props)

        this.pagePath = this.props.location.pathname.substring(1)

        this.state = {
            // Defaults and filters
            ...DEFAULT_CRUD_STATE,
            fields: this.getFields(),
            tableOptions: getDefaultTableOptions(this.getFields(), {}, this.pagePath, this.props.translate),
            queryFilterFields: this.getQueryFilterFields(),
            prefilled: '',
            isColumnsDialogVisible: false,

            // Dialogs
            confirmDialog: false,
            confirmDialogHideAnimation: false,

            boardMembersModalOpen: false,
            Members: [],
            breakpoint: {}
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
        this.setCurrentUser()
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    setCurrentUser = () => {
        let currentUser = getUser('Contact')

        currentUser = {
            ContactID: getProp(currentUser, 'ContactID', 0),
            FirstName: getProp(currentUser, 'FirstName', ''),
            LastName: getProp(currentUser, 'LastName', ''),
            Email: getProp(currentUser, 'Email', ''),
            IsCurrentUser: true,
            GroupMemberTypeID: 2
        }
        this.setState({Members: [currentUser]})

        return currentUser
    }
    deleteResource = (item) => {
        this.setState({text: this.props.translate('message.confirm_delete_generic', [item.TaskGroupName])}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(deleteResource({
                        user: LocalStorage.get('user'),
                        query: Object.assign({}, {id: item[this.getPrimaryKey()]}),
                        piggyQuery: this.getQuery(),
                        errorMessage: true,
                        successMessage: this.props.translate(`message.archived_generic`, [item.TaskGroupName]),
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    /** UI Events
     ================================================================= */
    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        }, () => {
            if (this.state.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
                this.handleFilterInputChange('limit', 10);
            }
        });
    }

    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog,
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleToggleCreateModal = () => {
        this.setState({
            selectedItem: null,
            boardMembersModalOpen: !this.state.boardMembersModalOpen,
            Members: [this.setCurrentUser()]
        })
    }

    handleToggleBoardMembersModal = (item = null) => {
        this.setState({
            selectedItem: item,
            boardMembersModalOpen: !this.state.boardMembersModalOpen
        })
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            isColumnsDialogVisible: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate)
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }
    /** Helpers
     ================================================================= */
    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {}, {}),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {menuPlacement: "top"})
        }
    }

    getFields = (item = null) => {
        const fieldTemplates = {
            TaskGroupName: new Field('TaskGroupName', this.state?.queryFilterFields?.query?.value ?? '', ['empty'], false, 'custom', {
                addContainerClass: 'relative col-span-6',
                render: (item) => {
                    return (
                        <span>{item.TaskGroupName} ({item.MemberCount})</span>
                    )
                }
            }),
            TaskGroupDesc: new Field('TaskGroupDesc', '', ['empty'], false, 'textarea', {
                addContainerClass: 'relative col-span-6',
                limitWidth: true

            }),
        }
        return fillFieldsFromData(fieldTemplates, item)
    }

    saveFilters = () => {
        let filters = []
        Object.keys(this.getQuery()).map(it => filters.push(it))
        LocalStorage.persistState(this.pagePath, this.state, filters)
    }

    getResource = () => {
        return Resources.BoardTasks
    }

    getPrimaryKey = () => {
        return 'TaskGroupID'
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource, thirdResource, secondResource} = this.props
        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)
        const membersData = getProp(secondResource.data, 'list', [])

        return (
            <Layout
                onBreakpointChange={this.handleBreakpointChange}
                isAccessible={!(resource.errorStatus === 2)}
                {...this.props}
            >
                <Page>
                    {/** Header **/}
                    <PageHeader
                        title={translate('page.heading.TaskGroup')}
                        buttonLabel={translate('btn.create_new')}
                        onButtonClick={this.handleToggleCreateModal}
                        hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                        afterTitle={(
                            <PageHeaderInfo
                                dispatch={this.props.dispatch}
                            />
                        )}
                    />

                    <div className="flex">
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleClearFiltersClick}
                            translate={translate}
                        />

                        <div className="ml-auto flex">
                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>
                    </div>

                    {/** Table **/}
                    <TableCard addClass={'relative'}>
                        <TableFilters
                            hideLimit
                            filterFields={this.state.queryFilterFields}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                            onRefreshTable={this.fetchData}
                        />

                        <ResourceTable
                            data={data}
                            fields={this.getFields()}
                            verticalTableIsVisible={this.state.breakpoint.index <= 1}

                            translate={translate}
                            isLoading={resource.isLoading}

                            options={this.state.tableOptions}

                            limit={this.state.queryFilterFields.limit.value}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onRowClick={checkPerm(Resources.TaskGroupMembers, UPDATE_PERM) ? this.handleToggleBoardMembersModal : null}
                            onDelete={this.deleteResource}
                            hasDeletePerm={checkPerm(this.getResource(), DELETE_PERM)}

                            actions={[
                                {
                                    action: (item) => this.handleToggleBoardMembersModal(item),
                                    icon: PencilIcon,
                                    hasPerm: checkPerm(this.getResource(), UPDATE_PERM)
                                },
                            ]}
                        />

                        <TableCardFooter
                            show={!(!data.length && !resource.isLoading)}
                        >
                            <Pagination
                                count={count}
                                isLoading={resource.isLoading}
                                hideRowsPerPage={this.state.breakpoint.index <= 1}
                                handleQueryChange={
                                    (name, value, currentPage) => name === "offset"
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                            />
                        </TableCardFooter>

                        <NoRecordsTable
                            show={(data.length === 0) && !resource.isLoading}
                            canCreate={checkPerm(this.getResource(), CREATE_PERM)}
                            title={translate('text.no_matching_records')}
                            text={translate('text.create_new_resource', [this.state.queryFilterFields.query.value])}
                            btnLabel={translate('btn.quick_create_new')}
                            onBtnClick={() => {
                                this.setState({prefilled: this.state.queryFilterFields.query?.value}, () => {
                                    this.handleToggleCreateModal()
                                })
                            }}
                            onClearFilterClick={this.handleClearFiltersClick}
                            clearFilterBtnLabel={translate('text.clear_all_filters')}
                            clearFilterText={translate('text.try_without_filters')}
                            filters={this.state.queryFilterFields}
                        />
                    </TableCard>

                    <TableOptionsDialog
                        show={this.state.isColumnsDialogVisible}
                        pagePath={this.pagePath}
                        columns={this.state.tableOptions.columns}
                        setTableColumnOptions={this.handleSetTableColumnOptions}
                        getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                        onClose={this.handleToggleColumnSettingsDialog}
                        translate={translate}
                    />

                    {/** CRUD dialogs **/}
                    <Modal
                        show={this.state.boardMembersModalOpen}
                        onClose={this.handleToggleBoardMembersModal}
                        widthClass={"max-w-7xl"}
                    >
                        {this.state.boardMembersModalOpen && !this.state.selectedItem && (
                            <BoardMembersModal
                                onTabChange={(currentTab) => this.setState({currentTab: currentTab})}
                                ExcludeIDs={getProp(this.state, 'Members', []).map(it => it.ContactID)}
                                contactsResource={thirdResource}
                                membersResource={{
                                    isLoading: false,
                                    resource: '',
                                    ...(this.state.currentTab === 'Members' && {
                                        data: {
                                            list: getProp(this.state, 'Members', []),
                                            count: getProp(this.state, 'Members', []).length
                                        }
                                    }),
                                }}
                                dispatch={this.props.dispatch}
                                translate={translate}
                                onClose={this.handleToggleCreateModal}

                                onFetchContacts={(params, groupMemberTypeID) => {
                                    this.props.dispatch(getThirdResource({
                                        user: LocalStorage.get('user'),
                                        resource: params.resource,
                                        query: Object.assign({}, params.query, {GroupMemberTypeID: groupMemberTypeID})
                                    }))
                                }
                                }

                                onAddMembers={(params) => {
                                    let groupMemberType = 'Members'
                                    const newArr = Object.values(params.query.contactRows)
                                        .filter(it => !this.state[groupMemberType].some(contact => contact.ContactID === it.ContactID))
                                    this.setState({[groupMemberType]: [...this.state[groupMemberType], ...newArr]})
                                }}

                                ChangeGroupMembersTypes={(it, type) => {
                                    let GroupMemberType = 2;
                                    if (type === 'Watcher') {
                                        GroupMemberType = 1;
                                    } else if (type === 'FullMember') {
                                        GroupMemberType = 2;
                                    }
                                    this.setState({
                                        Members: this.state.Members.map(item => {
                                            return Object.assign({}, item,
                                                it[item.ContactID] ? {GroupMemberTypeID: GroupMemberType} : {}
                                            )
                                        })
                                    })
                                }}

                                onDeleteMembers={(params) => {
                                    const updatedMembers = this.state.Members.filter((contact) => !params.query.IDs.includes(contact.ContactID.toString()))
                                    this.setState({
                                        Members: updatedMembers
                                    })
                                }}

                                onCreateNewBoard={(params) => {
                                    params.Members = this.state.Members.map((it) => {
                                        return {"ContactID": it.ContactID, "GroupMemberTypeID": it.GroupMemberTypeID}
                                    })
                                    this.props.dispatch(createResource({
                                        user: LocalStorage.get('user'),
                                        params: params,
                                        resource: Resources.BoardTasks,
                                        piggyResource: Resources.BoardTasks,
                                        query: params,
                                        errorMessage: true, successMessage: 'Board created',
                                    }))
                                    this.handleToggleBoardMembersModal(null)
                                    this.setState({
                                        Members: []
                                    })
                                }}
                            />
                        )}

                        {this.state.boardMembersModalOpen && !!this.state.selectedItem && (
                            <BoardMembersModal
                                onClose={this.handleToggleBoardMembersModal}
                                selectedItem={this.state.selectedItem}
                                translate={translate}
                                dispatch={this.props.dispatch}
                                membersResource={secondResource}
                                contactsResource={thirdResource}
                                onFetchMembers={(params) => {
                                    this.props.dispatch(getSecondResource({
                                        user: LocalStorage.get('user'),
                                        resource: params.resource,
                                        query: Object.assign({}, params.query, {
                                            TaskGroupID: this.state.selectedItem.TaskGroupID
                                        })
                                    }))
                                }}
                                onFetchContacts={(params, groupMemberTypeID) => {
                                    this.props.dispatch(getThirdResource({
                                        user: LocalStorage.get('user'),
                                        resource: params.resource,
                                        query: Object.assign({}, params.query, {
                                            TaskGroupID: this.state.selectedItem.TaskGroupID,
                                            GroupMemberTypeID: groupMemberTypeID
                                        })
                                    }))
                                }}
                                onDeleteMembers={(params) => {
                                    this.props.dispatch(deleteSecondResource({
                                        user: LocalStorage.get('user'),
                                        resource: params.resource,
                                        query: Object.assign({}, params.query, {
                                            TaskGroupID: this.state.selectedItem.TaskGroupID
                                        }),
                                        secondPiggyQuery: Object.assign({}, params.piggyQuery, {
                                            TaskGroupID: this.state.selectedItem.TaskGroupID
                                        }),
                                        secondPiggyResource: params.piggyResource
                                    }))
                                }}
                                onAddMembers={(params) => {
                                    this.props.dispatch(createSecondResource({
                                        user: LocalStorage.get('user'),
                                        resource: params.resource,
                                        params: Object.assign({}, params.query, {
                                            TaskGroupID: this.state.selectedItem.TaskGroupID
                                        }),
                                        query: Object.assign({}, params.piggyQuery, {
                                            TaskGroupID: this.state.selectedItem.TaskGroupID
                                        }),
                                        piggyResource: params.piggyResource
                                    }))
                                }}

                                ChangeGroupMembersTypes={(it, type, query) => {
                                    let GroupMemberType = 2;
                                    if (type === 'Watcher') {
                                        GroupMemberType = 1;
                                    } else if (type === 'FullMember') {
                                        GroupMemberType = 2;
                                    }

                                    let Members = membersData.map(item => {
                                        let subset;
                                        if (it[item.ContactID]) {
                                            subset = {
                                                ContactID: item.ContactID,
                                                GroupMemberTypeID: GroupMemberType
                                            }
                                        }

                                        return subset
                                    })

                                    let filteredMembers = Members.filter(item => item)

                                    this.props.dispatch(updateSecondResource({
                                        user: LocalStorage.get('user'),
                                        resource: Resources.TaskGroupMembers,
                                        params: Object.assign({}, {
                                            Members: filteredMembers,
                                            TaskGroupID: this.state.selectedItem.TaskGroupID
                                        }),
                                        query: Object.assign({}, query, {
                                            TaskGroupID: this.state.selectedItem.TaskGroupID
                                        }),
                                        piggyResource: Resources.TaskGroupMembers
                                    }))


                                }}


                                onEditTaskGroup={(params) => {
                                    if (params) {
                                        params[this.getPrimaryKey()] = this.state.selectedItem[this.getPrimaryKey()]
                                        this.props.dispatch(updateResource({
                                            user: LocalStorage.get('user'),
                                            query: params.query,
                                            params: params.query,
                                            resource: params.resource,
                                            piggyResource: params.piggyResource,
                                            errorMessage: true,
                                            successMessage: 'Board updated.'
                                        }));
                                        this.handleToggleBoardMembersModal()
                                    }
                                }}
                            />
                        )}
                    </Modal>

                    {!!this.state.confirmModal && (
                        <ModalConfirm
                            title={'Confirm delete'}
                            show={!!this.state.confirmDialog}
                            text={this.state.text}
                            onClose={() => this.setState({confirmDialog: false})}
                            buttonLabel={translate('btn.confirm')}
                            closeButtonLabel={'Cancel'}
                            translate={translate}
                            onConfirm={this.state.confirmModal}
                        />
                    )}
                </Page>
            </Layout>
        )
    }
}

export default connect(state => state)(TaskGroupView)
