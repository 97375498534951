import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {
    checkPerm,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getProp,
    mergeDeep,
    returnOffsetAndPagination,
    saveTableColumns
} from '../../../common/util/util-helpers'
import {Field, FieldsManager} from '../../../data/services/fields'
import {CREATE_PERM, DEFAULT_CRUD_STATE, DELETE_PERM, UPDATE_PERM} from '../../../util/util-constants'
import {cloneDeep} from '../../../common/util/util-vanilla'
import Layout from "../../../common/components/layout";
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ResourceTable from "../../../common/components/resource-table";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import {fillFieldsFromData} from "../../../common/util/util-fields";

class LocationZonesView extends Component {
    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1)

        this.state = {
            // Defaults and filters
            ...DEFAULT_CRUD_STATE,

            sortBy: '',
            fields: this.getFields(),
            queryFilterFields: this.getQueryFilterFields(),
            columns: Object.keys(this.getFields()),
            prefilled: '',
            isColumnsDialogVisible: false,

            tableOptions: getDefaultTableOptions(this.getFields(), {}, this.pagePath, this.props.translate),

            // Dialogs
            selectedItem: null,
            createUpdateDialog: false,
            confirmDialog: false,
            breakpoint: {}
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    archiveResource = (item) => {
        this.setState({text: this.props.translate('message.confirm_archive_location_zone')}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(deleteResource({
                        user: LocalStorage.get('user'),
                        query: Object.assign({}, this.getQuery(), {LocationZoneID: item.LocationZoneID}),
                        errorMessage: true, successMessage: this.props.translate('text.location_zone_archived'),
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    restoreFromArchive = (item) => {
        this.setState({text: this.props.translate('message.confirm_restore_location_zone')}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({confirmDialog: false}, () => {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: {
                            LocationZoneID: item.LocationZoneID,
                            ArchivedDate: 1
                        },
                        errorMessage: true, successMessage: this.props.translate('text.location_zone_restored'),
                        query: this.getQuery(),
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    /** UI Events
     ================================================================= */
    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        }, () => {
            if (this.state.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
                this.handleFilterInputChange('limit', 10);
            }
        });
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    handleCreateUpdateResource = (item = null) => {
        this.setState({selectedItem: item, createUpdateDialog: true})
    }

    handleCloseCreateUpdateResource = () => {
        this.setState({
            createUpdateDialog: false,
            canSubmit: false,
            emailExist: null,
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            isColumnsDialogVisible: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    handleSetDefaultTableColumnOptions = () => {
        const tableOptions = getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate)

        this.setState({
            tableOptions: tableOptions,
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }
    /** Helpers
     ================================================================= */
    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            archived: new Field('archived', '', [''], false, 'checkbox'),
            limit: new Field('limit', 10, [''], false, 'select', {hideLabel: true}, {
                menuPlacement: "top",
                hasPortal: true
            })
        }
    }

    getFields = (item = {}) => {
        const fieldTemplates = {
            LocationZone: new Field('LocationZone', '', ['empty'], false, 'text', {
                addContainerClass: 'col-span-full'
            }),
            Notes: new Field('Notes', '', [], false, 'textarea', {
                label: 'Descriptions',
                labelRaw: 'Descriptions',
                addContainerClass: 'col-span-full'
            }),
            CreateUpdateDate: new Field('CreateUpdateDate', '', [], false, 'date', {
                hideDialog: true
            }),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    saveFilters = () => {
        let filters = []

        Object.keys(this.getQuery()).forEach(it => filters.push(it))

        LocalStorage.persistState(this.pagePath, this.state, filters)
    }

    getResource = () => {
        return Resources.LocationZones
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate);
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props
        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)
        const isLoading = getProp(resource, 'isLoading', true)

        return (
            <Layout
                onBreakpointChange={this.handleBreakpointChange}
                isAccessible={!(resource.errorStatus === 2)}
                {...this.props}
            >
                <Page>
                    <PageHeader
                        title={translate('page.heading.location_zones')}
                        buttonLabel={translate('btn.create_new')}
                        onButtonClick={() => this.handleCreateUpdateResource()}
                        hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                        afterTitle={(
                            <PageHeaderInfo
                                dispatch={this.props.dispatch}
                            />
                        )}
                    />

                    <div className="flex relative z-[32]">
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleClearFiltersClick}
                            translate={translate}
                        />

                        <div className="ml-auto flex">
                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>
                    </div>

                    <TableCard>
                        <TableFilters
                            hideLimit
                            filterFields={this.state.queryFilterFields}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                            onRefreshTable={this.fetchData}
                        />

                        <NoRecordsTable
                            show={(data.length === 0) && !isLoading}
                            canCreate={checkPerm(this.getResource(), CREATE_PERM)}
                            title={translate('text.no_matching_records')}
                            text={translate('text.create_new_resource', [this.state.queryFilterFields.query.value])}
                            btnLabel={translate('btn.create_location_zone')}
                            onBtnClick={() => {
                                this.handleCreateUpdateResource({LocationZone: this.state.queryFilterFields.query.value})
                            }}
                            onClearFilterClick={this.handleClearFiltersClick}
                            clearFilterBtnLabel={translate('text.clear_all_filters')}
                            clearFilterText={translate('text.try_without_filters')}
                            filters={this.state.queryFilterFields}
                        />

                        <ResourceTable
                            data={data}
                            fields={this.getFields()}
                            verticalTableIsVisible={this.state.breakpoint.index <= 1}

                            translate={translate}
                            isLoading={isLoading}

                            options={this.state.tableOptions}

                            limit={this.state.queryFilterFields.limit.value}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onRowClick={checkPerm(this.getResource(), UPDATE_PERM) ? this.handleCreateUpdateResource : null}
                            onEdit={this.handleCreateUpdateResource}
                            onDelete={this.archiveResource}
                            onRestore={this.restoreFromArchive}

                            hasEditPerm={checkPerm(this.getResource(), UPDATE_PERM)}
                            hasDeletePerm={checkPerm(this.getResource(), DELETE_PERM)}
                            hasRestorePerm={checkPerm(this.getResource(), UPDATE_PERM)}
                        />

                        {/*Table footer*/}
                        <TableCardFooter
                            show={!(!data.length && !isLoading)}
                        >
                            <Pagination
                                count={count}
                                isLoading={isLoading}
                                hideRowsPerPage={this.state.breakpoint.index <= 1}
                                handleQueryChange={
                                    (name, value, currentPage) => name === "offset"
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                            />
                        </TableCardFooter>

                    </TableCard>
                </Page>

                <ModalSaveResource
                    title={this.state.selectedItem?.LocationZoneID ? translate('dialog_heading.edit_location_zone') : translate('dialog_heading.create_location_zone')}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-12"
                    show={this.state.createUpdateDialog}
                    onClose={this.handleCloseCreateUpdateResource}
                    fields={this.getFields(this.state.selectedItem)}
                    onSubmit={(params) => {
                        if (params) {
                            if (!!this.state.selectedItem?.LocationZoneID) {
                                this.props.dispatch(updateResource({
                                    user: LocalStorage.get('user'),
                                    params: Object.assign(params, {LocationZoneID: this.state.selectedItem?.LocationZoneID}),
                                    query: this.getQuery(),
                                    resource: this.getResource(),
                                    piggyResource: this.getResource(),
                                    errorMessage: true, successMessage: translate('text.location_zone_updated')
                                }))
                            } else {
                                this.props.dispatch(createResource({
                                    user: LocalStorage.get('user'),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: this.getResource(),
                                    piggyResource: this.getResource(),
                                    errorMessage: true, successMessage: translate('text.location_zone_created')
                                }))
                            }
                            this.handleCloseCreateUpdateResource()
                        }
                    }}
                    translate={translate}
                />

                <ModalConfirm
                    title={translate('text.Confirm')}
                    show={!!this.state.confirmDialog}
                    text={this.state.text}
                    onClose={() => this.setState({confirmDialog: false})}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={translate('btn.cancel')}
                    translate={translate}
                    onConfirm={this.state.confirmModal}
                />

                <TableOptionsDialog
                    show={this.state.isColumnsDialogVisible}
                    pagePath={this.pagePath}
                    columns={this.state.tableOptions.columns}
                    setTableColumnOptions={this.handleSetTableColumnOptions}
                    setDefaultTableColumnOptions={this.handleSetDefaultTableColumnOptions}
                    getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                    onClose={this.handleToggleColumnSettingsDialog}
                    translate={translate}
                />
            </Layout>
        )
    }
}

export default connect(state => state)(LocationZonesView)
