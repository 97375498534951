export default class Resources {
    static CompanyGeneral = "company/general"
    static CompanyBilling = "company/billing"
    static User = "user";

    static Resource = "resource"

    static CreateSupportTicket = "support"

    static Help = "help"

    static UserInfo = "user"
    static UsersReport = "user/reports/admin"

    static Company = "company"
    static CompanyVacation = "company/vacation"
    static CompanyMailingSettings = "company/email-server"

    static ContactGroups = "contactgroups"
    static ContactGroupsQuick = "contactgroups/quick"
    static ContactGroupContacts = "contactgroups/contact"


    static TrackTime = "hr-manage/track-time"
    static NewCandidates = "hr-manage/application"
    static Requests = "hr-manage/requests"

    static UserPermissions = "user/permissions"
    static PermissionsList = "user/permissions/list"
    static ContactPermissions = "contactgroups/permissions"
    static UserPermissionsCopy = "user/permissions/copy";

    static ContactsPassword = "contacts/password"
    static SystemContactsPassword = "system-contacts/password"

    static Organizations = 'organizations'
    static OrganizationsQuick = 'organizations/quick'

    static DocumentEntities = "documents/required"
    static DocumentTypes = "lookup/document"
    static DocumentsSend = "documents/send"

    static DispatchSettings = "dispatch/settings"
    static DispatchExpiry = "dispatch/expiry"

    static Offices = "offices"
    static OfficesQuick = "offices/quick"
    static OfficesHistory = "offices/history"
    static OfficesImportExcel = "offices/import/excel"
    static Lookup = "lookup"
    static LookupsPublic = "lookup/public"
    static Integrations = 'integrations'
    static IntegrationsMy = 'integrations'
    static Locations = "locations"
    static LocationsQuick = "locations/quick";
    static LocationsHistory = "locations/history"
    static LocationsContacts = "location/contacts"
    static LocationsTrucks = "locations/trucks"
    static LocationsTrailers = "locations/trailers"
    static LocationsDuplicates = "locations/duplicates"
    static LocationZones = "zones"

    static Drivers = "drivers"
    static DriversQuick = "drivers/quick"
    static DriverInfo = "drivers/info"
    static DriverDocuments = "drivers/documents"
    static DriverSettlements = "drivers/settlements"
    static DriverSettlementsTemplate = "drivers/settlements/template"
    static DriverSettlementsTemplateDeductions = "drivers/settlements/template/deductions"
    static DriversHistory = "drivers/history"
    static DriversSuspend = "drivers/suspend"
    static DriversTerminate = "drivers/terminate"
    static DriversTerminateInvoice = "drivers/terminate/invoice"
    static DriverEndorsements = "drivers/endorsements"
    static DriverPreferences = "drivers/preferences"
    static DriverReviews = "drivers/reviews"
    static DriverNotes = "drivers/notes"
    static DriverRatings = "drivers/ratings"
    static DriverVacations = "drivers/vacation"
    static DriverRestrictions = "drivers/restrictions"
    static DriverDispatch = "drivers/contacts"
    static DriverDispatchPrimary = "drivers/contacts/set-primary"
    static DriverDispatchGroups = "drivers/contacts/groups"
    static DriverEmployments = "drivers/employments"
    static DriverDeduction = "drivers/deduction"
    static DriverSettlementsTemplateDeductions = "drivers/settlements/template/deductions"
    static DriversPayroll = "drivers/payroll"
    static DriversBulkEdit = "drivers/bulk/edit"
    static DriversLoads = "drivers/loads"
    static DriversClaims = "drivers/claims"
    static DriversAccidents = "drivers/accidents"
    static MoneyCodesIntegrated = "accounting/money-code/efs"
    static MoneyCodesImport = "accounting/money-code/import"
    static MoneyCodes = "accounting/money-code"

    static PayrollBatches = "accounting/payroll/batches"
    static PayrollSummary = "accounting/payroll/summary"
    static PayrollSettings = "accounting/payroll/settings"
    static CarrierPayroll = "accounting/carrier/payroll"

    static AccountingAssets = "accounting/assets"

    static PayrollBatchesItems = "accounting/payroll/batches/items"
    static PayrollBatchesItem = "accounting/payroll/batches/item"
    static PayrollBatchesAssign = "accounting/payroll/batches/assign"
    static PayrollBatchesItemsDocument = "accounting/payroll/batches/items/document"
    static PayrollBatchesItemsDocuments = "accounting/payroll/batches/items/document/bulk"
    static PayrollBatchesItemsPost = "accounting/payroll/batches/post"

    static LoadCustomerPublicDocuments = "load/public/documents";

    static PayrollBatch = "accounting/payroll/batches/single"

    static DocumentsPreview = "documents/preview"
    static Agents = "agents"
    static AgentsQuick = "agents/quick"
    static AgentInfo = "agents/info"
    static AgentDocuments = "agents/documents"
    static AgentsVacation = "agents/vacation"
    static AgentsEmployments = "agents/employments"
    static AgentsHistory = "agents/history"
    static AgentsBulkEdit = 'agents/bulk/edit'
    static AgentsSettlements = 'agents/settlements'
    static AgentsPayroll = "agents/payroll"
    static AgentsDeduction = "agents/deduction"
    static ContactImage = "contacts/image"
    static SystemContactImage = "system-contacts/image"


    static ContactDocuments = "contacts/document"
    static SystemContactsDocuments = "system-contacts/document"
    static CompanyImage = "company/image"
    static GlobalSearch = "global/search"
    static Trucks = "trucks"
    static TrucksQuick = "trucks/quick"
    static TruckImport = "trucks/import"
    static TrucksDeatach = "trucks/deatach"
    static TrucksAttach = "trucks/attach"
    static TruckReports = "trucks/inactive"
    static TrucksPermissions = "trucks/permissions"
    static TrucksPermissionsUser = "trucks/permissions/user"
    static TrucksPermissionsGroup = "trucks/permissions/group"
    static TrucksBulkEdit = "trucks/bulk/edit"
    static Trailers = "trailers"
    static TrailersQuick = "trailers/quick"
    static TrailerReports = "trailers/inactive"
    static TrailersPermissions = "trailers/permissions"
    static TrailersService = "trailers/service"
    static TrailersPermissionsUser = "trailers/permissions/user"
    static TrailersPermissionsGroup = "trailers/permissions/group"
    static TrailersLocations = "trailers/locations"
    static TrailersLoads = "trailers/loads"
    static TrailerHistory = "trailers/history"
    static TrailerDocuments = "trailers/documents"
    static TrailersLocationsDetails = "trailers/locations/details"
    static Trailer = "trailers/info"
    static TrailersTypes = "trailers/types"
    static TrailerRetire = "trailers/retire"
    static TrailersBulkEdit = "trailers/bulk/edit"
    static TruckDocuments = "trucks/documents"
    static TruckServices = "trucks/service"
    static TrucksInfo = "trucks/info"
    static TrucksPermits = "trucks/permits"
    static TruckRetire = "trucks/retire"
    static TrucksType = "trucks/types"
    static TrucksTypeCopy = "trucks/types/copy"
    static TrucksCanGo = "trucks/can-go"
    static TrucksLocations = "trucks/locations"
    static TrucksLoads = "trucks/loads"
    static TrucksLocationsDetails = "trucks/locations/details"
    static TrucksImport = "trucks/external"
    static TrucksHistory = "trucks/history"
    static TrucksMileage = "trucks/mileage"
    static MileageReports = "fleet/reports/mileage"
    static Documents = "documents"


    static Carriers = "carriers"
    static CarriersQuick = "carriers/quick"
    static Carrier = "carriers/info"
    static CarrierPayment = "carriers/payment"
    static CarrierDrivers = "carriers/drivers"
    static CarrierContacts = "carriers/contacts"
    static CarrierDocuments = "carriers/documents"
    static CarrierPreferences = "carriers/preferences"
    static CarrierCertifications = "carriers/certs"
    static CarrierCertificationsDocument = "carriers/certs/document"
    static CarrierReviews = "carriers/reviews"
    static CarrierHistory = "carriers/history"
    static CarrierSettings = "carriers/settings"
    static CarrierExpiry = "carriers/expiry"
    static Brokers = "brokers"
    static BrokersInfo = "brokers/info"
    static BrokerHistory = "brokers/history"
    static BrokersDocuments = "brokers/documents"
    static BrokersReviews = "brokers/reviews"
    static BrokersContacts = "brokers/contacts"
    static Customers = "customers"
    static CustomersQuick = "customers/quick"
    static CustomersInfo = "customers/info"
    static CustomersPayment = "customers/payment"
    static CustomerHistory = "customers/history"
    static CustomersDocuments = "customers/documents"
    static CustomersCommissions = "customers/comissions"
    static CustomersMatrix = "customers/matrix"
    static CustomersLoadReview = "customers/load-reviews"
    static CustomersExtraCharges = "customers/extracharges"
    static CustomersExpiryItems = "customers/expiry"
    static CustomersAccessorials = "customers/accessorials"
    static CustomersReviews = "customers/reviews"
    static CustomersContacts = "customers/contacts"
    static CustomersSettings = "customers/settings"
    static CustomersCredit = "customers/credit"
    static CustomersSettingsCredit = "customers/settings/credit"
    static CustomersSettingsLoad = "customers/settings/load"
    static CustomersClaims = "customers/claims"
    static CustomersSummary = "customers/summary"
    static CustomerContacts = "customers/contacts"
    static Vendors = "vendors"
    static VendorsQuick = "vendors/quick"
    static VendorsInfo = "vendors/info"
    static VendorHistory = "vendors/history"
    static VendorsDocuments = "vendors/documents"
    static VendorsReviews = "vendors/reviews"
    static VendorsContacts = "vendors/contacts"
    static VendorsSettings = "vendors/settings"
    static VendorsPayment = "vendors/payment"
    static VendorsExpiry = "vendors/expiry"
    static Commodities = "commodities"
    static CommoditiesPermissions = "commodities/permissions"
    static CommoditiesPermissionsUser = "commodities/permissions/user"
    static CommoditiesPermissionsGroup = "commodities/permissions/group"
    static Loads = "loads"
    static LoadsQuick = "loads/quick"
    static LoadsProfitSummary = "loads/profit-summary";
    static LoadsPricing = "loads/pricing";
    static Load = "load"
    static LoadDrop = "load/drop"
    static Trip = "load/trip";
    static TripAddRemoveLoads = "load/trip/loads";
    static TripStops = "load/trip/stops";
    static TripPricing = "load/trip/pricing";
    static Trips = "load/trips";
    static LoadPODReviewed = "load/podreviewed"
    static LoadMark = "load/mark"
    static LoadInfo = 'load/info'
    static LoadReimbursement = 'load/reimbursement'
    static LoadMoneyCodes = 'load/money-codes'
    static LoadReimbursementDocument = 'load/reimbursement/document'
    static LoadTracks = 'load/tracks'
    static LoadTracking = 'load/tracking'
    static LoadCombined = "load/combined"
    static LoadEvents = "load/events"
    static LoadEventTemplates = "load/event-template"
    static LoadChatInternal = "load/chat/internal"
    static LoadChatExternal = "load/chat/external"
    static LoadStart = "load/start"
    static LoadCancel = "load/cancel"
    static LoadStatus = "load/status"
    static LoadResend = "load/resend"
    static LoadReset = "load/reset"
    static LoadAbort = "load/abort"
    static LoadFinish = "load/finish"
    static LoadWatchers = "load/watchers"
    static LoadRecurring = "load/recurring"
    static LoadDocuments = "load/documents"
    static LoadFavorites = "load/favorite"
    static LoadMileage = "load/calculate/route"
    static LoadClaims = "load/claims"
    static LoadNotificationSettings = "load/notification/settings"
    static LoadCommodities = "load/commodities"
    static LoadPricing = "load/pricing"
    static LoadPricingAcc = "load/pricing/acc"
    static LoadHistory = "load/history"
    static LoadPayroll = "load/delivered"
    static LoadShareSettings = "load/share"
    static LoadShort = "load/short"
    static LoadShareSend = "load/share/send"
    static LoadShareCarrierSettings = "load/share/carrier"
    static LoadManifest = "load/manifest"
    static LoadTonu = "load/tonu"
    static LoadTailgate = "load/tailgate"
    static LoadSplitDispatch = "load/splitDispatch"
    static LoadCustomerSettings = "load/customer/settings"
    static LoadStopUpdate = "load/stop-update"
    static LoadStopComplete = "load/stop-complete"
    static LoadTracksPush = "load/tracks/push"
    static LoadPost = "load/post"
    static Units = "unit"
    static UnitInfo = "unit/info"
    static UnitReport = "unit/report"

    static Scheduler = "scheduler"
    static SchedulerShare = "scheduler/share"
    static SchedulerSharePublic = "scheduler/share/public"
    static SchedulerShareSend = "scheduler/share/send"

    static LoadCarrierPublic = 'load/carrier/public'
    static LoadShareInfo = 'load/share/info'
    static LoadShareEvents = 'load/share/events'
    static LoadShareTracks = 'load/share/tracks'
    static LoadShareDocuments = 'load/share/documents'
    static LoadsColor = 'load/color'
    static LoadPreview = 'load/preview'
    static LoadPublicReview = 'load/public/review'
    static LoadPublic = 'load/public'

    static Fuel = "fuel"
    static FuelIntegrationSettings = "fuel/integrations/settings"
    static FuelIntegrationPreviews = "fuel/integrations/previews"
    static FuelIntegrationPreview = "fuel/integrations/preview"
    static FuelIntegrationImport = "fuel/integrations/import "
    static FuelCard = "fuel-card"
    static FuelCardInfo = "fuel-card/info"
    static FuelSurcharge = "fuel-surcharge"
    static Transponder = "transponder"

    static Routes = "loads"
    static Calendar = "dashboard/calendar"
    static Vehicles = "dashboard/vehicles"
    static DashboardTasks = "tasks/dashboard"
    static DashboardProfitLoss = "dashboard/profitandloss"
    static DashboardExpenses = "dashboard/expenses"
    static DashboardSales = "dashboard/sales"
    static DashboardIncome = "dashboard/income"
    static Accessorial = "load/accessorial"
    static AccessorialQuick = "accessorial/quick"
    static LoadPricingMatrix = "load-pricing-matrix"
    static CommodityPricingMatrix = "commodity-pricing-matrix"
    static LoadMatrixCalculate = "load/matrix/calculate"
    static FuelMatrixCalculate = "load/surcharge/calculate"
    static FuelSurchargeMatrix = "fuel-surcharge"
    static UserLoggedDevices = "user/logged-devices"
    static Tasks = "tasks"
    static TasksMark = "tasks/mark"
    static TasksBulkReassign = "tasks/bulk-reassign"
    static TasksInfo = "tasks/info"
    static TasksComment = "tasks/comment"
    static TasksDocuments = "tasks/documents"
    static TaskGrab = "tasks/grab"
    static TaskResource = "tasks/resource"
    static TaskResourceCount = "tasks/resource-count"
    static TaskPin = "tasks/pin"
    static MineTasks = "tasks/mine"
    static BoardTasks = "tasks/group"
    static BoardMembers = "tasks/group/members"
    static RepeatTasks = 'tasks/repeat'
    static TasksSettings = 'tasks/settings';
    static TasksGroupDefaults = 'tasks/group/defaults';
    static TaskPostpone = 'tasks/postpone'
    static TaskGroupMembers = 'tasks/group/members'
    static TaskGroupAddMembers = 'tasks/group/add-members'
    static TaskGroupRemoveMembers = 'tasks/group/remove-members'
    static TaskGroupContacts = 'tasks/group/contacts'
    static UserCommands = 'user/commands'
    static UserTableProfiles = 'user/commands/profile'
    static Claims = "claims"
    static ClaimsInfo = "claims/info"
    static ClaimsNotes = "claims/notes"
    static ClaimsDocuments = "claims/documents"
    static ClaimsHistory = "claims/history"
    static Tickets = 'ticket'
    static TicketsInfo = 'ticket/info'
    static TicketsImages = 'ticket/image'
    static TicketsLike = 'ticket/like'
    static Accidents = "accidents"
    static AccidentsInfo = "accidents/info"
    static AccidentsNotes = "accidents/notes"
    static AccidentsDocuments = "accidents/documents"
    static AccidentsHistory = "accidents/history"

    static Employees = 'employees'
    static EmployeesQuick = 'employees/quick'
    static EmployeesInfo = 'employees/info'
    static EmployeesVacation = 'employees/vacation'
    static EmployeesVacationStatus = 'employees/vacation/status'
    static EmployeesDocuments = 'employees/documents'
    static EmployeesEmployments = 'employees/employments'
    static EmployeesHistory = 'employees/history'
    static EmployeesBulkEdit = 'employees/bulk/edit'
    static EmployeesSettlements = 'employees/settlements'
    static EmployeesPayroll = 'employees/payroll'
    static EmployeesCommissions = 'employees/commission'
    static EmployeesDeduction = 'employees/deduction'

    static NotesPersonal = "notes/personal"

    static Billing = 'accounting/billing'
    static BillingBulk = 'accounting/billing/bulk'
    static Invoices = 'accounting/income'
    static InvoicesAdjust = 'accounting/income/adjust'
    static IncomeDocumentsPDF = "accounting/income/documents/pdf"
    static InvoicesCollectionNote = 'accounting/income/collection-note'
    static InvoicesMark = 'accounting/income/mark';
    static InvoicesInvoice = 'accounting/income/invoice'
    static InvoicesFactoring = 'accounting/income/factoring';

    static InvoicesPayment = 'accounting/income/payment'
    static ExpensePayment = 'accounting/expense/payment'
    static ExpenseHistory = 'accounting/expense/history'
    static ExpenseImport = 'accounting/expense/import-money-codes'
    static ExpenseDocumentSend = 'accounting/expense/documents/send'
    static ExpenseDocumentsPDF = 'accounting/expense/documents/pdf'
    static AccountingExpenseDocumentsAdditional = 'accounting/expense/documents/additional'
    static InvoicesDocuments = 'accounting/income/documents'
    static InvoicesColor = 'accounting/income/color'
    static IncomeHistory = 'accounting/income/history'

    static InvoicesReceipt = 'accounting/income/receipt'
    static AccountingIncomeInvoiceComment = 'accounting/income/invoice/comment'
    static AccountingExpenseBillComment = 'accounting/expense/bill/comment'
    static AccountingExpenseOrder = 'accounting/expense/order'
    static BillingDocuments = 'accounting/invoice/documents'
    static AccountingIncomeSample = 'accounting/income/sample'
    static Collections = 'accounting/collections'
    static AccountingProdServicesImage = "accounting/prodservices/image"

    static AccountingMark = "accounting/mark"

    static ProductsServices = 'accounting/prodservices'
    static AssetsInventory = 'accounting/inventory'
    static AssetsAssets = 'accounting/assets'
    static AssetsAssetsInfo = 'accounting/assets/info'
    static AssetsAssetsDocuments = 'accounting/assets/documents'
    static ProductsServicesCategory = 'accounting/prodservicescategory'

    static Expense = "accounting/expense";
    static ExpenseBills = "accounting/expense/bills";
    static ExpenseApprove = "accounting/expense/approve";
    static ExpenseExpense = "accounting/expense/expense";
    static ExpenseExpenseShort = "accounting/expense/expense/short";
    static ExpenseBill = "accounting/expense/bill";
    static ExpenseChecks = "accounting/expense/checks";
    static ExpenseLiabilities = "accounting/expense/liabilities";
    static ExpenseLiabilitiesLoan = "accounting/expense/liabilities/loan";
    static ExpenseLiabilitiesDownpayment = "accounting/expense/liabilities/downpayment";
    static ExpenseRecurring = "accounting/expense/recurring";
    static ExpenseReference = "accounting/expense/reference";
    static IncomeReference = "accounting/income/reference";
    static IncomeChecks = "accounting/income/checks";
    static IncomeTransactions = "accounting/income/transactions";
    static IncomeTransfers = "accounting/income/transfers";
    static IncomeTransactionsDetails = "accounting/income/transactions/details";
    static IncomeTransactionsAttach = "accounting/income/transactions/attach";
    static IncomeTransactionsDetach = "accounting/income/transactions/detach";

    static ExpenseMark = 'accounting/expense/mark';
    static ExpenseDocuments = 'accounting/expense/documents';
    static ExpenseColor = 'accounting/expense/color'
    static ExpenseChecksPrint = 'accounting/expense/checks/print';
    static ExpenseTransactions = "accounting/expense/transactions";
    static ExpenseTransactionsDetails = "accounting/expense/transactions/details";
    static ExpenseTransactionsAttach = "accounting/expense/transactions/attach";
    static ExpenseTransactionsDetach = "accounting/expense/transactions/detach";

    static Journal = "accounting/journal";
    static JournalPending = "accounting/journal/pending";
    static JournalDocuments = "accounting/journal/documents";
    static Accounts = "accounting/accounts";
    static AccountsQuick = "accounting/accounts/quick";

    static AccountingTaxes = "accounting/taxes";
    static AccountingTaxesReport = "accounting/taxes/report";
    static AccountingSettings = "accounting/settings";
    static AccountingReconciliation = "accounting/reconciliation";
    static AccountingReconciliationComplete = "accounting/reconciliation/complete";
    static AccountingReconciliationBatch = "accounting/reconciliation/batch";
    static AccountingReimbursement = "accounting/reimbursement";
    static AccountingReimbursementApprove = "accounting/reimbursement/approve";
    static AccountingReimbursementDocument = "accounting/reimbursement/document";
    static AccountingSummary = "accounting/summary";
    static AccountingDashboard = "accounting/reports/dashboard";
    static AccountingBalanceSheet = "accounting/reports/balancesheet";
    static AccountingIncomeStatement = "accounting/reports/incomestatement";
    static AccountingReportsAging = "accounting/reports/aging";
    static AccountingReportsARAging = "accounting/reports/ar-aging";
    static AccountingReportsAPAging = "accounting/reports/ap-aging";
    static AccountingReportsVendorPayments = "accounting/reports/vendor-payments";
    static AccountingReportsRevenueCustomer = "accounting/reports/revenue-customer";
    static AccountingReportsTrialBalances = "accounting/reports/trial-balances";
    static AccountingReportsCustomerStatement = "accounting/reports/customer";
    static AccountingAccountReport = "accounting/reports/account";
    static AccountingAccountAdvances = "accounting/reports/advances"
    static AccountingCreditAccounts = "accounting/credit-cards";
    static AccountingCreditAccountsImport = "accounting/credit-cards/import";
    static AccountingCreditAccountsRecharge = "accounting/credit-cards/recharge";
    static AccountingCreditAccountsDetails = "accounting/credit-cards/details";

    static AccountingPurchaseOrder = "accounting/purchaseorder";
    static AccountingPurchaseOrderApprove = "accounting/purchaseorder/approve";
    static AccountingPurchaseOrderReject = "accounting/purchaseorder/reject";
    static AccountingPurchaseOrderInfo = "accounting/purchaseorder/info";
    static AccountingPurchaseOrderDocuments = "accounting/purchaseorder/documents";

    static Divisions = "divisions";
    static DivisionsImage = "divisions/image";

    static MyCarrierIntegration = "carriers/integrations/mycarrier"
    static MyCarrierIntegrationInvite = "carriers/integrations/mycarrier/invite"
    static MyCarrierIntegrationImport = "carriers/integrations/mycarrier/import"
    static MyCarriersIntegrationsPreview = "carriers/integrations/mycarrier/preview"

    static EFSIntegration = "drivers/integrations/efs"

    static EmailTemplates = "email/templates"
    static EmailTemplatesParse = "email/templates/parse";

    static ColorTag = "colortag";
    static LoadColor = "load/color";
    static AccountingColor = "accounting/color";
    static TaskColor = "tasks/color";
    static ChatContacts = 'contacts';
    static Contacts = "contacts";
    static SystemContacts = "system-contacts"
    static ContactsQuick = "contacts/quick";
    static MoneyCodeType = "accounting/money-code/types";
    static ContactsActivate = "contacts/activate";
    static ContactsHistory = "contacts/history";
    static Messages = "messages";

    static Mileage = "mileage"
    static IFTAMileageReports = "mileage/ifta"
    static NewMexicoMileageReports = "mileage/new-mexico"
    static NewYorkMileageReports = "mileage/ny"
    static OregonMileageReports = "mileage/oregon"

    static IFTAMileageReportsPDF = "mileage/ifta/pdf"
    static NewMexicoMileageReportsPDF = "mileage/new-mexico/pdf"
    static NewYorkMileageReportsPDF = "mileage/ny/pdf"
    static OregonMileageReportsPDF = "mileage/oregon/pdf"

    static CTMileageReports = "mileage/ct"
    static CTMileageReportsPDF = "mileage/ct/pdf"

    static MileageTaxSettings = 'mileage/tax-settings'
    static MileageTaxes = 'mileage/taxes'
    static MileageTaxesDownload = 'mileage/taxes/download'

    static QuotesLoad = 'quotes/load'
    static QuotesInfo = 'quotes/load'
    static QuotesLoads = 'quotes/loads'
    static QuotesLoadReject = 'quotes/load/reject'
    static QuotesLoadConvert = 'quotes/load/convert'

    static Insurance = 'insurance';
    static OCRImage = 'ocr/image';
    static OCRJSON = 'ocr/json';

    static FleetExpiry = 'fleet/expiry'
    static PersonnelExpiry = 'personnel/expiry'
    static TrucksExpiry = 'trucks/expiry'
    static TrailersExpiry = 'trailers/expiry'

    static DriversExpiry = 'drivers/expiry'

    static CompaniesExpiry = 'companies/expiry'

    static ExcelPreview = 'excel/preview'
    static TransponderImport = 'transponder/import'

    static TransponderImportPreview = 'transponder/import/preview'
    static CommissionReport = "accounting/reports/commission"
    static UnpaidDispatches = 'accounting/reports/unpaid-dispatches'
    static UnpaidCarriers = 'accounting/reports/unpaid-carriers'
    static UnpaidCommissions = 'accounting/reports/unpaid-commissions'
    static Accounting1099Reports = 'accounting/reports/1099'
    static Accounting1099ReportsPDF = 'accounting/reports/1099/pdf'
    static Accounting1096Reports = 'accounting/reports/1096'

    static WorkOrder = "work-orders/work-order"
    static ProductServiceReport = "accounting/prodservices/report"

    static TimeOffEmailReceivers = "company/vacation/timeoff-email-receivers"
    static Jobs = "user/jobs"

    // Work order submodule resources
    static WorkOrders = "work-orders/work-orders"
    static WorkOrdersRentalContracts = "work-orders/rental-contracts"
    static WorkOrdersRentalContractsAnnex = "work-orders/rental-contracts/annex"
    static WorkOrdersRentalContract = "work-orders/rental-contract"
    static WorkOrdersRentalContractsDocuments = "work-orders/rental-contracts/documents"
    static WorkOrdersDocuments = "work-orders/work-orders/documents"
    static WorkOrdersDocumentsSingle = "work-orders/work-orders/documents/single"
    static WorkOrdersImagesSingle = "work-orders/work-orders/images/single"

    static WorkOrderLabor = "work-orders/work-orders/labor"
    static WorkOrderParts = "work-orders/work-orders/parts"
    static WorkOrderNotes = "work-orders/work-orders/notes"

    static Parts = "work-orders/parts"
    static WorkOrderPartsAutoComplete = "work-orders/work-orders/parts/auto-complete"

    static WorkOrdersImages = "work-orders/work-orders/images"
    static WorkOrdersLinked = "work-orders/work-orders/linked"
    static WorkOrdersCustomCodes = "work-orders/custom-codes"
    static WorkOrdersVMRSCode = "work-orders/vmrs"
    static WorkOrdersServicesTruck = "work-orders/work-orders/services/truck"
    static WorkOrdersServicesTrailer = "work-orders/work-orders/services/trailer"

    static Rentals = "work-orders/rentals"
    static Rental = "work-orders/rental"
    static RentalInfo = "work-orders/rental/info"
    static RentalDocuments = "work-orders/rental/documents"
    static RentalDocumentsSingle = "work-orders/rental/documents/single"
    static ColorTagRental = "work-orders/rental/color";
    static ColorTagWorkOrders = "work-orders/work-orders/color";

    static RentalBillingPaymentTemplates = "work-orders/rental/billing/payment-templates"
    static RentalBillingPaymentTemplatesCreateInvoice = "work-orders/rental/billing/payment-templates/create-invoice"
    static RentalBillingInvoices = "work-orders/rental/billing/invoices"

    static RentalContractsInfo = "rental-contracts"
    static RentalContractsDocuments = "rental-contracts/documents"
    static TrackingRoutes = "tracking/routes"
    static TrackingRoute = "tracking/route"
    // Work order submodule resources END

    static UnitsSchedule = "unit/schedule"
    static UnitsScheduleComment = "unit/schedule/comment"
    static UnitsScheduleShare = "unit/schedule/share"

    static DriversPending = "drivers/pending"
    static LoadTrackingStart = "load/start"
    static LoadTrackingResend = "load/resend"
    static LoadTrackingFinish = "load/finish"
    static LoadTrackingReset = "load/reset"

    static RouteTrackingStart = "tracking/route/start"
    static RouteTrackingResend = "tracking/route/resend"
    static RouteTrackingFinish = "tracking/route/finish"
    static RouteTrackingReset = "tracking/route/reset"

    static FuelExcelPreview = 'fuel/excel/preview'
    static FuelExcelImport = 'fuel/excel/import'
}
