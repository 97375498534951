import React, {Component} from 'react'
import {connect} from 'react-redux'
import {deleteResource, getResource,} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {showModal} from '../../../data/actions/ui'
import {
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    returnOffsetAndPagination,
    saveTableColumns,
} from '../../../common/util/util-helpers'
import {Field, FieldsManager} from '../../../data/services/fields'
import cronstrue from 'cronstrue'
import {DEFAULT_CRUD_STATE, DELETE_PERM, UPDATE_PERM} from '../../../util/util-constants'
import {checkPerm, getProp, longTableColumn, mergeDeep} from '../../../common/util/util-helpers'
import {cloneDeep} from "../../../common/util/util-vanilla";
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import ResourceTable from "../../../common/components/resource-table";
import Layout from "../../../common/components/layout";
import NoRecords from "../../../common/components/no-records-found/no-records";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import UpdateRepeatTasksDialog from "../../../common/components/modal/repeatTasksDialog/updateRepeatTasksDialog";

class RepeatTasksView extends Component {
    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1)

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: 'TaskName',

            fields: this.getFields(),
            tableOptions: getDefaultTableOptions(this.getFields(), {}, this.pagePath, this.props.translate),

            queryFilterFields: this.getQueryFilterFields(),

            confirmDialog: false,
            confirmDialogHideAnimation: false,
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.RepeatTasks,
            query: this.getQuery()
        }))
    }

    archiveResource = (item) => {
        this.setState({text: `${this.props.translate("message.are_you_sure_delete")} ${item.TaskName}?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(deleteResource({
                        user: LocalStorage.get('user'),
                        query: {id: item.TaskRepeatID},
                        errorMessage: true, successMessage: `${item.TaskName} deleted`,
                        resource: Resources.RepeatTasks,
                        piggyResource: Resources.RepeatTasks
                    }))
                })
            }, item)
        })
    }

    /** UI Events
     ================================================================= */
    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    handleUpdateResource = (items) => {
        this.setState({
            id: items.TaskID,
            items: items
        }, () => this.props.dispatch(showModal('UpdateRepeatTasksDialog')))
    }

    handleFilterClear = () => {
        this.setState({
            queryFilterFields: this.getQueryFilterFields(),
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            isColumnsDialogVisible: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate);
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }
    /** Helpers
     ================================================================= */
    hasCron = (cron = "") => {
        return (cron.split(" ").length > 4)
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,

            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {
                containerClass: 'col-md-3'
            }, {}),
            limit: new Field('limit', 10, [''], false, 'select', {
                containerClass: 'col-md-9 limit-field-container float-right'
            })
        }
    }

    getFields = () => {
        return {
            TaskName: new Field('TaskName', '', ['empty']),
            Description: new Field('Description', '', [], false, 'custom', {
                render: (item) => <div>{longTableColumn(item.Description)}</div>
            }),
            Cron: new Field('Cron', '', [''], false, 'custom', {
                render: (item) => {
                    return <div>{this.hasCron(item?.Cron) ? cronstrue.toString(item.Cron) : ''}</div>
                }
            }),
            ExecuteCount: new Field('ExecuteCount', '', ['empty']),
            Notes: new Field('Notes', '', ['empty'], false, 'custom', {
                render: (item) => <div>{longTableColumn(item.Notes)}</div>
            }),
            NextRunDate: new Field('NextRunDate', '', [], false, 'datetimezone'),
            CreateUpdateDate: new Field('CreateUpdateDate', '', [], false, 'datetimezone'),
        }
    }

    saveFilters = () => {
        let filters = []
        Object.keys(this.getQuery()).map(it => filters.push(it))
        LocalStorage.persistState(this.pagePath, this.state, filters)
    }

    getResource = () => {
        return Resources.RepeatTasks
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props
        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)

        return (
            <Layout {...this.props}>
                <Page>
                    <PageHeader
                        title={translate('page.heading.repeat_tasks')}
                        titleClass="mr-5 text-2xl"
                        afterTitle={(
                            <PageHeaderInfo
                                dispatch={this.props.dispatch}
                            />
                        )}
                    />
                    <div className="flex">
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleFilterClear}
                            translate={translate}
                        />

                        <div className="ml-auto">
                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>
                    </div>
                    <TableCard addClass={'relative'}>
                        <TableFilters
                            filterFields={this.state.queryFilterFields}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                        />

                        <ResourceTable
                            data={data}
                            fields={this.getFields()}

                            translate={translate}
                            isLoading={resource.isLoading}

                            options={this.state.tableOptions}

                            limit={this.state.queryFilterFields.limit.value}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onRowClick={checkPerm(this.getResource(), UPDATE_PERM) ? this.handleUpdateResource : null}
                            onEdit={checkPerm(this.getResource(), UPDATE_PERM) ? this.handleUpdateResource : null}
                            onDelete={checkPerm(this.getResource(), DELETE_PERM) ? this.archiveResource : null}
                        />

                        {/*Table footer*/}
                        {!(!data.length && !resource.isLoading) && (
                            <div
                                className="bg-inverse px-4 py-3 flex items-center justify-between border-t border-tm-gray-300 sm:px-6 h-16 rounded-b-lg"
                            >
                                <Pagination
                                    count={count}
                                    pageLimit={this.state.queryFilterFields.limit.value}
                                    queryFields={this.state.queryFilterFields}
                                    pageOffset={this.state.offset}
                                    handleQueryChange={
                                        (name, value, currentPage) => name === "offset"
                                            ? this.handleUpdateOffset(value, currentPage)
                                            : this.handleFilterInputChange(name, value)
                                    }

                                    translate={translate}
                                />
                            </div>
                        )}

                        <NoRecords
                            show={(data.length === 0) && !resource.isLoading}
                            title={translate('text.no_records')}
                        />
                    </TableCard>

                    <TableOptionsDialog
                        show={this.state.isColumnsDialogVisible}
                        pagePath={this.pagePath}
                        columns={this.state.tableOptions.columns}
                        setTableColumnOptions={this.handleSetTableColumnOptions}
                        getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                        onClose={this.handleToggleColumnSettingsDialog}
                        translate={translate}
                    />

                    <ModalConfirm
                        title={'Confirm'}
                        show={!!this.state.confirmDialog}
                        text={this.state.text}
                        onClose={() => this.setState({confirmDialog: false})}
                        buttonLabel={translate('btn.confirm')}
                        closeButtonLabel={'Cancel'}
                        translate={translate}
                        onConfirm={this.state.confirmModal}
                    />

                    {this.props.ui.modalName === 'UpdateRepeatTasksDialog' &&
                        <UpdateRepeatTasksDialog
                            items={this.state.items}
                            translate={translate}
                            {...this.props}
                        />
                    }
                </Page>
            </Layout>
        )
    }
}

export default connect(state => state)(RepeatTasksView)
