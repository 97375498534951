import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Field, FieldsManager} from '../../../../data/services/fields'
import moment from 'moment-timezone'
import {hideGlobalModal, showModal, showResourceDialog} from '../../../../data/actions/ui'
import Dropzone from 'react-dropzone'
import LocalStorage from '../../../../util/localStorage'
import Resources from '../../../../data/services/resources'
import cronstrue from 'cronstrue'
import {createResource, updateResource} from '../../../../data/actions/resource'
import Env from '../../../../util/env'
import {deleteDocument, download, uploadDocument} from '../../../../data/actions/download'
import {createTaskResource, getTaskResource, updateTaskResource} from '../../../../data/actions/taskResource'
import FireIcon from '@heroicons/react/20/solid/FireIcon'
import Tippy from '@tippyjs/react'
import ArrowPathIcon from '@heroicons/react/20/solid/ArrowPathIcon'
import CalendarIcon from '@heroicons/react/20/solid/CalendarIcon'
import {
    COLOR_PICKER_COLOR,
    CREATE_PERM,
    DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS,
    MAXIMUM_DOCUMENT_UPLOAD_SIZE, SECTIONS_VISIBILITY,
    TASK_STATUS_IN_PROGRESS
} from '../../../../util/util-constants'
import {copyToClipboard, scrollErrorIntoViewFields} from '../../../util/util-vanilla'
import {
    getProp,
    checkPerm,
    openInNewTab,
    resourceIsCreated,
    resourceIsDeleted,
    resourceIsLoaded,
    resourceIsUpdated
} from '../../../util/util-helpers'
import {TagIcon} from "@heroicons/react/24/outline";
import {ChevronUpIcon} from "@heroicons/react/20/solid";
import CheckIcon from "@heroicons/react/20/solid/CheckIcon";
import {createDialogResource, getDialogResource} from "../../../../data/actions/dialogResource";
import ClipboardDocumentIcon from "@heroicons/react/24/outline/ClipboardDocumentIcon";
import {currentDate} from "../../../util/util-dates";
import DocumentCard from "../../card/document-card";
import ContactBadge from "../../contact/contact-badge";
import ModalHeader from "../../modal/modal-header";
import {LoaderSmall} from "../../loader";
import TaskPostponeForm from "../../task/task-postopne-form";
import ModalConfirm from "../../modal/modal-confirm";
import ModalDefault from "../../modal/modal-default";
import ContactsSelectModal from "../../../../common/components/modal/contacts-select-modal"
import TaskFormComments from "../../../../views/tasks/all-tasks/dialogs/task-form-comments";
import FieldTextarea from "../../fields/field-textarea";
import ModalFooter from "../../modal/modal-footer";
import InfoParagraph from "../../info-paragraph";
import PopOver from "../../popover";
import LinkedTasks from "../../card/card-linked-tasks";
import Modal from "../../modal";
import Cron from "../../cron";
import FileViewer from "../../file-viewer/components";
import TaskHistory from "../../../../views/tasks/all-tasks/dialogs/task-history";
import Nav from "../../nav";
import {classNames, getLookup, scrollToElementBottom} from "../../../util/util-helpers";
import {Flags} from "../../feature-flags";
import PopoverWatchers from "../../task/popover-watchers";
import InfoBar from "../../info-paragraph/info-bar";
import TableTagManagerPopover from "../../resource-table/table-components/table-tag-manager/table-tag-manager-popover";
import TableTagManagerModal from "../../resource-table/table-components/table-tag-manager/table-tag-manager-modal";
import {fieldsToHtml, fillFieldsFromData} from "../../../util/util-fields";
import Badge from "../../badge";
import FieldsToHtml from "../../fields/fields-to-html";
import FieldContainer from "../../fields/field-container";
import FieldText from "../../fields/field-text";
import FieldDate from "../../fields/field-date";
import FieldRadio from "../../fields/field-radio";
import {getJWT} from "../../../util/util-auth";

class TasksFormDialog extends Component {

    constructor(props) {
        super(props);

        this.colors = COLOR_PICKER_COLOR;

        this.state = {
            // FIELDS
            fields: {TaskComment: new Field('TaskComment', '', ['empty'])},
            fields_info: this.getFields(),
            assignees: this.getAssignees(),
            watchers: this.getWatchers(),
            ocrFields: this.getOcrFields(),
            fileForOcr: undefined,
            acceptedFiles: [],
            existingFiles: this.getAttachments(),
            newFiles: [],
            rejectedFiles: [],
            linkedTasks: this.props.TaskID ? getProp(this.props.taskResource, 'data.linkedTasks', []) : [],
            selectedTagRows: [],
            cron: false,
            selectedRepeatOption: 'none',

            // HELPERS
            canSubmit: false,
            Descriptions: [],
            tabs: [
                {
                    name: this.props.translate("tab.comments"),
                    resource: "comments",
                    current: true
                },
                {
                    name: this.props.translate("tab.history"),
                    resource: "history",
                    current: false
                }
            ],

            // MODALS
            addAssigneeModal: false,
            addWatcherModal: false,
            isPostponeTaskModalVisible: false,

            // Preview modal
            previewModal: false,
            isBlobPreviewOpen: false,
            previewModalAnimation: false,

            viewContactCard: false,
            selectedItem: null,
            urlCopied: false,
            tagsData: this.props.tagsData ?? [],
            setTags: [],
            tagColor: this.colors[0],
            selectedLabelsForDelete: {},
            documentConfirmData: {},
            isTagManagerModalOpen: false
        }

        this.dialogBodyRef = React.createRef();
        this.PopoverButtonRef = React.createRef();
        this.initialFocusRef = React.createRef();

        this.myID = LocalStorage.get('user')?.Contact?.ContactID;
        this.myName = LocalStorage.get('user')?.Contact?.FirstName + " " + LocalStorage.get('user')?.Contact?.LastName;

        this.canCreateLoad = SECTIONS_VISIBILITY.hasLoadSection !== false && checkPerm(Resources.Load, CREATE_PERM);
    }

    /** Lifecycles
     ================================================================= */
    componentDidMount() {
        if (this.props.TaskID) {
            this.fetchData();
        }

        if (!this.props.tagsData) {
            this.fetchTagsData();
        }

        setTimeout(() => {
            const initialInput = this.initialFocusRef?.select?.select?.inputRef;
            if (initialInput) {
                initialInput.focus();
            }
        }, 100);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.taskResource !== this.props.taskResource && !this.state.canSubmit) {
            scrollToElementBottom('commentSection');

            const item = getProp(this.props.taskResource, 'data.task', {}) ? getProp(this.props.taskResource, 'data.task', {}) : this.props.ui?.globalDialogs?.createTaskDialog?.data

            let tags = []

            if (item.ColorTag) {
                tags = item.ColorTag.split("|").map((it, i) => {
                    const tagData = it.split(",");
                    return {
                        id: i,
                        color: tagData[1],
                        label: tagData[0]
                    }
                });
            }

            if (this.state.TaskGroups) {
                const group = this.state.TaskGroups.find(it => it.TaskGroupID === item.TaskGroupID)
                item["TaskGroupDesc"] = getProp(group, "TaskGroupDesc", '')
            }

            this.setState({
                fields_info: this.getFields(item),
                existingFiles: this.getAttachments(),
                assignees: this.getAssignees(),
                watchers: this.getWatchers(),
                setTags: tags,
                linkedTasks: this.props.TaskID ? getProp(this.props.taskResource, 'data.linkedTasks', []) : []
            })
        }

        if (prevProps.taskResource !== this.props.taskResource && !!this.state.canSubmit) {
            let fields = this.state.fields_info;

            fields = FieldsManager.updateField(fields, 'Marked', getProp(this.props.taskResource, 'data.task.Marked', ''))

            this.setState({
                existingFiles: this.getAttachments(),
                fields_info: fields
            })
        }

        if (resourceIsLoaded(this.props.dialogResource, prevProps.dialogResource) && this.props.dialogResource.resource === "colortag") {
            this.setState({
                tagsData: getProp(this.props.dialogResource, 'data', [])
            })
        }

        if (resourceIsCreated(this.props.dialogResource, prevProps.dialogResource) && this.props.dialogResource.resource === "colortag") {
            this.setState({
                tagsData: getProp(this.props.dialogResource, 'data', [])
            })
        }

        if (resourceIsDeleted(this.props.dialogResource, prevProps.dialogResource) && this.props.dialogResource.resource === "colortag") {
            this.setState({
                tagsData: getProp(this.props.dialogResource, 'data', [])
            })
        }

        if (resourceIsCreated(this.props.taskResource, prevProps.taskResource)) {
            if (this.state?.ocrFields?.CreateLoadFromFile?.value === true && this.state?.fileForOcr?.preview) {
                const ocrDocumentData = {
                    url: this.state.fileForOcr.preview,
                    documentName: this.state.fileForOcr.fileName
                };

                const encodedOcrDocumentData = btoa(JSON.stringify(ocrDocumentData));
                openInNewTab("/loads/create?ocrDocumentUrl=" + encodedOcrDocumentData)
            }

            this.hideModal();
        }



        if (resourceIsUpdated(this.props.taskResource, prevProps.taskResource) && this.props.taskResource.resource !== "tasks/mark") {
            this.hideModal(); // Task successfully updated
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getTaskResource({
            user: LocalStorage.get('user'),
            query: {
                id: this.props.TaskID,
            },
            resource: Resources.TasksInfo
        }))
    }

    fetchTagsData = () => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get('user'),
            resource: Resources.ColorTag
        }))
    }

    createTag = (label, color) => {
        this.props.dispatch(createDialogResource({
            user: LocalStorage.get('user'),
            params: {ColorTag: label + ',' + color},
            resource: Resources.ColorTag,
            piggyResource: Resources.ColorTag,
            errorMessage: true,
            successMessage: this.props.translate('text.tag_created'),
        }))
    }

    getResourcePath = () => {
        if (this.props.globalDialog) {
            return null;
        }

        if (this.props.TaskID && this.props.pathname === '/all-tasks') {
            return this.state.fields_info.ResourcePath.value;
        }
        if (this.props.pathname !== '/all-tasks') {
            return this.props.pathname;
        }

        return null;
    }

    submit = () => {

        this.setState({fields_info: FieldsManager.validateFields(this.state.fields_info)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields_info)) {
                let params = Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields_info), {
                    Watchers: this.state.watchers.map(it => it.ContactID),
                    Candidates: this.state.assignees.map(it => it.ContactID),
                    id: this.props.TaskID,
                    LinkedTask: this.state.linkedTasks.map(item => {
                        if (+item.LinkTypeID === 4) {
                            item.LinkTypeID = 2
                            return item
                        }
                        return item
                    }),
                    ColorTag: this.state.setTags.reduce((memo, it, index) => {
                        memo = memo + (index ? '|' : '') + it.label + ',' + it.color
                        return memo
                    }, ''),
                    AssigneeID: this.state.fields_info.AssigneeID.value?.value,
                    ResourcePath: this.getResourcePath(),
                    Repeat: this.state.fields_info.Repeat.value ? 1 : 0
                })

                delete params.StartDateTime;
                delete params.DueDateTime;

                if (this.props.TaskID) {

                    this.props.dispatch(updateTaskResource({
                        user: LocalStorage.get('user'),
                        params: params,
                        resource: Resources.Tasks,
                        taskCountResource: Resources.TaskResourceCount,
                        taskCountQuery: {param: window.location.pathname},
                        errorMessage: true, successMessage: 'Task updated successfully!'
                    }))
                } else {
                    this.props.dispatch(createTaskResource({
                        user: LocalStorage.get('user'),
                        params: params,
                        file: this.state.newFiles,
                        fileResource: Resources.TasksDocuments,
                        resource: Resources.Tasks,
                        taskCountResource: Resources.TaskResourceCount,
                        taskCountQuery: {param: window.location.pathname},
                        successMessage: 'TASK_CREATED_SUCCESSFULLY',
                    }))
                }
            } else {
                scrollErrorIntoViewFields([this.state.fields_info])
            }
        })
    }

    addCommentHandler = () => {
        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                this.props.dispatch(createResource({
                    user: LocalStorage.get('user'),
                    params: {
                        TaskID: this.props.TaskID,
                        TaskComment: this.state.fields.TaskComment.value
                    },
                    query: {
                        id: this.props.TaskID
                    },
                    resource: Resources.TasksComment,
                    taskPiggyResource: Resources.TasksInfo,
                    errorMessage: true, successMessage: this.props.translate('text.successfully_added_comment'),
                }))
                this.setState({fields: FieldsManager.updateField(this.state.fields, 'TaskComment', '')})
            }
        })
    }

    editCommentHandler = () => {
        this.props.dispatch(updateResource({
            user: LocalStorage.get('user'),
            params: {
                id: this.state.editedComment.id,
                TaskComment: this.state.editedComment.value,
            },
            query: {
                id: this.props.TaskID
            },
            resource: Resources.TasksComment,
            taskPiggyResource: Resources.TasksInfo,
            errorMessage: true, successMessage: this.props.translate('text.successfully_updated_comment')
        }))
        this.setState({editedComment: {id: null, value: null,}})
    }

    handleTagRowsClick = (selectedLabels) => {
        const selectedColorTags = selectedLabels.filter(it => it.checked);

        this.PopoverButtonRef.current.click();
        this.setState({
            setTags: selectedColorTags,
            canSubmit: true
        })
    }

    toggleCreateNewTagModal = () => {
        this.setState({isTagManagerModalOpen: !this.state.isTagManagerModalOpen})
    }

    onTagClick = (tagIndex) => {
        const selectedLabels = this.state.setTags;

        selectedLabels.splice(tagIndex, 1);

        this.setState({
            setTags: selectedLabels,
            canSubmit: true
        })
    }

    /** Fields
     ================================================================= */
    getFields = (item = null) => {
        const isMe = item?.AssigneeID == LocalStorage.get('user')?.Contact?.ContactID

        const addContainerClass = 'col-span-6 relative'

        const isTaskInProgress = Boolean(item?.TaskStatusID > 1);
        const isTaskCompleted = Boolean(item?.TaskStatusID > 2);
        const isTaskClosed = Boolean(item?.TaskStatusID > 3)

        const canChangeTaskStatus = (isMe || checkPerm(Resources.Tasks, CREATE_PERM));

        const isForMeOnly = this.props.onlyForMyself && !this.props.TaskID

        let defaultAssigne = this.props.defaultAssigne ?? ''

        const isTaskPrivate = !!this.props.TaskID && !item?.TaskGroupID;

        let fieldTemplates = {
            TaskStatusID: new Field('TaskStatusID', 1, [], !canChangeTaskStatus || !item?.AssigneeID, item ? 'select' : 'hidden', {
                labelType: 'stack',
                addContainerClass: 'relative md:col-span-6',
                htmlAfter: () => {
                    const message = this.getTaskStatusNote(item?.TaskStatusID, isMe);

                    return !message
                        ? null
                        : <React.Fragment>
                            <div className="-mx-5 pt-5">
                                <InfoBar>
                                    {message}
                                </InfoBar>
                            </div>

                            <div className="-mx-6 pt-1 border-b border-tm-gray-200"/>
                        </React.Fragment>
                }
            }, {omitSort: true}),
            PrivateTasks: new Field('PrivateTasks', isTaskPrivate ? "1" : '', [''], !!this.props.TaskID && !item?.TaskGroupID && true, 'checkbox', {
                hideDialog: !!item?.TaskGroupID,
                addContainerClass: "ml-2 my-2"
            }),
            TaskFor: new Field('TaskFor', '', [], false, 'button-group',
                {
                    data: {0: this.props.translate('field.AssigneeID'), 1: this.props.translate('btn.candidates')}
                }),

            TaskName: new Field('TaskName', (this.props.prefilled || this.props.taskName) ?? '', ['empty'], isTaskInProgress, 'text', {
                labelType: 'stack',
                addContainerClass: 'col-span-4 relative'
            }),
            TaskPriorityID: new Field('TaskPriorityID', 3, ['empty'], isTaskInProgress, 'select', {
                labelType: 'stack',
                addContainerClass: 'col-span-2 relative'
            }, {
                reverseKeySort: true
            }),
            TaskGroupID: new Field('TaskGroupID', '', !isTaskPrivate ? ['empty'] : [], isTaskInProgress, 'select-search', {
                hideDialog: (!!this.props.TaskID && !item?.TaskGroupID),
                labelType: 'stack',
                addContainerClass: 'relative md:col-span-6',
                customValue: (data) => {
                    return {
                        label: data.TaskGroupName,
                        value: data.TaskGroupID,
                        metadata: data
                    }
                }
            }, {omitSort: true, innerRef: (el) => this.initialFocusRef = el}),
            Requester: new Field('Requester', '', [''], true, 'hidden'),

            Description: new Field('Description', this.props.taskDescription ?? '', [], isTaskCompleted, 'textarea', {
                addContainerClass,
                labelType: 'stack',
                heightClass: 'h-36'
            }),

            AssigneeID: new Field('AssigneeID', defaultAssigne, [], (isForMeOnly || !item?.TaskGroupID || isTaskClosed), 'select-search', {
                labelType: 'stack',
                addContainerClass: 'w-full',
                fieldOptions: (it) => (it?.value?.value !== LocalStorage.get('user')?.Contact?.ContactID && item?.TaskGroupID) && !isTaskClosed ?
                    <button
                        onClick={this.assignToMe}
                        className="field-btn text-primary leading-5 py-0"
                    >
                        {this.props.translate("text.assign_to_me")}
                    </button> : null,
            }, {key: 0, isClearable: !isTaskInProgress}),

            StartDate: new Field('StartDate', '', [''], isTaskInProgress, 'datetimezone', {addContainerClass: 'col-span-3'}),
            StartDateTime: new Field('StartDateTime', '', [''], (!item?.StartDate || isTaskInProgress), 'timezone-custom', {addContainerClass: 'col-span-3'}),

            DueDate: new Field('DueDate', '', [''], isTaskInProgress, 'datetimezone', {addContainerClass: 'col-span-3'}, {minDate: item?.StartDate}),
            DueDateTime: new Field('DueDateTime', '', [''], (!item?.DueDate || isTaskInProgress), 'timezone-custom', {addContainerClass: 'col-span-3'}),

            Marked: new Field('Marked', '', [], false, 'toggle', {labelType: 'stack'}),

            Groups: new Field('Groups', '', []),
            ResourcePath: new Field('ResourcePath', '', [''], false, (!item?.ResourcePath || !this.props.TaskID) ? 'hidden' : 'link', {
                formLabelClass: 'flex flex-col text-sm font-semibold text-tm-gray-900',
                addContainerClass
            }),

            Repeat: new Field('Repeat', '', [], false, !this.props.TaskID ? 'button-group' : 'hidden', {
                data: {0: this.props.translate('btn.no'), 1: this.props.translate('btn.yes')},
                formLabelClass: 'hidden',
                addContainerClass
            }),

            Notes: new Field('Notes', '', []),

            TaskTagType: new Field('TaskTagType', [], [], false, 'creatable', {
                labelType: 'stack',
                addContainerClass
            }, {menuPlacement: 'top', isMulti: true}),

            Cron: new Field('Cron', '', []),

            EndDate: new Field('EndDate', '', [], false, 'datetime'),
            EndAfter: new Field('EndAfter', '', ['integer_not_require']),
        }

        fieldTemplates = fillFieldsFromData(fieldTemplates, item);
        fieldTemplates.PrivateTasks.value = (!!this.props.TaskID && !item?.TaskGroupID) ? "1" : '';

        return fieldTemplates
    }

    getStateFields = (fields, excludeFields = null, includeFields = null) => {
        let stateFields = Object.assign({}, fields)

        if (excludeFields) {
            excludeFields.forEach((item) => {
                delete stateFields[item]
            })
        }

        if (includeFields) {
            Object.keys(fields).forEach(key => {
                if (!includeFields.includes(key)) {
                    delete stateFields[key]
                }
            });
        }

        return stateFields
    }

    getAttachments = () => {
        if (this.props.TaskID) {
            let tmp = []
            getProp(this.props.taskResource, 'data.documents', []).forEach(it => {
                it.description = it.Description
                it.DocumentName = it.OriginalFilename
                tmp.push(it)
            })
            return tmp
        }
        return []
    }

    getAssignees = () => {
        if (this.props.TaskID) {
            return getProp(this.props.taskResource, 'data.candidates', []).filter(it => it.ContactID)
        }
        return []
    }

    getWatchers = () => {
        if (this.props.TaskID) {
            return getProp(this.props.taskResource, 'data.watchers', []).filter(it => it.ContactID)
        }
        return []
    }

    getOcrFields = () => {
        return {
            CreateLoadFromFile: new Field('CreateLoadFromFile', "0", [''], false, 'checkbox'),
        }
    }

    /** UI Events
     ================================================================= */

    handleAssignAssignees = (contacts) => {
        let arr = this.state.assignees
        Object.values(contacts).forEach(contact => {
            if (typeof contact === 'object') {
                arr.push(contact)
            }
        })
        this.setState({assignees: arr, canSubmit: true})
    }

    handleAssignWatchers = (contacts) => {
        this.setState({watchers: Object.values(contacts), canSubmit: true})
        this.handleCloseContactsModal('Watcher')
    }

    handleToggleContactsModal = (type) => {
        this.setState({
            canSubmit: true,
            [`add${type}Modal`]: !this.state[`add${type}Modal`]
        })
    }

    handleHistoryContactClick = (contactID) => {
        if (!contactID) return null;

        this.props.dispatch(showModal('ViewContactCard', {ContactID: contactID}));
    }

    handleCloseContactsModal = (type) => {
        this.setState({
            [`add${type}Modal`]: false
        })
    }

    handleBadgeCloseClick = (id, state) => {
        this.setState({
            [state]: this.state[state].filter(contact => id !== contact.ContactID),
            canSubmit: true
        })
    }

    handleUnlinkIssue = (item) => {
        let tasks = this.state.linkedTasks
        tasks.forEach((it, index) => {
            if (it.LinkedTaskID == item.LinkedTaskID && it.LinkTypeID == item.LinkTypeID) {
                if (index !== -1) {
                    tasks.splice(index, 1)
                }
            }
        })
        this.setState({linkedTasks: tasks, canSubmit: true})
    }

    handleUpdateLinkedTasksValues = (fields) => {
        const LinkedTask = fields.LinkedTaskID.value
        let linkedTasks = this.state.linkedTasks
        Object.keys(LinkedTask).forEach(it => {
            linkedTasks.push({
                LinkTypeID: fields.LinkTypeID.value,
                LinkedTaskID: LinkedTask[it].value,
                LinkedTaskName: LinkedTask[it].label,
                __IsNew__: true
            })
        })
        this.setState({linkedTasks: linkedTasks, canSubmit: true})
    }

    handleInputChange = (name, value, index = null) => {
        let cron = this.state.cron
        let fields_info = this.state.fields_info
        let assignees = this.state.assignees
        let canSubmit = true

        if (name === 'ResourcePath') {
            fields_info.ResourcePath.metadata.copied = true
            copyToClipboard(window.location.protocol + '//' + window.location.hostname + value)
        }

        if (name == 'Repeat') {
            cron = !cron
        }

        if ('TaskFor' === name && value) {
            fields_info.AssigneeID.type = 'hidden'
            fields_info = FieldsManager.updateField(fields_info, 'AssigneeID', '')
        }

        if ('TaskFor' === name && !value) {
            fields_info.AssigneeID.type = 'select-search'
        }

        if ('TaskFor' === name) {
            canSubmit = this.state.canSubmit
        }

        if ('DueDate' === name && value) {
            fields_info.DueDateTime.disabled = false
        }

        if ('StartDate' === name && value) {
            fields_info.StartDateTime.disabled = false
            fields_info.DueDate.props = Object.assign({}, fields_info.DueDateTime.props, {minDate: moment(value)})
        }

        if ('PrivateTasks' === name) {
            fields_info.TaskGroupID.type = value ? 'hidden' : 'select-search';

            fields_info.TaskGroupID.validate = value ? [''] : ['empty']
            fields_info.TaskGroupID.disabled = value

            fields_info.AssigneeID.validate = value ? ['empty'] : ['']

            if (!value) {
                fields_info.AssigneeID.value = ''
            }

            if (value) {
                fields_info.TaskGroupID.value = '';
                fields_info.AssigneeID.disabled = false;
                fields_info.AssigneeID.type = 'select-search';
                fields_info.AssigneeID.metadata = {
                    ...fields_info.AssigneeID.metadata,
                    fieldOptions: (it) => (it?.value?.value !== LocalStorage.get('user')?.Contact?.ContactID) ?
                        <button
                            onClick={this.assignToMe}
                            className="field-btn text-primary leading-5 py-0"
                        >
                            {this.props.translate("text.assign_to_me")}
                        </button> : null,
                };
                assignees = []
            }
        }

        if ('AssigneeID' === name) {
            if (value) {
                fields_info.TaskStatusID.disabled = false
                assignees = []
            } else {
                fields_info.TaskStatusID.disabled = true
                FieldsManager.updateField(fields_info, 'TaskStatusID', 1)
            }
        }

        if ('TaskGroupID' === name && value) {
            fields_info.AssigneeID.disabled = false
            fields_info.AssigneeID.metadata = {
                ...fields_info.AssigneeID.metadata,
                fieldOptions: (it) => (it?.value?.value !== LocalStorage.get('user')?.Contact?.ContactID && fields_info.TaskGroupID.value) ?
                    <button
                        onClick={this.assignToMe}
                        className="field-btn text-primary leading-5 py-0"
                    >
                        {this.props.translate("text.assign_to_me")}
                    </button> : null,
            };
            assignees = []
        }
        if ('TaskGroupID' === name) {
            fields_info.AssigneeID.props.key = fields_info.AssigneeID.props.key + 1;
            fields_info.AssigneeID.value = null
        }

        fields_info[name].errorMessage = '';

        if (index?.toString()) { // Documents handle input change
            let descriptions = this.state.Descriptions
            descriptions[index] = value
            this.setState({canSubmit: true, Descriptions: descriptions})
        } else { // Fields info handle input change
            if (name === 'StartDate' && value > fields_info.DueDate.value) {
                fields_info.StartDate.value = value
                fields_info.DueDate.value = ''
                fields_info.DueDateTime.disabled = true
                fields_info.DueDateTime.value = ''
                this.setState({canSubmit: true, fields_info: fields_info})
            } else {
                this.setState({
                    canSubmit: canSubmit,
                    assignees,
                    fields_info: FieldsManager.updateField(fields_info, name, value),
                    cron
                })
            }
        }
    }

    handleOcrInputChange = (name, value) => {
        let ocrFieldsUpdate = this.state.ocrFields;
        ocrFieldsUpdate = FieldsManager.updateField(ocrFieldsUpdate, name, value);

        let fileForOcr = this.state.fileForOcr;
        if (value) {
            fileForOcr = this.state?.newFiles?.[0]
        } else {
            fileForOcr = undefined;
        }

        this.setState({
            ocrFields: ocrFieldsUpdate,
            fileForOcr: fileForOcr
        });
    }

    hideModal = () => {
        this.setState({linkedTasks: []})

        if (this.props.onClose) {
            this.props.onClose()
        } else {
            this.props.dispatch(hideGlobalModal('createTaskDialog'))
        }
    }

    handleMarkedClick = () => {
        const isMarked = !this.state.fields_info.Marked.value;

        if (this.props.TaskID === undefined) {
            this.setState({
                fields_info: FieldsManager.updateField(this.state.fields_info, 'Marked', isMarked)
            });
        } else {
            this.props.dispatch(updateTaskResource({
                user: LocalStorage.get('user'),
                params: {
                    IDs: [this.props.TaskID],
                    Marked: isMarked ? 1 : 0
                },
                resource: Resources.TasksMark,
                query: this.props.getQuery ? this.props.getQuery() : {id: this.props.TaskID},
                piggyResource: Resources.TasksInfo,
                errorMessage: true,
                successMessage: isMarked ? this.props.translate('message.task_marked') : this.props.translate('message.task_unmarked')
            }))
        }
    };

    onDragEnter = () => {
        this.setState({
            dropzoneActive: true
        })
    }

    onDragLeave = () => {
        this.setState({
            dropzoneActive: false
        })
    }

    handleDropAccepted = () => {
        this.setState({
            dropzoneActive: false,
        })
        this.props.dispatch(showResourceDialog())
    }

    handlePreviewDocument = (document) => {
        if (this.props.TaskID) {
            this.setState({previewDocument: document, previewModal: true})
        } else {
            this.setState({previewDocument: document, isBlobPreviewOpen: true})
        }
    }

    handleClosePreview = () => {
        this.setState({
            previewModal: false
        })
    }

    handleCloseBlobPreview = () => {
        this.setState({
            isBlobPreviewOpen: false
        })
    }

    uploadDocument = () => {
        let acceptedFiles = this.state.acceptedFiles;

        for (let i = 0; i < acceptedFiles.length; i++) {
            acceptedFiles[i].description = this.state.Descriptions[i]
            acceptedFiles[i].fileName = acceptedFiles[i].name;
        }

        if (this.props.TaskID) {
            this.props.dispatch(uploadDocument({
                user: LocalStorage.get('user'),
                files: this.state.acceptedFiles,
                id: this.props.TaskID,
                query: {
                    id: this.props.TaskID,
                },
                Descriptions: this.state.Descriptions,
                resource: Resources.TasksDocuments,
                errorMessage: true,
                successMessage: `Attachment${this.state.newFiles.length > 1 ? 's' : ''} ${this.state.newFiles.length > 1 ? 'have' : 'has'} been uploaded.`,
                taskPiggyResource: Resources.TasksInfo
            }));

            this.setState({
                filesUploadDialog: false,
                Descriptions: [],
                acceptedFiles: [],
                rejectedFiles: []
            })
        } else {
            acceptedFiles = [...this.state.newFiles, ...acceptedFiles];
            this.setState({
                filesUploadDialog: false,
                newFiles: acceptedFiles,
                Descriptions: [],
                acceptedFiles: [],
                rejectedFiles: []
            })
        }
    }

    cancelUpload = () => {
        this.setState({
            filesUploadDialog: false,
            Descriptions: [],
            acceptedFiles: [],
            rejectedFiles: []
        })
    }

    downloadDocument = (document) => {
        const fileExt = this.state.previewDocument ? this.state.previewDocument?.OriginalFilename?.split('.').pop() : document?.OriginalFilename?.split('.').pop()
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.TasksDocuments,
            query: Object.assign({
                DocumentID: this.state.previewDocument ? this.state.previewDocument.DocumentID : document.DocumentID,
                format: fileExt,
                name: 'document_' + currentDate() + '.' + fileExt
            })
        }))
        this.setState({previewDocument: false}, this.handleClosePreview)
    }

    deleteDocument = () => {
        const index = this.state.documentConfirmData.index;
        const document = this.state.documentConfirmData.document;

        this.setState({
            documentConfirmData: {},
            confirmModal: undefined
        }, () => {
            if (this.props.TaskID) {
                this.props.dispatch(deleteDocument({
                    user: LocalStorage.get('user'),
                    params: {
                        DocumentID: document.DocumentID,
                        id: this.props.TaskID
                    },
                    query: {id: this.props.TaskID},
                    resource: Resources.TasksDocuments,
                    taskPiggyResource: Resources.TasksInfo
                }))
            } else {
                this.setState({newFiles: this.state.newFiles.filter((_, i) => i !== index)})
            }
        })
    }

    confirmDocumentDelete = (index, document) => {
        this.setState({
            confirmModal: this.deleteDocument,
            confirmText: this.props.translate("message.confirm_document_delete", [document.DocumentName]),
            documentConfirmData: {
                index,
                document
            }
        })
    }

    editComment = (id, value = null) => {
        this.setState({editedComment: {id: id, value: value}}, () => {
            if (value) {
                document.getElementById('textInput').focus()
            }
        })
    }

    togglePostponeModal = () => {
        this.setState({
            isPostponeTaskModalVisible: !this.state.isPostponeTaskModalVisible
        })
    }

    /** Helpers
     ================================================================= */
    handleTabChange = (selectedTab) => {
        let tabs = this.state.tabs;

        this.setState({
            tabs: tabs.map(it => {
                it.current = it.resource === selectedTab;

                return it;
            })
        })
    }

    getLookups = (arr) => {
        let lookups = {}
        arr.forEach(item => {
            lookups[item] = getLookup(item, `${item}ID`, item)
        })
        return lookups
    }

    isTaskInProgress = () => {
        return (!!this.props.TaskID && Number(getProp(this.props.taskResource, 'data.task.TaskStatusID', 0)) === TASK_STATUS_IN_PROGRESS)
    }

    handleLinkClick = (e) => {
        if (e.includes('http')) {
            window.open(`${e}`, '_blank')
        } else {
            window.open(`${window.location.origin}${e}`, '_blank')
        }
    }

    deleteCommentHandler = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmText: this.props.translate('text.delete_comment_confirm')
        })
    }

    handleAddLinkedTaskClick = () => {
        let element = this.dialogBodyRef.current

        if (element) {
            setTimeout(() => element.scrollTop = element.scrollHeight, 100)
        }
    }

    assignToMe = () => {
        this.handleInputChange("AssigneeID", {label: this.myName, value: this.myID})
    }

    setColor = (tagColor) => {
        this.setState({tagColor})
    }

    colorPicker = () => {
        return this.colors.map((it, i) => (
                <button
                    key={i}
                    onClick={() => this.setColor(it)}
                    className={classNames(
                        'w-7 h-7 rounded-full ring-offset-inverse ring-link focus:ring-2 focus:ring-offset-2 flex items-center justify-center',
                    )}
                    style={{background: it}}
                >
                    {it === this.state.tagColor && (
                        <CheckIcon className="w-5 h-5 text-white"/>
                    )}
                </button>
            )
        )
    }

    getTaskStatusNote = (TaskStatusID, isMe) => {
        if (!isMe) {
            return null;
        }

        switch (Number(TaskStatusID)) {
            case 1:
                return this.props.translate("text.task_status_open_note");
            case 2:
                return this.props.translate("text.task_status_in_progress_note");
            default:
                return null;
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, taskResource} = this.props
        const infoMessage = <p>You need to select a board before adding assignees.</p>

        const selectedTab = this.state.tabs.find(it => it.current);

        const isTaskInProgress = this.isTaskInProgress();
        const isTaskClosed = Boolean(Number(getProp(this.props.taskResource, 'data.task.TaskStatusID', 0)) > 3);

        const taggedLabels = this.state.setTags.reduce((memo, it) => {
            memo[[it.label + "," + it.color]] = [this.props.TaskID]
            return memo;
        }, {});

        const isLoading = this.props.taskResource.isLoading
        const selects = {
            AssigneeID: {
                api: 'api/' + Resources.ContactsQuick,
                query: {
                    query: this.state.fields_info.AssigneeID.value,
                    IsSystemUser: 1,
                    TaskGroupID: this.state.fields_info?.TaskGroupID?.value?.value
                },
                searchMap: (it) => ({
                    label: it.FirstName + ' ' + it.LastName + ' - ' + it.Email,
                    value: it.ContactID
                })
            },
            TaskGroupID: {
                api: 'api/' + Resources.BoardTasks,
                query: {
                    limit: 50,
                    offset: 0,
                    AmIMember: 1
                },
                customizeList: (list) => {
                    this.setState({TaskGroups: list})
                    return list
                        .map(item => ({
                            value: item.TaskGroupID,
                            label: item.TaskGroupName,
                            metadata: item
                        }))
                }
            },
        }

        const fieldsHtmlColLeft1 = fieldsToHtml(Object.values(Object.assign({}, this.getStateFields(this.state.fields_info, [],
            ['TaskName', 'TaskPriorityID']))), translate, this.handleInputChange, selects)
        const fieldsHtmlTaskGroupID = fieldsToHtml(Object.values(Object.assign({}, this.getStateFields(this.state.fields_info, [],
            ['TaskStatusID', 'TaskGroupID', 'PrivateTasks']))), translate, this.handleInputChange, selects)
        const fieldsHtmlColLeft2 = fieldsToHtml(Object.values(Object.assign({}, this.getStateFields(this.state.fields_info, [],
            ['Description']))), translate, this.handleInputChange, selects)
        const assigneeField = fieldsToHtml(Object.values(Object.assign({}, this.getStateFields(this.state.fields_info, [],
            ['AssigneeID']))), translate, this.handleInputChange, selects)
        const fieldsHtmlColLeft3 = fieldsToHtml(Object.values(Object.assign({}, this.getStateFields(this.state.fields_info, [],
            ['StartDate', 'StartDateTime', 'DueDate', 'DueDateTime', 'ResourcePath']))), translate, this.handleInputChange, selects)

        const rejectedFiles = this.state.rejectedFiles.map(it => <li key={it.name}
                                                                     className="pl-3 text-red-600">{it.name}</li>)

        let {
            TaskStatus,
            TaskTagType,
            DocumentType
        } = this.getLookups(['TaskPriority', 'TaskStatus', 'TaskTagType', 'DocumentType'])
        getProp(this.state.fields_info, 'Tags.value', []).forEach(item => {
            if (TaskTagType[item.value]) delete TaskTagType[item.value]
        })

        const item = getProp(taskResource, 'data.task', {})

        if (!checkPerm(Resources.Tasks, CREATE_PERM)) {
            delete TaskStatus[4]
        }

        const Candidates = getProp(taskResource, 'data.candidates', [])


        // Rendering files to upload in Create dialog
        const documents = this.state.newFiles.map((document, index) => {
            return (
                <div
                    key={index}
                    className="flex items-center"
                >
                    {this.state.ocrFields?.CreateLoadFromFile?.value === true && (
                        <FieldRadio
                            className="text-primary w-4 h-4 cursor-pointer bg-inverse checked:bg-primary focus:ring-offset-inverse my-1.5 mr-2"
                            name="file-for-ocr"
                            onChange={() => null}
                            onClick={() => {
                                this.setState({
                                    fileForOcr: document
                                })
                            }}
                            value={document?.name === this.state?.fileForOcr?.name}
                        />
                    )}

                    <DocumentCard
                        canNotDelete={Boolean(Number(getProp(this.props.taskResource, 'data.task.TaskStatusID', 0)) > 2)}
                        layoutSmall={true}
                        documentID={document.DocumentID}
                        description={document.description}
                        fileName={document.DocumentName ?? document.name}
                        category={DocumentType[document.DocumentTypeID]}
                        date={document.CreateUpdateDate}
                        downloadDocument={() => {
                        }}
                        onPreviewDocument={() => this.handlePreviewDocument(document)}
                        onDeleteDocument={() => this.confirmDocumentDelete(index, document)}
                        translate={this.props.translate}
                    />
                </div>
            )
        })

        // Render already uploaded files in Edit dialog
        const existingDocuments = this.state.existingFiles.map((document, index) => {
            return (
                <DocumentCard
                    key={document?.DocumentID}
                    canNotDelete={Boolean(Number(getProp(this.props.taskResource, 'data.task.TaskStatusID', 0)) > 2)}
                    layoutSmall={true}
                    documentID={document.DocumentID}
                    description={document.description}
                    fileName={document.DocumentName}
                    category={DocumentType[document.DocumentTypeID]}
                    date={document.CreateUpdateDate}
                    downloadDocument={() => this.downloadDocument(document)}
                    onPreviewDocument={() => this.handlePreviewDocument(document)}
                    onDeleteDocument={() => this.confirmDocumentDelete(index, document)}
                    buttons={this.state.ocrFields?.CreateLoadFromFile?.value === true
                        ? [
                            {
                                iconLeading: ClipboardDocumentIcon,
                                className: "btn btn-table-action  m-0",
                                title: "Create load from document",
                                onClick: () => {
                                    const url = Env.getApiUrl('api/' + Resources.TasksDocuments, {
                                        DocumentID: document.DocumentID,
                                        token: getJWT().access_token,
                                        id: this.props.TaskID
                                    });
                                    const documentName = document.DocumentName
                                    const encodedUrl = btoa(JSON.stringify({ url, documentName }));
                                    openInNewTab("/loads/create?ocrDocumentUrl=" + encodedUrl);
                                }

                            }
                        ]
                        : undefined
                    }
                    translate={this.props.translate}
                />
            )
        })

        const watchersContactsList = {}
        const contactsList = {}

        const watchersContacts = this.state.watchers.map(contact => {
            if (!contact.ContactName) {
                contact.ContactName = contact.FirstName + " " + contact.LastName;
            }

            Object.assign(watchersContactsList, {
                [contact.ContactID]: contact
            })

            return (
                <ContactBadge
                    key={contact.ContactID}
                    className="block"
                    ContactEmail={contact.Email}
                    ContactName={contact.ContactName}
                    ContactID={contact.ContactID}
                    ImagePath={contact.ImagePath}
                    dispatch={this.props.dispatch}
                    onAvatarClick={() => this.props.dispatch(showModal('ViewContactCard', {ContactID: contact.ContactID}))}
                    onRemoveBadge={() => this.handleBadgeCloseClick(contact.ContactID, 'watchers')}
                />
            )
        })

        Candidates.forEach(it => {
            let AreaCode = it.phones?.AreaCode ? `(${it.phones?.AreaCode})-` : ''
            let PhoneExtension = it.phones?.PhoneExtension ? ` (${it.phones?.PhoneExtension}) ` : ''
            return (
                <tr className="font-weight-bold">
                    <td className="px-3">{it.ContactName ? it.ContactName :
                        <span className="text-tm-gray-500"></span>}</td>
                    <td className="px-3">{it.Email ? it.Email : <span className="text-tm-gray-500"></span>}</td>
                    <td className="px-3">{it.phones ? <>{AreaCode + it.phones?.PhoneNumber + PhoneExtension}</> :
                        <span className="text-tm-gray-500"></span>}</td>
                </tr>
            )
        })

        const cronPopover = (
            <div className="bg-popup p-4 rounded-md border border-tm-gray-300">
                {/* CRON PART*/}
                <div
                    className="flex justify-between items-center mb-4 pb-2 border-b border-tm-gray-200">
                    <h2 className="text-lg">Repeat task</h2>

                    {fieldsToHtml(Object.values(Object.assign({}, this.getStateFields(this.state.fields_info, null, ['Repeat']))), translate, this.handleInputChange, selects)}
                </div>

                <div className="">
                    <Cron
                        onChange={(e) => this.handleInputChange(this.state.fields_info.Cron.name, e)}
                        value={this.state.fields_info.value?.value}
                        showResultText={true}
                        options={{
                            //headers: [HEADER.MONTHLY, HEADER.WEEKLY, HEADER.DAILY]
                        }}
                    />
                </div>

                <div className="col-span-6">
                    <div className="mt-2">
                        <div className="flex items-center space-x-3">
                            <input
                                type={'radio'}
                                value={'none'}
                                name={'Repeat'}
                                checked={this.state.selectedRepeatOption === 'none'}
                                onChange={() => {
                                    let fields = this.state.fields_info
                                    fields.EndDate.value = ''
                                    fields.EndAfter.value = ''
                                    this.setState({
                                        fields_info: fields,
                                        selectedRepeatOption: 'none'
                                    })
                                }}
                            />

                            <p className="text-sm font-semibold text-tm-gray-900">{translate('field.None')}</p>
                        </div>
                    </div>

                    <div className="my-3 flex justify-between items-center">
                        <div className="flex items-center space-x-3">
                            <input
                                type={'radio'}
                                value={'EndDate'}
                                name={'Repeat'}
                                checked={this.state.selectedRepeatOption === 'EndDate'}
                                onChange={() => this.setState({
                                    selectedRepeatOption: 'EndDate',
                                    fields_info: FieldsManager.updateField(this.state.fields_info, 'EndAfter', '')
                                })}
                            />

                            <p className="text-sm font-semibold text-tm-gray-900">{translate('field.EndDate')}</p>
                        </div>

                        <div className="flex items-center space-x-3">
                            <div className="form-group">
                                <label className="label-float" htmlFor="notes">{translate('field.EndDate')}</label>

                                <FieldDate
                                    showTimeSelect={false}
                                    addClass={'form-control text-center'}
                                    onChange={this.handleInputChange}
                                    {...this.state.fields_info.EndDate}
                                    disabled={this.state.selectedRepeatOption !== 'EndDate'}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-between items-center">
                        <div className="flex items-center space-x-3">
                            <input
                                type={'radio'}
                                value={'EndAfter'}
                                name={'Repeat'}
                                checked={this.state.selectedRepeatOption === 'EndAfter'}
                                onChange={() => this.setState({
                                    selectedRepeatOption: 'EndAfter',
                                    fields_info: FieldsManager.updateField(this.state.fields_info, 'EndDate', '')
                                })}
                            />

                            <p className="text-sm font-semibold text-tm-gray-900">{translate('field.EndAfter')}</p>
                        </div>

                        <div className="flex items-center space-x-3">
                            <div className="form-group">
                                <label className="label-float"
                                       htmlFor="notes">{translate('field.EndAfterNumber')}</label>

                                <FieldText
                                    showTimeSelect={false}
                                    addClass={'form-control text-center'}
                                    onChange={this.handleInputChange}
                                    {...this.state.fields_info.EndAfter}
                                    disabled={this.state.selectedRepeatOption !== 'EndAfter'}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form-group mt-6">
                        <label className="label-float" htmlFor="notes">{translate('field.Notes')}</label>

                        <FieldTextarea
                            id="notes"
                            rows={3}
                            {...this.state.fields_info.Notes}
                            onChange={this.handleInputChange}
                            addClass={'form-control whitespace-pre-wrap'}
                        />
                    </div>
                </div>

            </div>
        )

        return (
            <React.Fragment>
                <ModalHeader
                    title={this.props.TaskID ? (`#${this.props.TaskID} - ${this.state.fields_info.TaskName.value}`) : translate('dialog_heading.create_task')}
                    onClose={() => this.hideModal()}
                >
                    <div className="flex flex-grow w-full justify-end px-4 space-x-3 relative pr-10">
                        <div>
                            <Tippy
                                content={<span>{translate('text.needsAttention')}</span>}
                                delay={[400, 0]}
                                trigger={"mouseenter"}
                            >
                                <button
                                    type="button"
                                    onClick={this.handleMarkedClick}
                                    className={classNames("btn btn-icon relative z-10 m-0 overflow-hidden", !this.state.fields_info.Marked?.value ? undefined : "text-red-500")}
                                >
                                    <FireIcon className="h-4 w-4"/>

                                    {!!this.state.fields_info.Marked?.value && (
                                        <span
                                            className="-inset-0.5 animate-ping absolute inline-flex rounded-full border-8 border-red-600 opacity-75 z-0"></span>
                                    )}
                                </button>
                            </Tippy>
                        </div>


                        <Tippy disabled={!this.state.canSubmit || !isTaskInProgress}
                               content={translate('text.postpone_task_save')}>
                            <div>
                                <Tippy
                                    content={
                                        isTaskInProgress
                                            ? translate('text.postpone_task')
                                            : translate('text.postpone_task_disabled')
                                    }
                                    disabled={!!this.state.canSubmit}
                                    delay={[400, 0]}
                                    trigger={"mouseenter"}
                                >
                                    <span>
                                        <button
                                            onClick={this.togglePostponeModal}
                                            className="btn btn-icon"
                                            disabled={!!this.state.canSubmit || !isTaskInProgress}
                                        >
                                            <CalendarIcon className={'h-4 w-4'}/>
                                        </button>
                                    </span>
                                </Tippy>
                            </div>
                        </Tippy>

                        <Flags authorizedFlags={['RepeatTaskON']}
                               renderOn={() => (
                                   <div>
                                       <PopOver
                                           btnClass="btn btn-icon"
                                           BtnIcon={ArrowPathIcon}
                                           btnIconClass="h-4 w-4"
                                           widthClass="max-w-xl"
                                           positionClass="absolute translate-x-0 right-0"
                                           tippy={translate('text.repeat_task')}
                                       >
                                           {cronPopover}
                                       </PopOver>
                                   </div>
                               )}
                               renderOff={() => null}
                        />

                        <div>
                            <PopoverWatchers
                                watchersContacts={watchersContacts}
                                onToggleContactsModal={this.handleToggleContactsModal}
                                onRemoveAllClick={() =>
                                    this.setState({
                                        watchers: []
                                    })
                                }
                                translate={translate}
                            />
                        </div>
                    </div>
                </ModalHeader>

                {!!isLoading && (
                    <div className="absolute inset bg-inverse z-index-10 items-center">
                        <LoaderSmall/>
                    </div>
                )}

                <div
                    ref={this.dialogBodyRef}
                    className={classNames('h-dialog-body grid ', this.props.TaskID ? 'grid-cols-2' : 'grid-cols-1')}
                    style={{scrollBehavior: 'smooth'}}
                >
                    <div>
                        {/* INFO FIELDS
                        ================================================================= */}
                        <div className="grid grid-cols-1 gap-4 px-6 pt-3">
                            <div className="col-span-1 space-y-4 mb-5">
                                {this.state.fields_info.Cron.value && this.state.cron && (
                                    <div
                                        className="flex justify-center items-center bg-tm-gray-100 font-bold p-3 rounded-lg mb-4 border-2 border-tm-gray-200 border-dashed">
                                        <ArrowPathIcon className="w-5 h-5 mr-3 text-tm-gray-700"/>
                                        {cronstrue.toString(getProp(this.state.fields_info.Cron, 'value', '0 */1 * * *').toString().replace(/,/g, ' ').replace(/!/g, ','))}
                                    </div>
                                )}

                                <div>
                                    {fieldsHtmlTaskGroupID}

                                    {(Number(this.state.fields_info.PrivateTasks.value) === 1 && !this.props.TaskID) && (
                                        <div className={"-mx-5 my-5"}>
                                            <InfoBar type="info">
                                                {translate("text.PrivateTasks")}
                                            </InfoBar>
                                        </div>
                                    )}

                                    {(Number(this.state.fields_info.PrivateTasks.value) === 1 && (!!this.props.TaskID && !item?.TaskGroupID)) && (
                                        <div className={"-mx-5 my-5"}>
                                            <InfoBar type="info">
                                                {translate("text.isPrivateTask")}
                                            </InfoBar>
                                        </div>
                                    )}

                                    {getProp(this.state.fields_info, 'TaskGroupID.value.metadata.TaskGroupDesc', '') && (
                                        <div className={"my-5"}>
                                            <label
                                                className={"text-tm-gray-900 font-semibold text-sm"}>{translate("TaskGroupDesc")}:</label>
                                            <InfoBar type="info">
                                                {getProp(this.state.fields_info, 'TaskGroupID.value.metadata.TaskGroupDesc', '')}
                                            </InfoBar>
                                        </div>
                                    )}
                                </div>

                                <div className="grid grid-cols-6 gap-4">
                                    {fieldsHtmlColLeft1}
                                </div>

                                <div className="grid grid-cols-6 gap-4">
                                    {fieldsHtmlColLeft2}
                                </div>

                                {this.props.TaskID && (
                                    <div>
                                        <label
                                            className={"text-tm-gray-900 font-semibold text-sm"}>{translate("field.Requester")}</label>
                                        <div className={"text-sm mt-2"}>{this.state.fields_info.Requester.value}</div>
                                    </div>
                                )}

                                <div className="flex space-x-4">
                                    {!this.state.fields_info.TaskGroupID.value && Number(this.state.fields_info.PrivateTasks.value) === 0 && (
                                        <div className="flex w-full flex-col">
                                            <div
                                                className="flex items-center text-sm font-semibold text-tm-gray-900 h-5 whitespace-nowrap">
                                                {translate("field.Assignee")}
                                            </div>

                                            <InfoParagraph
                                                className="mb-3 w-full"
                                                message={infoMessage}
                                            />
                                        </div>
                                    )}

                                    {(!!this.state.fields_info.TaskGroupID.value || Number(this.state.fields_info.PrivateTasks.value) === 1) && (
                                        <React.Fragment key={this.state.fields_info?.TaskGroupID?.value?.value}>
                                            {assigneeField}
                                        </React.Fragment>
                                    )}
                                </div>

                                <div className="grid grid-cols-6 gap-4">
                                    {fieldsHtmlColLeft3}
                                </div>

                                <div className="mb-4">
                                    <div className="flex items-center">
                                        {!isTaskClosed && (
                                            <PopOver
                                                btnClass={classNames('btn btn-outline-secondary p-2 flex items-center mr-6')}
                                                BtnIcon={TagIcon}
                                                btnLabel={translate('btn.Tags')}
                                                btnIconClass={classNames('text-tm-gray-600 w-5 h-5 mr-1')}
                                                customIcon={<ChevronUpIcon
                                                    className={classNames('text-tm-gray-600 w-5 h-5')}/>}
                                                widthClass={'max-w-[16rem]'}
                                                positionClass="fixed md:absolute left-0 mx-5 md:mx-0 translate-x-0 bottom-12"
                                                PopoverButtonRef={this.PopoverButtonRef}
                                            >
                                                <div
                                                    className="bg-popup border border-tm-gray-300 rounded-md"
                                                >
                                                    <TableTagManagerPopover
                                                        translate={translate}
                                                        labels={this.state.tagsData}
                                                        taggedRows={{
                                                            list: taggedLabels
                                                        }}
                                                        selectedRows={{[this.props.TaskID]: this.props.TaskID}}
                                                        onApplySelected={(taggedRows, labelList) => this.handleTagRowsClick(labelList)}
                                                        onManageTagsClick={this.toggleCreateNewTagModal}
                                                        isLoading={false}
                                                    />
                                                </div>
                                            </PopOver>
                                        )}

                                        {!this.props.dialogResource.isLoading && (
                                            <div className="gap-2 flex flex-wrap items-center">
                                                {this.state.setTags.map((it, i) => {
                                                    return (
                                                        <div
                                                            key={i}
                                                            className="sm:mt-0">
                                                            <div className="flex flex-wrap items-center">
                                                                <Badge
                                                                    onClick={() => this.onTagClick(i)}
                                                                    type="custom"
                                                                    addClass={`bg-inverse relative cursor-pointer border border-tm-gray-300 flex items-center space-x-2 hover:bg-tm-gray-100 group`}
                                                                >
                                                                    <span
                                                                        style={{background: it.color}}
                                                                        className="w-3 h-3 rounded-full"
                                                                    />
                                                                    <span
                                                                        className="group-hover:line-through">{it.label}</span>
                                                                </Badge>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )}
                                    </div>


                                    {this.props.dialogResource.isLoading && (
                                        <div className="relative">
                                            <LoaderSmall/>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-1 gap-4 px-6">
                            {!!(documents.length || item.TaskStatusID || !this.props.TaskID) && (
                                <div className="col-span-1 md:col-span-1">
                                    <h5
                                        className="block text-sm font-semibold text-tm-gray-700">{translate('card_header.attachments')}</h5>

                                    {!this.props.download.isLoading && (
                                        <div>
                                            <Dropzone
                                                onDrop={(acceptedFiles, rejectedFiles) => {
                                                    if (acceptedFiles.length) {
                                                        this.setState({
                                                            acceptedFiles: acceptedFiles.map(file => Object.assign(file, {
                                                                preview: URL.createObjectURL(file)
                                                            })),
                                                            Descriptions: new Array(acceptedFiles.length).fill(''),
                                                            filesUploadDialog: true,
                                                            rejectedFiles: rejectedFiles,
                                                            dropzoneActive: false
                                                        });
                                                    }
                                                }}
                                                onDragEnter={this.onDragEnter}
                                                onDragLeave={this.onDragLeave}
                                                onDropAccepted={this.handleDropAccepted}
                                                accept={DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS}
                                                maxSize={MAXIMUM_DOCUMENT_UPLOAD_SIZE}
                                                multiple={true}
                                            >
                                                {({getRootProps, getInputProps}) => (
                                                    <section>
                                                        <div
                                                            style={{height: '110px'}}
                                                            {...getRootProps()}
                                                            className={'border-2 flex items-center justify-center' + (this.state.dropzoneActive ? ' border-primary border-solid text-primary' : ' border-tm-gray-200 border-dashed text-tm-gray-700')}>
                                                            <input {...getInputProps()} />

                                                            <p className="m-0"
                                                               style={{userSelect: 'none'}}>{translate('field.drag_n_drop')}</p>
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </div>
                                    )}

                                    {(!!documents.length || !!existingDocuments.length) && !this.props.download.isLoading && (
                                        <div className="mt-4">
                                            <label className="text-tm-gray-700 font-bold mt-6">
                                                {translate('text.attachment_list')}
                                            </label>

                                            {this.canCreateLoad && (
                                                <FieldsToHtml
                                                    fieldsState={this.state.ocrFields}
                                                    onInputChange={this.handleOcrInputChange}
                                                    translate={translate}
                                                />
                                            )}

                                            <div className="grid gap-4">
                                                {existingDocuments}
                                                {documents}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}

                            {(!isTaskClosed || !!this.state.linkedTasks.length) && (
                                <div className="mb-5 space-y-4">
                                    <div>
                                        <h5
                                            className="block text-sm font-semibold text-tm-gray-700">{translate('card_header.linked_issues')}</h5>

                                        <LinkedTasks
                                            data={this.state.linkedTasks}
                                            translate={translate}
                                            handleUnlinkIssue={this.handleUnlinkIssue}
                                            onAddLinkedTaskClick={this.handleAddLinkedTaskClick}
                                            handleUpdateDataValues={this.handleUpdateLinkedTasksValues}
                                            TaskID={getProp(this.props.taskResource, 'data.task.TaskID', '')}
                                            TaskName={getProp(this.props.taskResource, 'data.task.TaskName', '')}
                                            // disableCreate={isTaskClosed}
                                        />
                                    </div>


                                    <div>
                                        {this.props.download.isLoading && (
                                            <LoaderSmall
                                                addClass={'m-10-auto'}
                                            />
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    {!!this.props.TaskID && (
                        <div className="border-l border-tm-gray-200 pt-2">
                            {/* TABS
                        ================================================================= */}
                            <React.Fragment>
                                <div className="px-6">
                                    <div className="mb-3">
                                        <Nav tabs={this.state.tabs} onTabChange={this.handleTabChange}/>
                                    </div>
                                </div>

                                <div className="px-6 pb-7">
                                    <div className="">
                                        {selectedTab.resource === 'comments' && (
                                            <>
                                                <div
                                                    className=""
                                                >
                                                    <div
                                                        className="max-h-[calc(100vh-28rem)] overflow-auto"
                                                        id="commentSection"
                                                    >
                                                        <TaskFormComments
                                                            onEditComment={this.editComment}
                                                            onDeleteComment={this.deleteCommentHandler}
                                                            onSubmitComment={this.editCommentHandler}
                                                            selectedItem={this.state.editedComment}
                                                            TaskID={this.props.TaskID}
                                                            afterConfirmDelete={() => this.setState({confirmModal: false})}
                                                            translate={translate}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="mt-3 space-y-4">
                                                    <FieldContainer
                                                        key={'TaskComment'}
                                                        item={this.state.fields['TaskComment']}
                                                        translate={translate}
                                                    >
                                                        <FieldTextarea
                                                            onChange={(name, value) => this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)})}
                                                            {...this.state.fields.TaskComment}
                                                            placeholder={''}
                                                            addClass={'form-control whitespace-pre-wrap'}/>
                                                    </FieldContainer>

                                                    <div className="text-right">
                                                        <button className="btn btn-outline "
                                                                onClick={() => {
                                                                    this.addCommentHandler()
                                                                }}>{translate('btn.add_comment')}
                                                        </button>
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                        {selectedTab.resource === 'history' && (
                                            <TaskHistory
                                                history={getProp(taskResource, 'data.changes', [])}
                                                onContactClick={this.handleHistoryContactClick}
                                                translate={translate}
                                            />
                                        )}
                                    </div>
                                </div>
                            </React.Fragment>
                        </div>
                    )}
                </div>

                <ModalFooter
                    buttonLabel={translate('btn.update')}
                    buttonDisabled={!this.state.canSubmit}
                    onButtonClick={this.submit}
                    closeButtonLabel={translate('btn.close')}
                    onClose={this.hideModal}
                />

                {/* MODALS
                ================================================================= */}
                {/*Upload file dialog*/}
                <ModalDefault
                    show={this.state.filesUploadDialog}
                    widthClass={'max-w-5xl'}
                    title={translate('modal_heading.upload_confirm')}

                    onButtonClick={this.uploadDocument}
                    buttonLabel={translate('btn.attach')}
                    buttonDisabled={!this.state.canSubmit}

                    closeButtonLabel={translate('btn.Close')}
                    onClose={() => this.cancelUpload()}
                    isLoading={false}
                >
                    <React.Fragment>

                        {this.props.TaskID === undefined && (
                            <div className="mb-5 p-1">
                                <InfoBar>Attached files will be uploaded on form submit</InfoBar>
                            </div>
                        )}

                        {!!rejectedFiles.length && (
                            <div className="mb-5 p-1">
                                <InfoParagraph type="danger">
                                    <p className="default-soft">Following files have been rejected:</p>
                                    <ul>
                                        {rejectedFiles}
                                    </ul>
                                </InfoParagraph>
                            </div>
                        )}

                        {this.state.acceptedFiles.map((it, i) => {
                            return (
                                <div
                                    key={i}
                                    className="form-group mb-3 p-3"
                                >

                                    <label>{translate('text.desc_for_file')}: {this.state.acceptedFiles[i].name}</label>

                                    <FieldTextarea
                                        addClass="form-control whitespace-pre-wrap"
                                        onChange={(name, value) => this.handleInputChange('Description', value, i)}
                                        value={this.state.Descriptions[i]}
                                        placeholder=""
                                    />
                                </div>
                            )
                        })}
                    </React.Fragment>

                </ModalDefault>

                {/*Preview file dialog*/}
                <ModalDefault
                    show={!!this.state.previewModal}
                    title={translate('preview') + ' - ' + this.state?.previewDocument?.DocumentName}
                    widthClass={'max-w-7xl'}
                    limitHeight={true}
                    translate={translate}
                    onClose={() => this.handleClosePreview()}
                    closeButtonLabel={translate('Close')}
                    buttonLabel={translate('Download')}
                    onButtonClick={this.downloadDocument}
                >
                    <FileViewer
                        fileType={this.state?.previewDocument?.OriginalFilename?.split('.').pop()}
                        filePath={Env.getApiUrl('api/' + Resources.TasksDocuments, {
                            DocumentID: this.state?.previewDocument?.DocumentID,
                            token: getJWT().access_token,
                            id: this.props.TaskID
                        })}
                        onError={(e) => {
                            console.log(e)
                        }}/>
                </ModalDefault>

                <ModalDefault
                    show={!!this.state.isBlobPreviewOpen}
                    title={translate('preview') + ' - ' + this.state?.previewDocument?.name}
                    widthClass={'max-w-3xl'}
                    limitHeight={true}
                    translate={translate}
                    onClose={() => this.handleCloseBlobPreview()}
                    closeButtonLabel={translate('Close')}
                >
                    <FileViewer
                        fileType={this.state?.previewDocument?.name?.split('.').pop()}
                        filePath={this.state?.previewDocument?.preview}
                        onError={(e) => {
                            console.log(e)
                        }}/>
                </ModalDefault>

                {/*Add candidates for a task*/}
                <ModalDefault
                    show={!!this.state.addAssigneeModal}
                    title={translate('text.add_candidates_for_task')}
                    widthClass={'max-w-4xl'}
                    translate={translate}
                    limitHeight={true}
                    hideDialogFooter={true}
                    onClose={() => this.handleCloseContactsModal('Assignee')}
                >
                    <ContactsSelectModal
                        {...this.props}
                        onClose={() => this.handleCloseContactsModal('Assignee')}
                        onSubmitClick={this.handleAssignAssignees}
                        contactsList={contactsList}
                    />
                </ModalDefault>

                {/*Add watcher for a task*/}
                <ModalDefault
                    show={!!this.state.addWatcherModal}
                    title={translate('text.add_watcher_for_task')}
                    widthClass={'max-w-4xl'}
                    translate={translate}
                    limitHeight={true}
                    hideDialogFooter={true}
                    onClose={() => this.handleCloseContactsModal('Watcher')}
                >
                    <ContactsSelectModal
                        {...this.props}
                        onClose={() => this.handleCloseContactsModal('Watcher')}
                        onSubmitClick={this.handleAssignWatchers}
                        contactsList={watchersContactsList}
                        addQuery={{
                            TaskGroupID: this.state.fields_info?.TaskGroupID?.value?.value
                        }}
                    />
                </ModalDefault>

                <ModalConfirm
                    title={'Confirm delete'}
                    show={!!this.state.confirmModal}
                    text={this.state.confirmText}
                    buttonLabel={translate('btn.confirm')}
                    onClose={() => this.setState({confirmModal: false})}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.state.confirmModal}
                />

                <Modal
                    show={this.state.isPostponeTaskModalVisible}
                    widthClass="max-w-sm"
                    onClose={() => this.togglePostponeModal()}
                >
                    <TaskPostponeForm
                        StartDate={this.state.fields_info.StartDate.value}
                        StartDateTime={this.state.fields_info.StartDateTime.value}
                        DueDate={this.state.fields_info.DueDate.value}
                        DueDateTime={this.state.fields_info.DueDateTime.value}
                        onClose={() => this.togglePostponeModal()}
                        onSubmit={(params) => {
                            if (params) {
                                const StartDate = params.PostponeStartDate;
                                const DueDate = params.PostponeDueDate;

                                const Notes = params.Notes
                                const id = this.props.TaskID

                                this.props.dispatch(updateTaskResource({
                                    user: LocalStorage.get('user'),
                                    params: {StartDate, DueDate, Notes, id},
                                    resource: Resources.TaskPostpone,
                                    piggyResource: Resources.TasksInfo,
                                    query: {
                                        id: this.props.TaskID,
                                    },
                                    errorMessage: true, successMessage: translate('text.task_dates_updated')
                                }))

                                this.togglePostponeModal()
                            }
                        }}

                        translate={translate}
                    />
                </Modal>

                <TableTagManagerModal
                    show={this.state.isTagManagerModalOpen}
                    onClose={this.toggleCreateNewTagModal}
                    translate={translate}
                    onCreateTag={this.createTag}
                    tagsData={this.state.tagsData}
                    isLoading={this.props.dialogResource.isLoading}
                />
            </React.Fragment>
        )
    }
}

export default connect(state => state)(TasksFormDialog)
