import React, {Fragment, useEffect, useRef, useState} from 'react'
import {Dialog, Menu, Transition} from '@headlessui/react'
import {Bars3BottomLeftIcon, MagnifyingGlassIcon, MoonIcon, SunIcon, XMarkIcon,} from '@heroicons/react/24/outline'
import ChevronRightIcon from "@heroicons/react/20/solid/ChevronRightIcon";
import {useDispatch, useSelector} from "react-redux";
import GlobalDialogs from "./global-dialogs"
import ArrowsPointingOutIcon from "@heroicons/react/24/outline/ArrowsPointingOutIcon";
import ArrowsPointingInIcon from "@heroicons/react/24/outline/ArrowsPointingInIcon";
import {Link, useHistory} from "react-router-dom";
import {hideGlobalModal, showGlobalModal} from "../../../data/actions/ui";
import {checkPerm, classNames} from "../../util/util-helpers";
import {clearCookies, getJWT, getUser, saveJWTToken} from "../../util/util-auth";
import MainMenu from "./layout-main-menu";
import CompanyLogo from "./company-logo";
import UserMenu from "./user-menu";
import AppPicker from "./layout-components/app-picker";
import ThemePicker from "./layout-components/theme-picker";
import TaskGrab from "./layout-components/grab-task";
import Env, {_ENV_MODE} from "../../../util/env";
import {logoutClear} from "../../../data/actions/user";
import LocalStorage from "../../util/localStorage";
import Cookie from "../../../util/cookie";
import axios from "axios";
import ArrowTopRightOnSquareIcon from "@heroicons/react/24/outline/ArrowTopRightOnSquareIcon";
import {Prompt} from "react-router";
import UpdateBreakPoint from "./layout-components/update-breakpoint"
import ModalConfirm from "../modal/modal-confirm";
import ProfileImage from "./layout-components/profile-image";
import ModalDefault from "../modal/modal-default";
import DocumentHeader from "./layout-components/document-header";
import LogoutOutlineIcon from "@heroicons/react/24/outline/ArrowRightOnRectangleIcon";
import useTheme from "../../hooks/use-theme";
import QuickMenu from "./layout-components/quick-menu";
import NotificationsMenu from "./layout-components/notifications-menu";
import Mousetrap from "mousetrap";
import Resources from "../../../data/services/resources";
import {CREATE_PERM, UPDATE_PERM} from "../../util/util-consts";
import {getGrabTaskResource} from "../../../data/actions/taskResource";
import NotFound from "../../../views/general/not-found/not-found";
import {SECTIONS_VISIBILITY} from "../../../util/util-constants";

let menuTimeout;

export default function Layout({
                                   children,
                                   translate,
                                   isDirty,
                                   layoutRef,
                                   match,
                                   onBreakpointChange,
                                   onScroll,
                                   hasFooter,
                                   customPageTitle,
                                   isAccessible = true,
                                   accessibleMessageResource,
                                   notFoundBackLink,
                                   notFoundBackBtnLabel
                               }) {
    /** Constants
     ================================================================= */
    const dispatch = useDispatch();
    const history = useHistory();
    const [theme, setTheme] = useTheme();

    const ui = useSelector((state) => state.ui);

    const isAIChatVisible = ui?.AIChatPosition === 'bottom' && ui?.isAIChatVisible;

    const hasQuickMenu = SECTIONS_VISIBILITY.hasQuickMenu === true;
    const hasNotificationMenu = SECTIONS_VISIBILITY.hasNotificationMenu === true;

    const user = {
        name: getUser("Contact.FirstName") + " " + getUser("Contact.LastName"),
        email: getUser("Contact.Email,"),
        ContactID: getUser("Contact.ContactID"),
        ImagePath: getUser("Contact.ImagePath"),
        token: getJWT().access_token
    }
    const confirmProceed = useRef(false);

    /** State
     * ================================================================= */
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isSidebarExpanded, setIsSidebarExpanded] = useState(() => LocalStorage.get('is_sidebar_menu_expanded', true));
    const [isMainExpanded, setIsMainExpanded] = useState(false);

    const [isCollapseButtonVisible, setIsCollapseButtonVisible] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isThemesDialogOpen, setIsThemeDialogOpen] = useState(false);

    const [blockedLocation, setBlockedLocation] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [navigationBlocked, setIsNavigationBlocked] = useState(true);
    const [doesStoredUserExist, setDoesStoredUserExist] = useState(true);
    const [logout, setLogout] = useState(false);
    const handleMenuMouseEnter = () => {
        setIsCollapseButtonVisible(true);

        if (!isSidebarExpanded) {
            menuTimeout = setTimeout(() => setIsMainExpanded(true), 300);
        }
    }

    const handleMenuMouseLeave = () => {
        setIsCollapseButtonVisible(false);
        clearTimeout(menuTimeout);
        setIsMainExpanded(false);
    }

    const handleButtonMouseEnter = () => {
        if (!isSidebarExpanded) {
            clearTimeout(menuTimeout)
        }
    }

    const handleExpandMenuClick = () => {
        if (isSidebarExpanded) {
            setIsSidebarExpanded(false);
            //setIsMainExpanded(false);
        } else {
            setIsSidebarExpanded(true);
            //setIsMainExpanded(true);
        }

        LocalStorage.set('is_sidebar_menu_expanded', !isSidebarExpanded);
    }

    const onFullScreenToggle = () => {
        const elem = document.getElementsByTagName("BODY")[0];
        if (elem) {
            if (isFullScreen) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) { /* Safari */
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) { /* IE11 */
                    document.msExitFullscreen();
                }

                setIsFullScreen(false);
            } else {
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.webkitRequestFullscreen) { /* Safari */
                    elem.webkitRequestFullscreen();
                } else if (elem.msRequestFullscreen) { /* IE11 */
                    elem.msRequestFullscreen();
                }

                setIsFullScreen(true);
            }
        }
    }

    function handleTabFocus() {
        const token = Cookie.getCookie("access_token_" + _ENV_MODE);

        if (!token) {
            LocalStorage.set("redirect_path", match.url);
            history.push('/login')
        } else if (getUser("access_token") !== token) {
            LocalStorage.set("redirect_path", match.url);
            history.push('/logging-in')
        }
    }

    function handleBlockedNavigation(nextLocation) {
        if (navigationBlocked) {
            setBlockedLocation(nextLocation);
            setIsConfirmModalOpen(true);
            return false
        }

        return true
    }


    function handleReLoginClick() {
        if (getUser()) {
            axios.post(
                Env.getApiUrl('refresh'),
                'grant_type=refresh_token&refresh_token=' + getUser('refresh_token'),
                {
                    headers: {
                        'Authorization': 'Basic ' + Env.getPublicToken(),
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
            )
                .then((result) => {
                    if (result && result.status === 200) {

                        if (LocalStorage.get('user') && LocalStorage.get('user')?.Contact) {
                            const newUser = Object.assign(
                                {},
                                LocalStorage.get('user'),
                                result.data,
                            );
                            LocalStorage.set('user', newUser);
                        }

                        saveJWTToken(result.data);
                    }
                })


            //saveJWTToken(getUser());
            dispatch(hideGlobalModal('loggedOffModal'));
            dispatch(hideGlobalModal("logoutIdleModal")); // maybe redundant
        } else {
            setDoesStoredUserExist(false);
        }
    }

    function bindMousetrap() {
        const commands = LocalStorage.get("quick_commands")

        if (commands?.['DarkMode']) {
            Mousetrap.bind(commands['DarkMode'].toLowerCase(), () => {
                setTheme(theme === "dark" ? "light" : "dark");
            }, 'keyup');
        }

        if (commands?.['OpenSearch'] && SECTIONS_VISIBILITY.hasGlobalSearch !== false) {
            Mousetrap.bind(commands['OpenSearch'].toLowerCase(), () => {
                dispatch(showGlobalModal("globalSearchPopup"));
            }, 'keyup');
        }

        if (commands?.['NewLoad']) {
            checkPerm(Resources.Load, UPDATE_PERM) && Mousetrap.bind(commands['NewLoad'].toLowerCase(), () => {
                history.push('/loads/create')
            }, 'keyup');
        }

        if (commands?.['NewDriver'] && SECTIONS_VISIBILITY.hasPersonnelSection !== false) {
            checkPerm(Resources.Drivers, CREATE_PERM) && Mousetrap.bind(commands['NewDriver'].toLowerCase(), () => {
                history.push(`/driver/create`)
            }, 'keyup');
        }

        if (commands?.['NewTrailer'] && SECTIONS_VISIBILITY.hasFleetSection !== false) {
            checkPerm(Resources.Trailers, CREATE_PERM) && Mousetrap.bind(commands['NewTrailer'].toLowerCase(), () => {
                history.push('/trailer/create');
            }, 'keyup');
        }

        if (commands?.['NewTruck'] && SECTIONS_VISIBILITY.hasFleetSection !== false) {
            checkPerm(Resources.Trucks, CREATE_PERM) && Mousetrap.bind(commands['NewTruck'].toLowerCase(), () => {
                history.push('/truck/create');
            }, 'keyup');
        }

        if (commands?.['NewLocation'] && SECTIONS_VISIBILITY.hasPlacesSection !== false) {
            checkPerm(Resources.Locations, CREATE_PERM) && Mousetrap.bind(commands['NewLocation'].toLowerCase(), () => {
                dispatch(showGlobalModal('locationFormDialog', null))
            }, 'keyup')
        }

        if (commands?.['GrabTask'] && SECTIONS_VISIBILITY.hasTasksSection !== false) {
            Mousetrap.bind(commands['GrabTask'].toLowerCase(), () => {
                dispatch(getGrabTaskResource({
                    user: LocalStorage.get("user"),
                    resource: Resources.TaskGrab,
                    request: 'POST'
                }))
            }, 'keyup')
        }

        if (commands?.['CreateTask'] && SECTIONS_VISIBILITY.hasTasksSection !== false) {
            checkPerm(Resources.Tasks, CREATE_PERM) && Mousetrap.bind(commands['CreateTask'].toLowerCase(), () => {
                dispatch(showGlobalModal('createTaskDialog', {globalDialog: true}))
            }, 'keyup')
        }

        if (commands?.['NewNote'] && SECTIONS_VISIBILITY.hasDashboard !== false) {
            Mousetrap.bind(commands['NewNote'].toLowerCase(), () => {
                dispatch(showGlobalModal('PersonalNoteDialog'))
            }, 'keyup')
        }

        if (commands?.['AllTasks'] && SECTIONS_VISIBILITY.hasTasksSection !== false) {
            Mousetrap.bind(commands['AllTasks'].toLowerCase(), () => {
                history.push("/all-tasks");
            }, 'keyup')
        }
    }

    useEffect(() => {
        window.addEventListener("focus", handleTabFocus);

        return () => {
            window.removeEventListener("focus", handleTabFocus);
        }
    }, [dispatch, history])

    useEffect(() => {
        if (logout) {
            LocalStorage.remove('user');
            LocalStorage.remove('lookup');
            dispatch(logoutClear());
            history.push("/login");
            clearCookies();
        }
    }, [logout]);

    useEffect(() => {
        if (!navigationBlocked && !!confirmProceed.current && blockedLocation.pathname) {
            history.push(blockedLocation.pathname);
            confirmProceed.current = false;
        }
    }, [navigationBlocked]);

    useEffect(() => {
        if (SECTIONS_VISIBILITY.hasCustomShortcuts) {
            bindMousetrap();
        }
    }, [])

    return (
        <>
            <DocumentHeader translate={translate} match={match} customPageTitle={customPageTitle}/>

            <Prompt when={!!isDirty && !logout} message={handleBlockedNavigation}/>

            {!!onBreakpointChange && (
                <UpdateBreakPoint onBreakpointChange={onBreakpointChange}/>
            )}

            <div>
                <Transition.Root show={sidebarOpen} as={Fragment}>
                    <Dialog as="div" className="relative z-50 md:hidden" onClose={setSidebarOpen}>
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-black bg-opacity-50"/>
                        </Transition.Child>

                        <div className="fixed inset-0 z-40 flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transition ease-in-out duration-300 transform"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition ease-in-out duration-300 transform"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                <Dialog.Panel
                                    className="relative flex w-full max-w-xs flex-1 flex-col bg-popup text-tm-gray-900 pt-5 pb-4">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="absolute top-0 right-0 -mr-12 pt-2">
                                            <button
                                                type="button"
                                                className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                                onClick={() => {
                                                    setSidebarOpen(false)
                                                }}
                                            >
                                                <span className="sr-only">Close sidebar</span>
                                                <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true"/>
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    {/*<div className="flex flex-shrink-0 items-center px-4">*/}
                                    {/*    <CompanyLogo/>*/}
                                    {/*</div>*/}
                                    <div className="mt-5 h-0 flex-1 overflow-y-auto">
                                        <MainMenu/>

                                        <div
                                            className="px-3 md:z-10 md:ml-4 flex md:items-center border-tm-gray-300 border-t mt-5 pt-3 space-x-3">

                                            <button
                                                type="button"
                                                className="icon-btn"
                                                onClick={() => setTheme(theme === "dark" ? "light" : "dark")}
                                            >
                                                {theme === 'dark'
                                                    ? <SunIcon className="h-6 w-6" aria-hidden="true"/>
                                                    : <MoonIcon className="h-6 w-6" aria-hidden="true"/>
                                                }
                                            </button>
                                        </div>
                                    </div>

                                    <div className="flex-shrink-0 flex border-t border-tm-gray-300 p-4">
                                        <Link to="/my-profile" className="flex-shrink-0 group block">
                                            <div className="flex items-center">
                                                <div>
                                                    <ProfileImage user={user}/>
                                                </div>
                                                <div className="ml-3">
                                                    <p className="text-base font-medium text-tm-gray-900">{user.name}</p>
                                                    <p className="text-sm font-medium text-primary group-hover:text-primary-tint">View
                                                        profile</p>
                                                </div>
                                            </div>
                                        </Link>

                                        <div
                                            className="ml-auto flex justify-center items-center rounded-full bg-tm-gray-100 h-10 w-10 cursor-pointer">
                                            <button
                                                onClick={() => setLogout(true)}
                                            >
                                                <LogoutOutlineIcon className="h-6 w-6"/>
                                            </button>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                            <div className="w-14 flex-shrink-0" aria-hidden="true">
                                {/* Dummy element to force sidebar to shrink to fit close icon */}
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>

                <header className='bg-inverse shadow-header sticky top-0 z-[41] text-tm-gray-900'>
                    <div className="sticky top-0 flex h-16 flex-shrink-0 bg-inverse z-40">
                        <button
                            type="button"
                            className="border-r border-tm-gray-200 px-4 text-tm-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-tint outline-0 md:hidden"
                            onClick={() => setSidebarOpen(true)}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true"/>
                        </button>

                        {SECTIONS_VISIBILITY.hasGlobalSearch === true && (
                            <div
                                className="hidden lg:flex relative z-0 flex-1 px-2 justify-center items-center sm:absolute sm:inset-0"
                            >
                                <button
                                    type="button"
                                    className="flex justify-center items-center group leading-6 font-medium space-x-3 sm:space-x-4 transition-colors duration-200 py-2 px-4 hover:bg-tm-gray-100 rounded-full"
                                    onClick={() => dispatch(showGlobalModal("globalSearchPopup"))}
                                >
                                    <MagnifyingGlassIcon
                                        className="w-5 h-5 text-tm-gray-700 group-hover:te-text-tm-gray-500 te-transition-colors te-duration-200"/>

                                    <span className="text-base">{translate("text.quick_search")}</span>
                                </button>
                            </div>
                        )}

                        <div className="flex flex-1 px-3 justify-end">
                            <div className="flex flex-shrink-0 items-center h-16 z-10">
                                <CompanyLogo/>
                            </div>

                            <div className="hidden ml-auto md:flex items-center space-x-2 relative">
                                <TaskGrab/>

                                <button
                                    onClick={() => onFullScreenToggle()}
                                    type="button"
                                    className="icon-btn"
                                >
                                    <span className="sr-only">View notifications</span>

                                    <ArrowsPointingOutIcon className="h-6 w-6 fs-display-hide" aria-hidden="true"/>

                                    <ArrowsPointingInIcon className={"h-6 w-6 fs-display-show"}/>
                                </button>

                                <ThemePicker
                                    translate={translate}
                                    setIsThemeDialogOpen={setIsThemeDialogOpen}
                                    isThemesDialogOpen={isThemesDialogOpen}
                                />

                                {hasNotificationMenu && (
                                    <NotificationsMenu
                                        translate={translate}
                                    />
                                )}

                                {hasQuickMenu && (
                                    <QuickMenu/>
                                )}

                                <AppPicker/>

                                {/* Profile dropdown */}
                                <Menu as="div" className="flex-shrink-0 relative pl-2">
                                    {({open}) => (
                                        <>
                                            <div>
                                                <Menu.Button
                                                    className="bg-inverse rounded-full flex justify-center items-center focus:outline-none text-tm-gray-900">
                                                    <span className="sr-only">Open user menu</span>

                                                    <span
                                                        className={"select-none text-xs mr-3 " + (open ? "text-button" : "")}>{user.name}</span>

                                                    <ProfileImage user={user}/>
                                                </Menu.Button>
                                            </div>

                                            <Transition
                                                show={open}
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items
                                                    static
                                                    className="origin-top-right absolute right-0 mt-2 w-56 rounded-lg shadow-lg bg-inverse ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden z-50 "
                                                >
                                                    <div className="py-1">
                                                        <UserMenu
                                                            setIsThemeDialogOpen={setIsThemeDialogOpen}
                                                        />
                                                    </div>
                                                </Menu.Items>
                                            </Transition>
                                        </>
                                    )}
                                </Menu>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="flex bg-tm-gray-100 text-tm-gray-900">
                    {/* Static sidebar for desktop */}
                    <div
                        className={
                            classNames("hidden flex-shrink-0 md:bottom-0 md:flex md:flex-col duration-500 transition-width relative z-1 border-r border-tm-gray-200 bg-popup ml-0.5",
                                isSidebarExpanded || isMainExpanded ? "w-52 opacity-1" : "w-5"
                            )
                        }
                        onMouseEnter={handleMenuMouseEnter}
                        onMouseLeave={handleMenuMouseLeave}
                    >
                        <button
                            onClick={handleExpandMenuClick}
                            onMouseEnter={handleButtonMouseEnter}
                            className={classNames(
                                "flex h-7 w-7 text-tm-gray-700 border border-primary rounded-full absolute top-5 right-0 transform translate-x-1/2 items-center justify-center shadow z-50 bg-inverse hover:bg-tm-gray-200",
                                !isCollapseButtonVisible && isSidebarExpanded ? "opacity-0" : undefined
                            )}
                        >
                            <ChevronRightIcon className={
                                classNames(
                                    "w-5 h-5",
                                    isSidebarExpanded ? "rotate-180" : undefined
                                )
                            }/>
                        </button>

                        <div
                            className={
                                classNames(
                                    "flex flex-grow flex-col overflow-y-auto transition-width duration-500 ",
                                    isSidebarExpanded || isMainExpanded ? "overflow-y-auto opacity-1" : "overflow-y-hidden opacity-0"
                                )
                            }
                        >
                            <div className="mt-4 flex flex-grow flex-col">
                                <nav className={
                                    classNames(
                                        "flex-1 space-y-2 px-2 max-h-[calc(100vh-5rem)] overflow-y-auto",
                                        hasFooter ? "pb-20" : undefined
                                    )
                                }>
                                    <MainMenu/>
                                </nav>
                            </div>
                        </div>
                    </div>

                    {isAccessible && (
                        <main
                            ref={layoutRef}
                            className={
                                classNames(
                                    "bg-tm-gray-100 text-tm-gray-900",
                                    !hasFooter && !isAIChatVisible ? "h-[calc(100vh-64px)]" : undefined,
                                    !hasFooter && isAIChatVisible ? "h-[calc(100vh-28rem)]" : undefined,
                                    hasFooter && !isAIChatVisible ? "app-body-height-footer" : undefined,
                                    hasFooter && isAIChatVisible ? "h-[calc(100vh-32rem)]" : undefined,
                                    "my-0 m-0 overflow-auto w-full p-4"
                                )}
                            onScroll={onScroll}
                        >
                            {children}
                        </main>
                    )}

                    {!isAccessible && (
                        <main className="h-[calc(100vh-4rem)] w-full m-0">
                            <NotFound
                                backLink={notFoundBackLink}
                                backBtnLabel={notFoundBackBtnLabel}
                                accessibleMessageResource={accessibleMessageResource}
                                translate={translate}
                            />
                        </main>
                    )}
                </div>
            </div>

            <ModalConfirm
                type="default"
                onClose={() => setIsConfirmModalOpen(false)}
                onConfirm={() => {
                    setIsNavigationBlocked(false);
                    confirmProceed.current = true;
                }}
                buttonLabel={translate('btn.proceed')}
                closeButtonLabel={translate('btn.stay_on_page')}
                show={isConfirmModalOpen}
                title={translate("modal_heading.confirm")}
                translate={translate}
                customButtonsHTML={(
                    <Link
                        className="btn btn-outline mr-auto"
                        to={blockedLocation.pathname ?? ""}
                        target="_blank"
                        onClick={() => setIsConfirmModalOpen(false)}
                    >
                        <ArrowTopRightOnSquareIcon
                            className="h-4 w-4 -ml-1 mr-1"/> {translate("btn.open_in_new_tab")}
                    </Link>
                )}
            >
                <p>{translate("text.changes_on_page")}</p>
                <p>{translate("text.changes_will_be_lost")}</p>
            </ModalConfirm>

            <ModalDefault
                hideXbtn={true}
                show={ui?.globalDialogs?.loggedOffModal?.visible}
                widthClass={"max-w-xl"}
                title={translate("modal_heading.session_expired")}
                buttonLabel={doesStoredUserExist && getUser() ? "Continue with session" : ""}
                onButtonClick={handleReLoginClick}
                closeButtonLabel={translate("btn.logout")}
                onClose={() => setLogout(true)}
            >
                <div className="p-6">
                    {doesStoredUserExist && (
                        <p>Your session is expired due to inactivity in this or some other App in different tab.</p>
                    )}

                    {!doesStoredUserExist && (
                        <p>You logged off in some other tab and can not continue.</p>
                    )}

                </div>
            </ModalDefault>

            <GlobalDialogs
                translate={translate}
            />
        </>
    )
}

