import React from "react";

const ModalFooter = (props) => {
    const {
        buttonLabel,
        onButtonClick,
        buttonDisabled,
        buttonID,
        closeButtonLabel,
        onClose,
        closeButtonRef,
        secondaryButtonLabel,
        onSecondaryButtonClick,
        secondaryButtonDisabled,
        customHtmlLeft,
        customHtmlRight,
        closeButtonID
    } = props;
    return (
        <footer
            className="min-h-[4rem] flex flex-wrap flex-row-reverse items-center border-tm-gray-200 border-t px-6 py-2 gap-3 bg-popup rounded-b-lg">
            {(!!buttonLabel || !!closeButtonLabel) && (
                <div>
                    {!!customHtmlRight && (
                        <div className={"inline-flex mr-4"}>{customHtmlRight}</div>
                    )}

                    {!!buttonLabel && (
                        <button
                            data-name="submit-button-shortcut"
                            id={buttonID}
                            className={`btn btn-primary mr-2`}
                            onClick={() => {
                                if (!buttonDisabled) {
                                    onButtonClick();
                                }
                            }}
                            disabled={!!buttonDisabled}
                        >
                            {buttonLabel}
                        </button>
                    )}

                    {!!closeButtonLabel && (
                        <button
                            data-name="cancel-button-shortcut"
                            id={closeButtonID}
                            className="btn btn-outline"
                            onClick={() => {
                                onClose();
                            }}
                            ref={closeButtonRef}
                        >
                            {props.closeButtonLabel}
                        </button>
                    )}
                </div>
            )}

            <span className="flex mr-auto">
                {!!secondaryButtonLabel && (
                    <button
                        className={'btn btn-outline mr-auto'}
                        onClick={() => {
                            if (!secondaryButtonDisabled) {
                                onSecondaryButtonClick();
                            }
                        }}
                        disabled={!!secondaryButtonDisabled}
                    >
                        {secondaryButtonLabel}
                    </button>
                )}

                {!!customHtmlLeft && (
                    <div>
                        {customHtmlLeft}
                    </div>
                )}

                {props.children}
            </span>
        </footer>
    )
}

export default ModalFooter;
