import React, {useEffect, useMemo, useRef, useState} from 'react';
import {classNames, getLookup} from '../../util/util-helpers';
import HeaderSortButton from './table-components/header-sort-button';
import VirtualRows from './table-virtual-rows';
import ColumnWidthAdjuster from './table-components/column-width-adjuster';
import TableLoader from './table-components/table-loader';
import TableStyles from './table-components/table-styles'
import {getRandomID} from '../../util/util-vanilla';
import ColumnMenu from './table-components/column-menu';
import ModalDefault from '../modal/modal-default';
import EditColumnLabelDialog from './table-components/edit-column-label-dialog';
import EditColumnWidthDialog from './table-components/edit-column-width-dialog';
import TableObserver from './table-components/table-observer';
import {ButtonsActions, fieldToCell, getDefaultRowMinHeight, getHeadersData, mergeCRUDActions} from "./table-utils";
import TableVertical from "./vertical-table";
import {useDispatch, useSelector} from "react-redux";
// import TableVertical from '../resource-table/table-vertical';

export default function ResourceTable(
    {
        addTableClass = "isolate",
        fields,
        data = [],
        actions = [],
        onRowClick,
        onView,
        hasViewPerm = true,
        hasEditPerm  = true,
        hasDeletePerm  = true,
        hasRestorePerm = true,
        onEdit,
        onDelete,
        onRestore,
        queryFields,
        sort,
        sortBy,
        onSortChange,
        onSecondSortChange,
        secondSortBy,
        secondSort,
        isLoading,
        options,
        tableKey,
        translate,
        onSelectRow,
        selectedRows,
        onSelectAllClick,
        isSelectRowDisabled,
        saveTableOptions,
        updatedRows,
        limit,
        maxHeightClass,
        tfoot,
        verticalTableIsVisible,
        verticalTableContainerClass,
        lastVisitedRowID,
        onClearLastVisitedRowID,
        tableID,
        addRowClass
    }) {

    const dispatch = useDispatch();

    if (queryFields) {
        limit = limit ?? queryFields.limit?.value;
        sort = sort ?? queryFields.sort?.value;
        sortBy = sortBy ?? queryFields.sortBy?.value;
    }

    const tableOptions = useMemo(() => {
        if (!options && fields) {
            options = {
                style: {
                    horizontalLines: true,
                    verticalLines: false,
                    floatingActions: false
                }
            }
        }

        if (!options?.columns && fields) {
            options = Object.assign(options, {
                columns: Object.values(fields).reduce((memo, it) => {
                    memo[it.name] = {
                        show: true,
                        name: it.name,
                        label: translate ? translate("field." + (it?.metadata?.label ?? it.name)) : it.name
                    }

                    if (it?.metadata?.isColumnFrozen) {
                        memo[it.name].frozen = true;
                    }

                    return memo;
                }, {})
            })
        }

        return options;
    }, [options, fields]);
    const randomID = useRef(getRandomID(5))
    const thisTableID = tableID ?? randomID.current;
    const isVirtualizedRowTable = !!options?.behaviour?.hasVirtualRows;

    const isInitialLoadingRef = useRef(true);
    const tableWidthRef = useRef();
    const tableContainerRef = useRef();
    const initialLastColumnWidthRef = useRef(0); // For calculations when dragging last column
    const initialWidthDeltaRef = useRef(0);

    const [defaultSort, setDefaultSort] = useState("");

    const isDefaultSort = defaultSort === sortBy && sort === "DESC";
    const [remountCount, setRemountCount] = useState(1);
    const [tableStyle, setTableStyle] = useState({});
    const [tableBodyHeight, setTableBodyHeight] = useState(0);
    const [tableBodyWidth, setTableBodyWidth] = useState(0);

    const [areAllSelected, setAreAllSelected] = useState(false);
    const [rowMinHeight, setRowMinHeight] = useState(getDefaultRowMinHeight(tableOptions));

    const [areActionsVisible, setAreActionsVisible] = useState(true);
    const [lastVisitedRowIndex, setLastVisitedRowIndex] = useState(-1);
    const mergedActions = mergeCRUDActions(actions, onView, onEdit, onDelete, onRestore, translate, hasViewPerm, hasEditPerm, hasDeletePerm, hasRestorePerm);
    const hasActions = !!mergedActions.length && !isLoading && areActionsVisible;

    const [isOptionsDialogOpen, setIsOptionsDialogOpen] = useState(false);
    const [dialogItem, setDialogItem] = useState({});

    const headersRefs = useRef({});

    const tableRef = useRef();
    const tableHeightRef = useRef();

    const tablesDataResource = useSelector(state => state.tablesDataResource);

    const lastVisitedRowIDRef = useRef(lastVisitedRowID ?? tablesDataResource?.[tableID]?.lastVisited?.[tableKey])

    const tableClass = classNames(
        'text-tm-gray-700 w-full bg-inverse',
        !isVirtualizedRowTable ? "table mb-0" : undefined,
        options?.style?.isTableLayoutFixed ? "table-fixed" : "table-auto"
    );

    const actionsContainerClass = classNames(
        tableOptions?.style?.floatingActions
            ? 'hidden group-hover:flex absolute bg-tm-gray-50 right-0'
            : "w-full",
        'h-full px-2 space-x-2 flex items-center justify-center whitespace-nowrap',
        //!isVirtualizedRowTable ? "top-0 bottom-0" : undefined
        tableOptions?.style?.horizontalLines && tableOptions?.style?.floatingActions ? 'border-b border-tm-gray-200' : ''
    );

    const actionButtonClass = "p-2 rounded-btn text-tm-gray-600 hover:bg-tm-gray-200 hover:text-tm-gray-700 disabled:hover:bg-transparent focus:outline-none focus:ring-2 ring-primary-tint"
    const [headerData, setHeaderData] = useState(getHeadersData(fields, tableOptions, headersRefs, onSelectRow, onSortChange, mergedActions, thisTableID, translate));

    function getFooterRowClass(rowClass) {
        return classNames(
            //isVirtualizedRowTable ? "absolute flex" : "table-row relative",
            "bg-tm-gray-50 sticky group vt-footer-row",
            rowClass
        )
    }

    function getVTRowClass(row) { // It's not exclusive for VT rows. Rename
        return classNames(
            isVirtualizedRowTable ? `vt-${thisTableID}-row` : `t-${thisTableID}-row`,
            "group",
            "hover:bg-tm-gray-50",
            !row?.ArchivedDate && tableOptions?.style?.stripedRows ? "odd:bg-tm-gray-100 even:bg-inverse" : undefined,
            !row?.ArchivedDate && !tableOptions?.style?.stripedRows ? 'bg-inverse' : undefined,
            row?.ArchivedDate ? "bg-stripes bg-tm-gray-200 hover:bg-tm-gray-100" : "hover:bg-tm-gray-50",
            addRowClass ? addRowClass(row) : undefined
        )
    }

    function handleSetColumnWidth(newWidth, widthDelta, key) {
        tableRef.current.style.setProperty(`--vtc-${key}`, newWidth);

        //const tableWidthDelta = tableWidthRef.current - tableHeightRef?.current?.offsetWidth; // probably unnecessery
        const hasScroll = tableContainerRef?.current?.scrollWidth > tableContainerRef?.current?.clientWidth;
        const lastTableKey = headerData?.[headerData.length - 2]?.key;

        if (!lastTableKey || key === lastTableKey) {
            return null;
        }

        if (!hasScroll) {
            if (initialLastColumnWidthRef.current === 0) {
                initialLastColumnWidthRef.current = getComputedStyle(tableRef.current).getPropertyValue(`--vtc-${lastTableKey}`);
                initialWidthDeltaRef.current = widthDelta; // if dragging has started when scroll is visible
            }

            tableRef.current.style.setProperty(
                `--vtc-${lastTableKey}`,
                initialLastColumnWidthRef.current - widthDelta + initialWidthDeltaRef.current
            );
        } else if (initialLastColumnWidthRef.current !== 0 && initialLastColumnWidthRef.current < getComputedStyle(tableRef.current).getPropertyValue(`--vtc-${lastTableKey}`)) {
            tableRef.current.style.setProperty(
                `--vtc-${lastTableKey}`,
                initialLastColumnWidthRef.current - widthDelta + initialWidthDeltaRef.current
            );
        }
    }

    function handleSaveColumnWidth() {
        let currentTableWidth = 0;
        const lastTableKey = headerData?.[headerData.length - 2]?.key;

        Object.values(headerData).forEach(it => {
            if (tableOptions?.columns?.[it.key]) {
                currentTableWidth = currentTableWidth + Number(getComputedStyle(tableRef.current).getPropertyValue(`--vtc-${it.key}`));
                tableOptions.columns[it.key].minWidth = getComputedStyle(tableRef.current).getPropertyValue(`--vtc-${it.key}`);
            }
        })

        currentTableWidth = Number(currentTableWidth) + 2; // +2 because of the TableCard let and right border, maybe change border to shadow? Same in TableStyles component

        if (currentTableWidth < tableHeightRef?.current?.offsetWidth) {
            tableOptions.columns[lastTableKey].minWidth = Number(tableOptions.columns[lastTableKey].minWidth) + Number(tableRef?.current?.offsetWidth - currentTableWidth);
        }

        if (saveTableOptions) {
            saveTableOptions(Object.assign({}, tableOptions));
        }
        initialLastColumnWidthRef.current = 0;
        initialWidthDeltaRef.current = 0;
    }

    function handleOpenDialog(dialogType, key) {
        if (dialogType === 'reLabelColumn') {
            setIsOptionsDialogOpen(true);
            setDialogItem({type: 'reLabelColumn', key: key})
        }

        if (dialogType === 'updateColumnWidth') {
            setIsOptionsDialogOpen(true);
            setDialogItem({type: 'updateColumnWidth', key: key})
        }
    }

    const displayOnlyData = useMemo(() => {
        return processDataForDisplay();
    }, [data, updatedRows, headerData, tableOptions]);


    function headerRender(th) {
        let headerContent = th.content;

        if (th.key === 'select-row-column') {
            headerContent = <input
                type="checkbox"
                className={'m-1 relative z-10 rounded text-primary bg-field focus:ring-primary focus:ring-offset-inverse checked:bg-primary'}
                checked={areAllSelected}
                onChange={(event) => onSelectAllClick(areAllSelected, event)}
            />
        }

        return <div
            key={th.key}
            className={
                classNames(
                    th.class,
                )}
            ref={th.ref}
        >
            {th.isSortable && !th.hasMenu && (
                <HeaderSortButton
                    sort={sort}
                    sortBy={sortBy}
                    header={th}
                    onSortChange={onSortChange}
                    content={headerContent}
                />
            )}

            {th.canAdjustWidth && (
                <ColumnWidthAdjuster
                    headerKey={th.key}
                    headersRefs={headersRefs}
                    content={headerContent}
                    onSetColumnWidth={(newWidth, widthDelta) => handleSetColumnWidth(newWidth, widthDelta, th.key)}
                    onSaveColumnWidth={() => handleSaveColumnWidth(th.key)}
                />
            )}

            {th.hasMenu && (
                <ColumnMenu
                    columnKey={th.key}
                    isDefaultSort={isDefaultSort}
                    isSortable={th.isSortable}
                    defaultSort={defaultSort}
                    sort={sort}
                    sortBy={sortBy}
                    onSortChange={onSortChange}
                    secondSortBy={secondSortBy}
                    secondSort={secondSort}
                    onSecondSortChange={onSecondSortChange}
                    content={headerContent}
                    options={tableOptions}
                    saveTableOptions={saveTableOptions}
                    onOpenDialog={handleOpenDialog}
                    translate={translate}
                />
            )}

            {!th.hasMenu && !th.isFloating && (
                <p className="truncate font-bold">{headerContent}</p>
            )}
        </div>
    }

    function columnRender(rowData, header) {
        return (
            <div
                key={header.key + rowData.dataIndex + rowData?.row_update_count}
                className={header.cellClass}
            >

                {isLoading && header.key !== 'actions' && header.key !== 'select-row-column' && (
                    <TableLoader/>
                )}

                {!isLoading && !header.subColumns && header.key !== 'actions' && header.key !== 'select-row-column' && (
                    //fieldToCell(header.key, data[rowData.dataIndex], rowData)
                    fieldToCell(fields, header.key, rowData, rowData)
                )}

                {!isLoading && !!header.subColumns && (
                    <div className='py-1 space-y-0.5 w-full'>
                        {
                            //fieldToCell(header.key, data[rowData.dataIndex], rowData)
                        }
                        {fieldToCell(fields, header.key, rowData, rowData)}

                        {header.subColumns.map((subCol, i) => {
                            return <div key={i}>
                                {fieldToCell(fields, subCol.name, data[rowData.dataIndex], rowData)}
                            </div>
                        })}
                    </div>
                )}

                {hasActions && header.key === 'actions' && (
                    <div className={actionsContainerClass}>
                        <ButtonsActions
                            actions={mergedActions}
                            buttonClass={actionButtonClass}
                            rowData={rowData}
                            rowKey={header.key}
                            dispatch={dispatch}
                        />
                    </div>
                )}

                {header.key === 'select-row-column' && (
                    <React.Fragment>
                        <div
                            className="z-1 cursor-default inset-0 absolute flex items-center justify-center"
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                        >
                            <input
                                type="checkbox"
                                className={"relative z-10 rounded text-primary bg-field checked:bg-primary focus:ring-primary focus:ring-offset-inverse cursor-pointer disabled:opacity-50 disabled:bg-tm-gray-200 disabled:cursor-default"}
                                disabled={(!!isSelectRowDisabled && isSelectRowDisabled(rowData)) || isLoading}
                                checked={!!selectedRows?.[rowData[tableKey]]}
                                onClick={e => e.stopPropagation()}
                                onChange={(e) => handleSelectRowClick(e, rowData[tableKey])}
                            />
                        </div>
                    </React.Fragment>
                )}
            </div>
        )
    }

    function footerCellRender(rowData, header) {
        let result = "";
        if (!rowData?.[header.key]) {
            result = <div></div>;
        } else if (typeof rowData?.[header.key] === 'function') {
            result = rowData?.[header.key]();
        } else {
            result = fieldToCell(fields, header.key, rowData, rowData, true)
        }
        return <div
            key={header.key}
            className={
                classNames(
                    isVirtualizedRowTable
                        ? "vtc-" + thisTableID + "-default vtc-" + header.keyPlusID
                        : "tc-" + thisTableID + "-default tc-" + header.keyPlusID
                    ,
                    "bg-tm-gray-50 font-bold"
                )}
        >
            {result}
        </div>
    }


    function handleSelectRowClick(e, id) {
        const rowData = data.find(it => it[tableKey] === id);
        if (data) {
            onSelectRow(rowData, rowData[tableKey], e);
        }
    }

    function processDataForDisplay() {
        // This is soiled data used only for display, optimizations and other features
        // Always pass original data for actions
        //

        if (data?.length === undefined) {
            return [];
        }

        return data.reduce((memo, dataRow, i) => {
            // if (Object.keys(updatedRows ?? {}).length) { // for dynamic rows refreshing
            //     dataRow = updatedRows[dataRow?.[tableKey]] ?? dataRow;
            // }

            dataRow = Object.keys(dataRow).reduce((memoCol, key) => {

                if (fields[key] && fields[key].type === 'select') {
                    if (fields[key]?.props?.values) {
                        memoCol[key + "_select"] = fields[key].props.values[dataRow[key]]
                    } else if (dataRow[key.replace("ID", "")]) {
                        memoCol[key + "_select"] = dataRow[key.replace("ID", "")];
                    } else {
                        memoCol[key + "_select"] = getLookup(key.replace("ID", ""))?.[dataRow[key]]
                    }
                    memoCol[key] = dataRow[key];
                } else {
                    memoCol[key] = dataRow[key]; // Check for performance hit?!!
                }

                if (tableOptions?.columns?.[key]?.merged?.[0]) {
                    memoCol[key]  = memoCol[key] + " " + dataRow[tableOptions?.columns?.[key]?.merged?.[0]?.name];
                }

                return memoCol;
            }, {});

            dataRow.dataIndex = i;
            memo.push(dataRow)

            return memo;
        }, []);
    }

    function handleRowClick(rowIndex, rowKey) {
        if (onRowClick && !isLoading) {
            onRowClick(displayOnlyData[rowIndex], rowKey);
        }
    }

    function handleSelectAllClickVerticalTable(areAllSelected) {
        onSelectAllClick(!areAllSelected);
    }

    useEffect(() => {
        if (!!selectedRows && !isLoading) {
            let disabledCount = 0;
            const dataLength = data.length;
            const areAllSelectableSelected = !!dataLength && !data.find(it => {
                if (isSelectRowDisabled) {
                    // Don't count disabled
                    const isRowDisabled = !!isSelectRowDisabled(it);

                    if (isRowDisabled) {
                        disabledCount = disabledCount + 1;
                    }

                    return !(isRowDisabled || !!selectedRows[it[tableKey]])
                } else {
                    return !selectedRows[it[tableKey]];
                }
            });

            // If all are disabled don't check allSelected
            setAreAllSelected(areAllSelectableSelected && !(disabledCount === dataLength));
        }

        if (data?.[0] !== undefined) {
            isInitialLoadingRef.current = false;
        }
    }, [data, selectedRows]);

    useEffect(() => {
        let hd = getHeadersData(fields, tableOptions, headersRefs, onSelectRow, onSortChange, mergedActions, thisTableID, translate);
        let defaultSort = hd?.[0]?.key ?? "";
        if (defaultSort === 'select-row-column') {
            defaultSort = hd?.[1]?.key ?? "";
        }

        setHeaderData(hd);
        setDefaultSort(defaultSort);
    }, [isLoading, tableOptions])

    useEffect(() => {
        lastVisitedRowIDRef.current = lastVisitedRowID ?? tablesDataResource?.[tableID]?.lastVisited?.[tableKey] ?? -1;

        if (data.length && lastVisitedRowIDRef.current !== -1) {
            setLastVisitedRowIndex(data.findIndex(item => Number(item?.[tableKey]) === Number(lastVisitedRowIDRef.current)));
        } else {
            setLastVisitedRowIndex(-1);
        }
    }, [data, lastVisitedRowID, tablesDataResource]);

    return (
        <React.Fragment>
            {!verticalTableIsVisible && (
                <React.Fragment>
                    {tableHeightRef.current && isVirtualizedRowTable && (
                        <TableObserver
                            tableContainerRef={tableHeightRef}
                            onTableWidthChange={() => setRemountCount(count => count + 1)}
                            onTableHeightChange={() => setRemountCount(count => count + 1)}
                        />
                    )}

                    <TableStyles
                        tableID={thisTableID}
                        tableRef={tableRef}
                        tableContainerRef={tableContainerRef}
                        sizeChangeCount={remountCount}
                        data={data}
                        headerData={headerData}
                        isVirtualizedRowTable={isVirtualizedRowTable}
                        rowMinHeight={rowMinHeight}
                        setRowMinHeight={setRowMinHeight}
                        getDefaultRowMinHeight={getDefaultRowMinHeight}
                        setTableStyle={setTableStyle}
                        setTableBodyHeight={setTableBodyHeight}
                        setTableBodyWidth={setTableBodyWidth}
                        options={tableOptions}
                        isLoading={isLoading}
                        isRowClickable={!!onRowClick}
                        limit={limit}
                        tfoot={tfoot}
                        isInitialLoadingRef={isInitialLoadingRef}
                        hasActions={hasActions}
                        tableWidthRef={tableWidthRef}
                    />

                    <VirtualRows
                        tableBodyHeight={tableBodyHeight}
                        addTableClass={addTableClass}
                        tableContainerRef={tableContainerRef}
                        sizeChangeCount={remountCount}
                        tableRef={tableRef}
                        tableID={tableID}
                        tableKey={tableKey}
                        isInitialLoadingRef={isInitialLoadingRef}
                        tableHeightRef={tableHeightRef}
                        isGPUAccelerated={tableOptions?.style?.isGPUAccelerated}
                        maxHeightClass={maxHeightClass}
                        options={tableOptions}
                        tableClass={tableClass}
                        tableStyle={tableStyle}
                        tableBodyStyle={{height: tableBodyHeight + "px", width: tableBodyWidth + "px"}}
                        headerClass={isVirtualizedRowTable ? "sticky top-0 grid z-20" : "sticky top-0 z-20 table-header-group"}
                        bodyClass={isVirtualizedRowTable ? "vt-body flex relative bg-inverse" : "t-body table-row-group relative bg-inverse"}
                        headerRowClass={isVirtualizedRowTable ? "flex" : "table-row"}
                        rowHeight={rowMinHeight}
                        rowStyle={(rowIndex) => ({
                            //transform: "translateY(" + ((rowIndex ?? 0) * rowMinHeight) + "px)",
                            top: ((rowIndex ?? 0) * rowMinHeight) + "px"
                        })}
                        rowClass={(row) => getVTRowClass(row)}
                        data={displayOnlyData}
                        headerData={headerData}
                        headerRender={headerRender}
                        isLoading={isLoading}
                        columnRender={columnRender}
                        onRowClick={handleRowClick}
                        setAreActionsVisible={setAreActionsVisible}
                        tfoot={tfoot}
                        limit={limit}
                        getFooterRowClass={getFooterRowClass}
                        footerCellRender={footerCellRender}
                        footerRowStyle={(containerHeight) => ({top: (containerHeight - rowMinHeight) + "px"})}
                        lastVisitedRowIndex={lastVisitedRowIndex}
                        onClearLastVisitedRowID={onClearLastVisitedRowID}
                        updatedRows={updatedRows}
                    />
                </React.Fragment>
            )}

            {!!verticalTableIsVisible && (
                <TableVertical
                    verticalTableContainerClass={verticalTableContainerClass}
                    fields={fields}
                    resourceData={displayOnlyData}
                    options={tableOptions}

                    onView={onView}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    onRestore={onRestore}

                    actions={mergeCRUDActions(actions, onView, onEdit, onDelete, onRestore, translate, hasViewPerm, hasEditPerm, hasDeletePerm, hasRestorePerm)}

                    onSelectRow={onSelectRow}
                    selectedRows={selectedRows}
                    onSelectAllClick={handleSelectAllClickVerticalTable}

                    tableKey={tableKey}

                    isLoading={isLoading}

                    translate={translate}
                />
            )}

            <ModalDefault
                show={isOptionsDialogOpen}
                title={translate("dialog_heading." + dialogItem.type, [translate("field." + dialogItem.key)])}
                widthClass={"max-w-xl w-screen"}
                hideDialogFooter={true}
                onClose={() => setIsOptionsDialogOpen(false)}
                closeButtonLabel={translate("btn.close")}
                translate={translate}
            >

                {dialogItem.type === 'reLabelColumn' && (
                    <EditColumnLabelDialog
                        saveTableOptions={saveTableOptions}
                        selectedItem={dialogItem}
                        options={tableOptions}
                        onClose={() => setIsOptionsDialogOpen(false)}
                        translate={translate}
                    />
                )}

                {dialogItem.type === 'updateColumnWidth' && (
                    <EditColumnWidthDialog
                        saveTableOptions={saveTableOptions}
                        selectedItem={dialogItem}
                        options={tableOptions}
                        onClose={() => setIsOptionsDialogOpen(false)}
                        translate={translate}
                    />
                )}
            </ModalDefault>
        </React.Fragment>
    )
}





