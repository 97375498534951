import React from "react";
import { Link } from "react-router-dom";
import Env from "../../../util/env";
import Resources from "../../../data/services/resources";
import {getJWT, getUser} from "../../util/util-auth";

export default function CompanyLogo({className}) {
    const token = getJWT().access_token;
    const ContactID = getUser("Contact.ContactID");
    const CompanyName = getUser("Contact.CompanyName");

    return (
        <Link
            className={className}
            to={`/home`}
        >
            <img className={"h-12 text-inverse flex items-center"} src={Env.getApiUrl("api/" + Resources.CompanyImage, {
                token: token,
                ContactID: ContactID
            })}
                 alt={CompanyName}
            />
        </Link>
    )
}
