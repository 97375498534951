import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../data/services/fields'
import {
    checkPerm,
    classNames,
    getLookup,
    getPreFillFromQuery,
    getProp,
    updateContractFields,
    updateStateFields
} from '../../../common/util/util-helpers'
import {fieldsToHtml, fillFieldsFromData, includeFields} from "../../../common/util/util-fields"
import {getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import Tippy from '@tippyjs/react'
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";
import {getCountryCodes} from "../../../common/util/countryCodes";
import {
    BANK_ACCOUNT_TYPES,
    COUNTRY_ID_CANADA,
    COUNTRY_ID_USA,
    DEFAULT_HISTORY_TABLE_FILTER_FIELDS,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    FIELD_MASK_PHONE,
    READ_PERM,
    SCAC_CODE_FIELD_LENGTH,
    UPDATE_PERM
} from '../../../common/util/util-consts'
import {scrollErrorIntoViewFields, setSelectionRange} from '../../../common/util/util-vanilla'
import {toFrontDateTimeFromUTC} from '../../../common/util/util-dates'
import {CheckCircleIcon, ExclamationTriangleIcon, InformationCircleIcon} from '@heroicons/react/24/outline'
import {LoaderSmall} from "../../../common/components/loader";
import HistoryModal, {DEFAULT_HISTORY_TABLE_FIELDS} from "../../../common/components/modal/modal-history";
import {getHistoryResource} from "../../../data/actions/historyResource";
import {showModal} from "../../../data/actions/ui";
import {HourglassIcon} from "../../../data/themes/icons";
import CardSubTitle from "../../../common/components/card/card-sub-title";
import GroupsViewDialog from "../../../common/components/modal/groups-view-dialog";
import Card from "../../../common/components/card";
import Modal from "../../../common/components/modal";
import FieldOptions from "../../../common/components/fields/field-options";

export default class InfoTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fields: this.getFields(),
            canSubmit: false,
            footerIsSticky: true,

            // Modals
            historyModalOpen: false,
            isGroupInfoDialogOpen: false,
            groupInfoData: {},
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount = () => {
        this.fetchData()
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.resource.isLoading && (!this.props.resource.isLoading)) {
            this.setState({
                fields: this.getFields(getProp(this.props.resource, 'data', {}))
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.Carrier,
            query: {
                id: this.props.match.params.id
            }
        }))
    }

    onSubmit = () => {
        if (this.state.canSubmit) {
            this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
                if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                    this.setState({canSubmit: false}, () => {
                        this.props.canSwapTabsHandler(false)
                        this.props.dispatch(updateResource({
                            user: LocalStorage.get('user'),
                            params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {
                                id: this.props.match.params.id,
                            }),
                            query: {
                                id: this.props.match.params.id,
                            },
                            errorMessage: true,
                            successMessage: `Carrier ${getProp(this.props, 'resource.data.LegalName', '')} updated`,
                            resource: Resources.Carrier,
                            piggyResource: Resources.Carrier,
                            infoPiggyResource: Resources.Carrier,
                        }))
                    })
                } else {
                    scrollErrorIntoViewFields([this.state.fields])
                }
            })
        }
    }

    /** Helpers
     ================================================================= */
    getID = () => {
        return this.props.match.params.id
    }

    getCarrierContactGroup = () => {
        const info = getProp(this.props.resource, 'data', {})

        return {
            label: getProp(info, 'ContactGroup', ''),
            value: getProp(info, 'ContactGroupID', '')
        }
    }

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        let fields = FieldsManager.updateField(this.state.fields, name, value)
        if (!this.state.canSubmit) {
            this.props.canSwapTabsHandler()
        }
        if (name === 'Preferred' && !!value) fields.Blacklisted.value = 0
        if (name === 'Blacklisted' && !!value) fields.Preferred.value = 0

        fields = updateContractFields(fields, name, value)
        fields = updateStateFields(fields, name, value)

        this.setState({canSubmit: true, fields: fields})
    }

    handleSetLocations = (fields) => {
        let updatedFields = this.state.fields

        for (const [key, value] of Object.entries(fields)) {
            if (key in this.state.fields) {
                updatedFields = FieldsManager.updateField(updatedFields, key, value)
            }
        }

        this.setState({
            fields: updatedFields,
            canSubmit: true
        })
    }

    handleFormCancel = () => {
        if (this.state.canSubmit) {
            this.setState({
                canSubmit: false,
                fields: this.getFields(getProp(this.props.resource, 'data', {}))
            }, () => this.props.canSwapTabsHandler(true))
        }
    }

    handleGroupInfoClick = (groupData) => {
        this.setState({
            isGroupInfoDialogOpen: true,
            groupInfoData: groupData
        })
    }

    /** Fields/Data Definitions
     ================================================================= */
    getFields = (item = {}) => {
        const LegalName = getPreFillFromQuery();

        let fieldTemplates = {
            // Card 1
            CountryID: new Field('CountryID', 1, [], false, 'select', {addContainerClass: "col-span-full"}),
            GooglePlaces: new Field('GooglePlaces', '', [], false, 'google-locations', {
                setLocations: this.handleSetLocations,
                addContainerClass: "col-span-full"
            }),
            LegalName: new Field('LegalName', LegalName ?? '', ['empty'], false, "text", {addContainerClass: "col-span-6"}),
            DBAName: new Field('DBAName', '', [''], false, "text", {addContainerClass: "col-span-6"}),
            AddressName: new Field('AddressName', '', [], false, "text", {addContainerClass: "col-span-full"}),
            AddressName2: new Field('AddressName2', '', [], false, "text", {addContainerClass: "col-span-full"}),
            CityName: new Field('CityName', '', [], false, "text", {addContainerClass: "col-span-4"}),
            StateID: new Field('StateID', '', [], false, (item?.CountryID == COUNTRY_ID_USA || item?.CountryID == COUNTRY_ID_CANADA) ? 'select' : 'hidden', {addContainerClass: "col-span-4"}),
            PostalCode: new Field('PostalCode', '', [], false, "text", {addContainerClass: "col-span-4"}),

            ContactGroupID: new Field('ContactGroupID',
                this.getID() ? this.getCarrierContactGroup() : null,
                ['empty'], false, 'select-search',
                {
                    addContainerClass: "col-span-full xl:col-span-6",
                    label: "CompaniesGroupID",
                    fieldOptions: (it) => {
                        return (
                            <FieldOptions
                                options={[
                                    {
                                        icon: InformationCircleIcon,
                                        onClick: () => this.handleGroupInfoClick(it?.value?.metadata),
                                        isVisible: !!it.value && checkPerm(Resources.ContactGroups, READ_PERM)
                                    }
                                ]}
                            />
                        )
                    }
                }, {
                    isClearable: true,
                    api: 'api/' + Resources.ContactGroupsQuick,
                    query: {},
                    searchMap: (it) => ({
                        label: it.ContactGroupName,
                        value: it.ContactGroupID,
                        metadata: it
                    })
                }
            ),

            OfficeID: new Field('OfficeID', '', ['empty'], false, 'select-search',
                {
                    addContainerClass: "col-span-full xl:col-span-6",
                }, {
                    api: 'api/' + Resources.OfficesQuick,
                    query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                    searchMap: (item) => ({
                        label: item.OfficeName,
                        value: item.OfficeID
                    })
                }
            ),

            UserDefinedField1: new Field('UserDefinedField1', '', [], false, "test", {addContainerClass: "col-span-full"}),
            UserDefinedField2: new Field('UserDefinedField2', '', [], false, "test", {addContainerClass: "col-span-full"}),

            // Card 2
            AreaCode: new Field('AreaCode', '', [], false, 'select', {
                addContainerClass: "col-span-2"
            }),
            PhoneNumber: new Field('PhoneNumber', '', [''], false, 'mask', {}, {
                showMask: true,
                mask: FIELD_MASK_PHONE
            }),
            PhoneExtension: new Field('PhoneExtension', '', []),
            PrimaryEmail: new Field('PrimaryEmail', '', ['']),
            SCAC: new Field('SCAC', '', [''], false, 'text', {}, {
                max: SCAC_CODE_FIELD_LENGTH,
                upperCaseOnly: true
            }),
            OrganizationEntityTypeID: new Field('OrganizationEntityTypeID', '', [''], false, 'select', {
                addContainerClass: "col-span-2"
            }),
            MCNumber: new Field('MCNumber', '', [''], false, 'text', {}, {
                max: 10,
            }),
            USDOTNumber: new Field('USDOTNumber', '', [''], false, 'text', {}, {
                max: 10,
            }),
            FederalID: new Field('FederalID', '', [''], false, 'mask', {}, {
                showMask: true,
                mask: [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
                onFocus: (event) => {
                    setSelectionRange('FederalID', event.target.value)
                }
            }),
            NaicsID: new Field('NaicsID', '', [''], false, 'select'),
            SendPromotionalEmails: new Field('SendPromotionalEmails', '', [], false, 'checkbox'),

            // Card 3
            Notes: new Field('Notes', '', [], false, 'textarea'),

            // Card 5
            Blacklisted: new Field("Blacklisted", '', [], false, 'checkbox'),
            Preferred: new Field("Preferred", '', [], false, 'checkbox'),

            // Card 6
            NumOfTrucks: new Field("NumOfTrucks", '', ['integer_or_empty'], false, 'integer', {
                addContainerClass: 'col-span-full'
            }),
            NumOfTrailers: new Field("NumOfTrailers", '', ['integer_or_empty'], false, 'integer', {
                addContainerClass: 'col-span-full'
            }),

            // Card 7
            LiabilityInsuranceNo: new Field("LiabilityInsuranceNo", '', [], false, 'text', {
                hideLabel: true
            }),
            LiabilityInsuranceExpiryDate: new Field("LiabilityInsuranceExpiryDate", '', [], false, 'date', {
                hideLabel: true
            }),
            LiabilityInsuranceAmount: new Field("LiabilityInsuranceAmount", '', ['float_or_empty'], false, 'float', {
                hideLabel: true
            }),
            CargoInsuranceNo: new Field("CargoInsuranceNo", '', [], false, 'text', {
                hideLabel: true
            }),
            CargoInsuranceExpiryDate: new Field("CargoInsuranceExpiryDate", '', [], false, 'date', {
                hideLabel: true
            }),
            CargoInsuranceAmount: new Field("CargoInsuranceAmount", '', ['float_or_empty'], false, 'float', {
                hideLabel: true
            }),
            TrailerInteropInsuranceNo: new Field("TrailerInteropInsuranceNo", '', [], false, 'text', {
                hideLabel: true
            }),
            TrailerInteropInsuranceExpiryDate: new Field("TrailerInteropInsuranceExpiryDate", '', [], false, 'date', {
                hideLabel: true
            }),
            TrailerInteropInsuranceAmount: new Field("TrailerInteropInsuranceAmount", '', ['float_or_empty'], false, 'float', {
                hideLabel: true
            }),
            WorkCompInsuranceNo: new Field("WorkCompInsuranceNo", '', [], false, 'text', {
                hideLabel: true
            }),
            WorkCompInsuranceExpiryDate: new Field("WorkCompInsuranceExpiryDate", '', [], false, 'date', {
                hideLabel: true
            }),
            WorkCompInsuranceAmount: new Field("WorkCompInsuranceAmount", '', ['float_or_empty'], false, 'float', {
                hideLabel: true
            }),

            // Card 8
            IsCommonAuth: new Field('IsCommonAuth', '', [], false, 'checkbox'),
            IsContractAuth: new Field('IsContractAuth', '', [], false, 'checkbox'),
            IsBrokerAuth: new Field('IsBrokerAuth', '', [], false, 'checkbox'),
        }

        return item ? fillFieldsFromData(fieldTemplates, item) : fieldTemplates
    }

    getFieldTypes = () => {
        return Object.values(this.getFields()).reduce((memo, it) => {
            memo[it.name] = it.type
            return memo
        }, {})
    }

    getFieldsToHtmlForIncludedFields = (arr = []) => {
        const selects = {
            AreaCode: getCountryCodes(),
            BankAccountTypeID: BANK_ACCOUNT_TYPES,
            NaicsID: getLookup('Naics')
        }

        return fieldsToHtml((Object.values(Object.assign({}, includeFields(this.state.fields, arr)))), this.props.translate, this.handleInputChange, selects)
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, historyResource} = this.props
        const data = getProp(this.props, 'resource.data', {})

        const fieldsHtmlBasicInfo = this.getFieldsToHtmlForIncludedFields(['CountryID', 'GooglePlaces', 'LegalName', 'DBAName', 'AddressName', 'AddressName2', 'CityName', 'StateID', 'PostalCode', 'ContactGroupID', 'OfficeID', 'UserDefinedField1', 'UserDefinedField2'])
        const fieldsHtmlOrganizationInfo = this.getFieldsToHtmlForIncludedFields(['AreaCode', 'PhoneNumber', 'PhoneExtension', 'PrimaryEmail', 'SCAC', 'OrganizationEntityTypeID', 'MCNumber', 'USDOTNumber', 'FederalID', 'NaicsID', 'SendPromotionalEmails',])
        const fieldsHtmlNotes = this.getFieldsToHtmlForIncludedFields(['Notes'])
        const fieldsHtmlBlacklisted = this.getFieldsToHtmlForIncludedFields(['Blacklisted', 'Preferred'])
        const fieldsHtmlFleetDescription = this.getFieldsToHtmlForIncludedFields(['NumOfTrucks', 'NumOfTrailers'])
        const fieldsHtmlAuthority = this.getFieldsToHtmlForIncludedFields(['IsCommonAuth', 'IsContractAuth', 'IsBrokerAuth'])

        const fieldsHtmlInsurancesRowOne = this.getFieldsToHtmlForIncludedFields(['LiabilityInsuranceNo', 'LiabilityInsuranceExpiryDate', 'LiabilityInsuranceAmount',])
        const fieldsHtmlInsurancesRowTwo = this.getFieldsToHtmlForIncludedFields(['CargoInsuranceNo', 'CargoInsuranceExpiryDate', 'CargoInsuranceAmount',])
        const fieldsHtmlInsurancesRowThree = this.getFieldsToHtmlForIncludedFields(['TrailerInteropInsuranceNo', 'TrailerInteropInsuranceExpiryDate', 'TrailerInteropInsuranceAmount',])
        const fieldsHtmlInsurancesRowFour = this.getFieldsToHtmlForIncludedFields(['WorkCompInsuranceNo', 'WorkCompInsuranceExpiryDate', 'WorkCompInsuranceAmount',])

        return (
            <React.Fragment>
                {this.props.resource.isLoading && (
                    <Card>
                        <div className="text-center">
                            <LoaderSmall/>
                        </div>
                    </Card>
                )}

                {!this.props.resource.isLoading && (
                    <div className="pb-20">
                        <div className="flex items-center justify-end mb-2">
                            {!!data.Preferred && (
                                <Tippy content={
                                    <span>{translate('field.Preferred')} {data.Preferred ? `(By contact: ${data.PreferredUpdatedByContact}) - ${toFrontDateTimeFromUTC(data.PreferredUpdateDate)}` : ''}</span>}>
                                    <div className="relative mr-3">
                                        <button
                                            type="button"
                                            className={classNames('w-full relative z-10 p-2 rounded-full hover:text-green-600 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 ', 'text-green-500 bg-green-200')}
                                        >
                                            <CheckCircleIcon className="h-4 w-4"/>
                                        </button>

                                        <span
                                            className="top-1 left-1 animate-ping absolute inline-flex h-6 w-6 rounded-full bg-green-400 opacity-75"/>
                                    </div>
                                </Tippy>
                            )}
                            {!!data.Blacklisted && (
                                <Tippy content={
                                    <span>{translate('field.Blacklisted')} {data.Blacklisted ? `(By contact: ${data.BlacklistUpdatedByContact}) - ${toFrontDateTimeFromUTC(data.BlacklistUpdateDate)}` : ''}</span>}>
                                    <div className="relative mr-3">
                                        <button
                                            type="button"
                                            className={classNames('w-full relative z-10 p-2 rounded-full hover:text-red-600 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 ', 'text-red-500 bg-red-200')}
                                        >
                                            <ExclamationTriangleIcon className="h-4 w-4"/>
                                        </button>

                                        <span
                                            className="top-1 left-1 animate-ping absolute inline-flex h-6 w-6 rounded-full bg-red-400 opacity-75"/>
                                    </div>
                                </Tippy>
                            )}
                            {
                                checkPerm(Resources.CarrierHistory, READ_PERM) &&
                                <Tippy content={<span>{translate('text.view_history')}</span>}>
                                    <button
                                        type="button"
                                        onClick={() => this.setState({historyModalOpen: true})}
                                        className="btn btn-header"
                                    >
                                        {<HourglassIcon className="w-5 h-5"/>}
                                    </button>
                                </Tippy>
                            }
                        </div>

                        <div className="grid lg:grid-cols-2 3xl:grid-cols-3 gap-4">
                            <div className="">
                                <Card addClass={'h-full pb-4'}>
                                    <CardSubTitle
                                        text={translate('text.BasicInfo')}
                                    />
                                    <div className="grid grid-cols-12 gap-5">
                                        {fieldsHtmlBasicInfo}
                                    </div>
                                </Card>
                            </div>
                            <div className="">
                                <Card addClass={'h-full pb-4'}>
                                    <CardSubTitle
                                        text={translate('text.OrganizationInfo')}
                                    />
                                    <div className="grid grid-cols-2 gap-5">
                                        {fieldsHtmlOrganizationInfo}
                                    </div>
                                </Card>
                            </div>
                            <div className="">
                                <Card addClass={'h-full pb-4'}>
                                    <CardSubTitle
                                        text={translate('text.Notes')}
                                    />
                                    <div className="">
                                        {fieldsHtmlNotes}
                                    </div>
                                </Card>
                            </div>
                            <div className="">
                                <Card addClass={'h-full pb-4'}>
                                    <CardSubTitle
                                        text={translate('text.Appearance')}
                                    />
                                    <div className="grid grid-cols-2 gap-5">
                                        {fieldsHtmlBlacklisted}
                                    </div>
                                </Card>
                            </div>
                            <div className="">
                                <Card addClass={'h-full pb-4'}>
                                    <CardSubTitle
                                        text={translate('text.FleetDescription')}
                                    />
                                    <div className="grid grid-cols-2 gap-5">
                                        {fieldsHtmlFleetDescription}
                                    </div>
                                </Card>
                            </div>
                            <div className="">
                                <Card addClass={'h-full pb-4'}>
                                    <CardSubTitle
                                        text={translate('text.Insurances')}
                                    />
                                    <div className="grid grid-cols-4 gap-5">
                                        <div/>
                                        <div
                                            className="flex items-center text-sm font-semibold text-tm-gray-900 h-5 whitespace-nowrap">{translate("text.PolicyNo")}</div>
                                        <div
                                            className="flex items-center text-sm font-semibold text-tm-gray-900 h-5 whitespace-nowrap">{translate("text.Expires")}</div>
                                        <div
                                            className="flex items-center text-sm font-semibold text-tm-gray-900 h-5 whitespace-nowrap">{translate("text.Limits")}</div>
                                    </div>

                                    <div className="grid grid-cols-4 gap-5 flex items-center">
                                        <div
                                            className="flex items-center text-sm font-semibold text-tm-gray-900 h-5 whitespace-nowrap">
                                            {translate("text.LiabilityIns")}
                                        </div>
                                        <div className="grid grid-cols-3 gap-5 col-span-3">
                                            {fieldsHtmlInsurancesRowOne}
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-4 gap-5 flex items-center">
                                        <div
                                            className="flex items-center text-sm font-semibold text-tm-gray-900 h-5 whitespace-nowrap">
                                            {translate("text.CargoIns")}
                                        </div>
                                        <div className="grid grid-cols-3 gap-5 col-span-3">
                                            {fieldsHtmlInsurancesRowTwo}
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-4 gap-5 flex items-center">
                                        <div
                                            className="flex items-center text-sm font-semibold text-tm-gray-900 h-5 whitespace-nowrap">
                                            {translate("text.TrailerInter")}
                                        </div>
                                        <div className="grid grid-cols-3 gap-5 col-span-3">
                                            {fieldsHtmlInsurancesRowThree}
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-4 gap-5 flex items-center">
                                        <div
                                            className="flex items-center text-sm font-semibold text-tm-gray-900 h-5 whitespace-nowrap">
                                            {translate("text.WorkComp")}
                                        </div>
                                        <div className="grid grid-cols-3 gap-5 col-span-3">
                                            {fieldsHtmlInsurancesRowFour}
                                        </div>
                                    </div>
                                </Card>
                            </div>
                            <div className="">
                                <Card addClass={'h-full pb-4'}>
                                    <CardSubTitle
                                        text={translate('text.Authority')}
                                    />
                                    <div className="grid grid-cols-2 gap-5">
                                        {fieldsHtmlAuthority}
                                    </div>
                                </Card>
                            </div>
                        </div>

                        <PageFooter
                            translate={translate}
                            canSubmit={this.state.canSubmit}
                            actionCancel={this.handleFormCancel}
                            actionSubmit={checkPerm(Resources.Carrier, UPDATE_PERM) && this.onSubmit}
                        />
                    </div>
                )}

                <Modal
                    show={this.state.historyModalOpen}
                    widthClass={"max-w-5xl"}
                    onClose={() => this.setState({historyModalOpen: false})}
                    translate={translate}
                >
                    {this.state.historyModalOpen && (
                        <HistoryModal
                            onClose={() => this.setState({historyModalOpen: false})}
                            translate={translate}
                            title={translate("text." + Resources.CarrierHistory)}
                            tableFields={DEFAULT_HISTORY_TABLE_FIELDS(translate, this.getFieldTypes())}
                            filterFields={DEFAULT_HISTORY_TABLE_FILTER_FIELDS()}
                            onFetchData={(query) => {
                                this.props.dispatch(getHistoryResource({
                                    user: LocalStorage.get('user'),
                                    query: Object.assign({}, query, {
                                        id: this.props.match.params.OrganizationID
                                    }),
                                    resource: Resources.CarrierHistory
                                }))
                            }}
                            data={getProp(historyResource, "data.list", [])}
                            count={getProp(historyResource, "data.count", 0)}
                            isLoading={getProp(historyResource, "isLoading", false)}
                            showUserAvatar

                            onRowClick={(item) => {
                                this.props.dispatch(showModal('ViewContactCard', {ContactID: item.ContactID}))
                            }}
                            fieldTypes={this.getFieldTypes()}
                        />
                    )}
                </Modal>

                <GroupsViewDialog
                    groupID={this.state.fields?.ContactGroupID?.value?.value}
                    isGroupInfoDialogOpen={this.state.isGroupInfoDialogOpen}
                    groupInfoData={this.state.groupInfoData}
                    onClose={() => this.setState({
                        isGroupInfoDialogOpen: false
                    })}
                    translate={translate}
                />
            </React.Fragment>
        )
    }
}
