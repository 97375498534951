import React from 'react';

export default function TableHeader({headerData, headerClass, headerRowClass, headerRender, translate}) {
    return (
        <header className={headerClass ?? "table-header-group"}>
            <div className={headerRowClass ?? "table-row"}>
                {headerData.map((th, thIndex) => {
                    return (
                        headerRender 
                            ? headerRender(th, thIndex) 
                            : <th
                                key={
                                    th?.key
                                        ?? (typeof th === 'string' ? th : thIndex)
                                }
                                className={th.class}
                                //style={th.style}
                                ref={th.ref}
                            >
                                {translate ? translate(th) : th}
                            </th>
                    )
                })}
            </div>
        </header>
    )
}
