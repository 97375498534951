import React, { useEffect } from "react";
import LoaderSmall from "../../loader/loader-small";
import Resources from "../../../../data/services/resources";
import LocalStorage from "../../../util/localStorage";
import {checkPerm, classNames, getProp, openInNewTab} from "../../../util/util-helpers";
import { Field } from "../../../../data/services/fields";
import DataCard from "../../../../common/components/display-data/data-card";
import { getDialogInfoResource } from "../../../../data/actions/dialogInfoResource";
import ModalFooter from "../modal-footer";
import {UPDATE_PERM} from "../../../util/util-consts";

export default function TruckCard({translate, dialogInfoResource, TruckID, onClose, dispatch}) {
    useEffect(() => {
        dispatch(getDialogInfoResource({
            user: LocalStorage.get("user"),
            resource: Resources.TrucksInfo,
            query: {id: TruckID}
        }));
    }, [TruckID, dispatch])

    const handleUpdateButtonClick = () => {
        openInNewTab("/trucks/info/" + TruckID)
    }

    const data = getProp(dialogInfoResource, "data", {});
    const isLoading = getProp(dialogInfoResource, "isLoading", true);
    const truckFields = Object.values(getFields()).filter(it => data[it.name] !== undefined);

    return (
        <React.Fragment>
            <div className="relative p-3 min-h-[24rem]">
                {isLoading && (
                    <div className="text-center absolute inset-0 flex items-center justify-center">
                        <LoaderSmall/>
                    </div>
                )}

                {!isLoading && (
                    <React.Fragment>
                        <div className={'max-w-md mx-auto my-10'}>
                            <p className="text-2xl font-bold truncate mr-6 mb-2 text-tm-gray-900">
                                {data["TruckNumber"]}
                            </p>

                            <div className="bg-inverse rounded-xl border-tm-gray-300 border shadow-card">
                                {truckFields.map((field, i) => {
                                    return (
                                        <DataCard
                                            key={field.name}
                                            displayField={field}
                                            fieldsData={data}
                                            className={classNames("px-3 py-1.5 flex items-center px-6", i < truckFields.length - 1 ? "border-b border-tm-gray-300" : undefined)}
                                            translate={translate}
                                        />
                                    )
                                })}
                            </div>

                            {!!data?.Notes && (
                                <div className="mt-6">
                                    <p className="font-bold leading-5 mb-2">{translate("field.Notes")}</p>
                                    <div
                                        className="bg-inverse rounded-xl border-tm-gray-300 border shadow-card px-6 py-3 flex items-center">
                                        {data?.Notes}
                                    </div>
                                </div>
                            )}
                        </div>
                    </React.Fragment>
                )}
            </div>

            <ModalFooter
                buttonLabel={checkPerm(Resources.TrucksInfo, UPDATE_PERM) && translate("btn.view")}
                onButtonClick={handleUpdateButtonClick}
                closeButtonLabel={translate("btn.cancel")}
                onClose={onClose}
            />
        </React.Fragment>
    )
}

const getFields = () => {
    return {
        TruckNumber: new Field('TruckNumber', '', [''], false, "text",),
        Model: new Field('Model', '', [''], false, "text",),
        Year: new Field('Year', '', [''], false, "text", {label: "model_year"}),
        Manufacturer: new Field('Manufacturer', '', [''], false, "text",),
        TruckLicenceNumber: new Field('TruckLicenceNumber', '', [''], false, "text"),
        VIN: new Field('VIN', '', [''], false, "text"),
        TruckOwnershipType: new Field('TruckOwnershipType', '', [''], false, "text"),
    }
};