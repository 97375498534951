export function getCustomStyles() {
    return {
        indicatorSeparator: (provided, state) => ({
            ...provided,
            backgroundColor: "var(--color-gray-300)",
        }),
        clearIndicator: (provided, state) => ({
            ...provided,
            height: "34px",
            color: "var(--color-default)",

            "&:hover": {
                cursor: "pointer"
            }
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            height: "34px",
            color: state.isDisabled ? "var(--color-gray-200)" : "var(--color-gray-700)"
        }),
        container: (provided, state) => ({
            ...provided,
            borderRadius: "var(--rounded-input)",
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isFocused ? '#fff' : "var(--color-gray-700)",
            fontWeight: state.isSelected ? 700 : '',
            background: state.isFocused ? "var(--color-primary)" : "transparent",
            cursor: "pointer",
            '&:hover': {
                color: '#fff',
                background: "var(--color-primary)"
            }
        }),
        menu: (provided, state) => ({
            ...provided,
            background: "var(--color-field)",
            color: "var(--color-default)",
            zIndex: 11,
            width: "100%",
            marginTop: "0px"
        }),
        menuPortal: base => ({...base, zIndex: 100}),
        control: (provided, state) => ({
            ...provided,
            transition: "none",
            borderRadius: "var(--rounded-input)",
            backgroundColor: state.isDisabled ? "var(--color-gray-50)" : "var(--color-field)",
            backgroundImage: state.isDisabled ? "var(--stripes-bg-image)" : "",
            minHeight: "34px",
            border: state.isFocused ? 0 : 0,
            // This line disable the blue border
            boxShadow: state.isFocused ? "0 0 0 1px var(--color-primary-tint)" : 0,
            '&:hover': {
                border: state.isFocused ? "var(--color-gray-200)" : 0
            }
        }),
        input: (provided, state) => ({
            color: "var(--color-default)"
        }),
        singleValue: (provided, state) => ({
            color: "var(--color-default)",

            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "calc(100% - 2px)",
        }),
        multiValue: (provided, state) => {
            let backgroundColor = "var(--color-gray-100)";
            let color = "var(--color-gray-900)";
            if (state.data.manual) {
                backgroundColor = "var(--color-primary)";
                color = "var(--color-inverse)";
            }

            if (state.data.invalid) {
                backgroundColor = "rgb(220, 38, 38)";
            }

            return ({
                ...provided,
                color: color,
                background: backgroundColor
            })
        },
        multiValueLabel: (provided, state) => {
            return ({
                ...provided,
                color: "inherit",
                background: "transparent",
                paddingTop: 0,
                paddingBottom: 0
            })
        },
        multiValueRemove: (provided, state) => ({
            ...provided,
            background: "transparent",
            "&:hover": {
                cursor: "pointer"
            }
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            padding: "0.20rem 0.75rem;"
        }),

        // placeholder: (defaultStyles) => {
        //     return {
        //         ...defaultStyles,
        //         // color: '#ffffff',
        //     }
        // }
    }
}

export function getSelectGroupStyles() {
    return {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: 14,
        color: "var(--color-primary-tint)",
        paddingBottom: ".5em",
        marginBottom: ".5em",
        borderBottom: "1px solid var(--color-secondary)"
    };
}


export function getSelectGroupBadgeStyles() {
    return {
        backgroundColor: "var(--color-primary-tint)",
        borderRadius: '2em',
        color: "var(--color-inverse)",
        display: 'inline-block',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center',
    };
}
