import { checkUserHelper } from "../services/api-util";
import { call, put, takeLatest } from "redux-saga/effects";
import Api from "../services/api";
import { pushNotification } from "../actions/ui";
import { doneGetDialogInfoResource, errorGetDialogInfoResource } from "../actions/dialogInfoResource";

export function* getDialogInfoResourceCall(action) {
    let user = yield* checkUserHelper(action.data.user);

    let result = yield call(Api.getResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetDialogInfoResource(result.data));

        if (!!action.data.successMessage) {
            yield put(pushNotification({
                title: action.data.successMessage  === true ?  result.data : action.data.successMessage,
                timeout: action.data.timeout ?? true
            }));
        }
    } else {
        yield put(errorGetDialogInfoResource(result.data));

        if (!!action.data.errorMessage) {
            yield put(pushNotification({
                title: action.data.errorMessage === true ?  result.data : action.data.errorMessage
            }));
        }
    }
}

export function* watchGetDialogInfoResource() {
    yield takeLatest('GET_DIALOG_INFO_RESOURCE', getDialogInfoResourceCall);
}