import {cloneDeep} from "../../../util/util-vanilla";
import {fieldsToHtml} from "../../../util/util-fields";

export default function Fields({fieldsState, excludeFields = [], includeFields = [], translate, onInputChange, selects = {}}) {
    const fields = cloneDeep(fieldsState);

    if (excludeFields.length) {
        excludeFields.forEach(field => {
            delete fields?.[field];
        })
    }

    if (includeFields.length) {
        Object.values(fields).forEach(field => {
            if (!includeFields.includes(field.name)) {
                delete fields?.[field.name];
            }
        })
    }

    return fieldsToHtml(Object.values(Object.assign({}, fields)), translate, onInputChange, selects);
}

