import React, {Component} from "react";
import AsyncCreatableSelect from 'react-select/async-creatable';
import {getCustomStyles, getSelectGroupBadgeStyles, getSelectGroupStyles} from "../../../../data/themes";
import {scrollIntoView} from "../../../util/util-vanilla";
import { classNames } from "../../../util/util-helpers";

export default class FieldCreatableAsync extends Component {

    render() {
        const pairs = this.props.values ? this.props.values : {};

        const formatGroupLabel = data => (
            <div style={getSelectGroupStyles()}>
                <span>{data.label}</span>
                <span style={getSelectGroupBadgeStyles()}>{data.options.length}</span>
            </div>
        );

        return (
            <React.Fragment>
                <AsyncCreatableSelect
                    ref={this.props.innerRef}
                    styles={getCustomStyles()}
                    className={
                        classNames(
                            "select-css-search p-0",
                            !!this.props.addClass ? this.props.addClass : undefined,
                            !!this.props.className ? this.props.className : undefined,
                            this.props.errorMessage ? "is-invalid" : undefined
                        )
                    }
                    isDisabled={!!this.props.disabled}
                    onChange={(selectedOption) => {
                        this.props.onChange(this.props.name, selectedOption);
                    }}
                    name={this.props.name}
                    value={!this.props.loadOptions ? (this.props.value ? {
                        value: this.props.value,
                        label: pairs[this.props.value]
                    } : null) : this.props.value}
                    options={this.props.options}
                    onCreateOption={this.props.onCreateOption ? (value) => this.props.onCreateOption(this.props.name, value) : null}
                    loadOptions={this.props.loadOptions}
                    defaultOptions={!!this.props.defaultOptions}
                    isClearable={!!this.props.isClearable}
                    isMulti={this.props.isMulti}
                    placeholder={!!this.props.placeholder ? this.props.placeholder : 'Select...'}
                    formatGroupLabel={formatGroupLabel}
                    formatCreateLabel={!!this.props.formatCreateLabel ? this.props.formatCreateLabel : userInput => 'Create "' + userInput + '"'}
                    onFocus={(event) => {
                        if (this.props.onFocus) {
                            this.props.onFocus(event);
                        }
                        scrollIntoView(event);
                    }}
                    onBlur={this.props.onBlur}
                    menuPortalTarget={this.props.hasPortal ? document.body : null}
                    defaultMenuIsOpen={this.props.defaultMenuIsOpen}
                    inputValue={this.props.inputValue}
                    onInputChange={(value) => {
                        if (!!this.props.onInputChange) {
                            this.props.onInputChange(this.props.name, value);
                        }
                    }}
                />

                {this.props.errorMessage && (
                    <div className={"text-red-600 font-semibold"} style={{gridColumn: "1/-1"}}>
                        {!!this.props.translate && (
                            this.props.errorMessage.reduce((memo, it) => {
                                if (it.key) {
                                    return this.props.translate(it.key, it.values) + (!!memo ? "," + memo : "")
                                }
                                return this.props.translate(it) + (!!memo ? "," + memo : "")
                            }, "")
                        )}
                        {!this.props.translate && (
                            <span>Field required*</span>
                        )}
                    </div>
                )}
            </React.Fragment>
        )
    }
}
