import React, {useRef, useState} from 'react'
import DropZone from '../../../components/dropzone'
import CameraIcon from '@heroicons/react/24/outline/CameraIcon'
import Env from '../../../../util/env'
import {getJWT} from "../../../util/util-auth";
import moment from "moment";
import {DEFAULT_DATABASE_DATETIME_FORMAT} from "../../../../util/util-constants";
import {classNames} from "../../../util/util-helpers";
import Resources from "../../../../data/services/resources";
import ModalDefault from "../../modal/modal-default";
import CameraCanvas from "../../camera-canvas";
import CropImage from "../../crop-image";

function takePhoto(video) {
    const scaleFactor = 1

    const w = video.videoWidth * scaleFactor
    const h = video.videoHeight * scaleFactor
    const canvas = document.createElement('canvas')
    canvas.width = w
    canvas.height = h
    const ctx = canvas.getContext('2d')
    ctx.drawImage(video, 0, 0, w, h)

    return new Promise((resolve, reject) => {
        canvas.toBlob(blob => {
            if (!blob) {
                return
            }
            blob.name = 'CameraPhoto'
            let fileImageObject = new File([blob], 'CameraPhoto')
            resolve(fileImageObject)
        }, 'image/png')
    })
}

export default function ContactPhoto({translate, imageID, lastUpdated, addClass, onGetImage,stateChangerImagePath,stateChangerIsDirty,hasImage,avatarSet, ImagePath, removeDisabled}) {
    let imageFile = undefined
    const videoRef = useRef(null)

    const [avatar, setAvatar] = useState(undefined)

    const [isPhotoDialogOpen, setIsPhotoDialogOpen] = useState(false)
    const [isPreviewDialogVisible, setIsPreviewDialogVisible] = useState(false)

    const togglePhotoDialog = () => {
        setIsPhotoDialogOpen(!isPhotoDialogOpen)
        imageFile = undefined
        setAcceptedFile(null)
        setIsPhotoView(false)
    }

    const [acceptedFile, setAcceptedFile] = useState(undefined)

    const acceptFile = (files) => {
        const file = files[0]
        setAcceptedFile(file)
    }

    const handleApplyCropClick = () => {
        setAvatar(imageFile)
        onGetImage(imageFile)
        togglePhotoDialog()
        imageFile = undefined
        setAcceptedFile(null)
    }

    const handleApplyImage = () => {
        if (!!acceptedFile) {
            handleApplyCropClick()
        }
    }

    const getCroppedFile = (file) => {
        imageFile = file
    }

    const handleRevertAvatar = () => {
        setAcceptedFile(null)
        setAvatar(null)
        onGetImage(null)
    }

    const handleRemoveAvatar = () => {
        setAcceptedFile(null)
        stateChangerImagePath(false)
        stateChangerIsDirty(true)
        avatarSet(null)
        setAvatar(null)
        ImagePath = null
    }

    const [isPhotoView, setIsPhotoView] = useState(null)

    const togglePhotoViewClick = (photoView = !isPhotoView) => {
        setIsPhotoView(photoView)
        imageFile = undefined
        setAcceptedFile(null)
    }

    async function handleTakePhoto() {
        setAcceptedFile(await takePhoto(videoRef.current))
        setIsPhotoView(false)
    }

    const isAvatarClickable = !!hasImage || !!avatar

    const handleAvatarClick = () => {
        if (isAvatarClickable) {
            setIsPreviewDialogVisible(true)
        }
    }

    return (
        <React.Fragment>
            <div className={classNames('flex items-center space-x-5', addClass)}>
                {!avatar && (
                    <button
                        onClick={handleAvatarClick}
                        className={
                            classNames(isAvatarClickable ? 'hover:ring-4 focus:ring-4 ring-secondary' : 'cursor-default', 'inline-block h-12 w-12 rounded-full overflow-hidden bg-tm-gray-100')
                        }
                    >
                        {!hasImage && !avatar && (
                            <svg className="h-full w-full text-secondary-dark" fill="currentColor"
                                 viewBox="0 0 24 24">
                                <path
                                    d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"/>
                            </svg>
                        )}

                        {!!imageID && !avatar && (
                            <img src={Env.getApiUrl('api/' + Resources.ContactImage, {
                                token: getJWT().access_token,
                                ContactID: imageID,
                                date: moment(lastUpdated, DEFAULT_DATABASE_DATETIME_FORMAT).unix()
                            })} alt="avatar"/>
                        )}
                    </button>
                )}

                {!!avatar && (
                    <div>
                        <button
                            onClick={handleAvatarClick}
                            className={
                                classNames(isAvatarClickable ? 'hover:ring-4 focus:ring-4 ring-secondary' : 'cursor-default', 'inline-block h-12 w-12 rounded-full overflow-hidden bg-tm-gray-100')
                            }
                        >

                            <img className={'h-12 w-12 rounded-full bg-tm-gray-100'} src={URL.createObjectURL(avatar)}
                                 alt="avatar"/>
                        </button>


                    </div>
                )}

                <button
                    type="button"
                    onClick={togglePhotoDialog}
                    className="btn btn-outline-secondary"
                >
                    {translate('text.change')}
                </button>

                {!!avatar && (
                    <button
                        type="button"
                        onClick={handleRevertAvatar}
                        className="btn btn-text text-tm-gray-700 hover:text-primary"
                    >
                        {translate('btn.revert')}
                    </button>)}

                {!avatar && !!hasImage && !removeDisabled && (
                    <button
                        type="button"
                        onClick={handleRemoveAvatar}
                        className="btn btn-text text-tm-gray-700 hover:text-primary"
                    >
                        {translate('btn.remove')}
                    </button>)}


            </div>

            <ModalDefault
                show={isPhotoDialogOpen}
                widthClass={'max-w-3xl'}
                title="Change photo"

                buttonLabel={translate('btn.apply')}
                buttonDisabled={!acceptedFile}
                onButtonClick={handleApplyImage}

                secondaryButtonLabel={
                    <div className="flex items-center">
                        <CameraIcon className={'w-5 h-5 text-tm-gray-700 mr-2'}/>

                        {isPhotoView
                            ? translate('btn.take_photo')
                            : acceptedFile ? translate('btn.retake') : translate('btn.enable_camera')}
                    </div>
                }
                secondaryButtonDisabled={false}
                onSecondaryButtonClick={isPhotoView ? handleTakePhoto : togglePhotoViewClick}

                closeButtonLabel={(!!acceptedFile || !!isPhotoView) ? translate('btn.cancel') : translate('btn.close')}
                onClose={(!!acceptedFile || !!isPhotoView) ? () => togglePhotoViewClick(false) : togglePhotoDialog}
                isLoading={false}
            >
                <div className="h-[calc(100vh-12rem)]">
                    {!acceptedFile && !isPhotoView && (
                        <div className="p-6 relative h-full flex items-center justify-center">
                            <DropZone
                                onAcceptFiles={acceptFile}
                                translate={translate}
                            />
                        </div>
                    )}

                    {!!acceptedFile && (
                        <div className="relative h-full flex items-center justify-center bg-black">

                            <CropImage
                                file={acceptedFile}
                                getCroppedFile={getCroppedFile}
                            />
                        </div>
                    )}

                    {!acceptedFile && isPhotoView && (
                        <CameraCanvas
                            videoRef={videoRef}
                            onVideoClick={handleTakePhoto}
                            errorMessage={translate('error.CAMERA_ERROR')}
                        >
                            <div className="absolute bottom-0 left-0 right-0">
                                <p className="py-6 text-center text-lg text-white">
                                    {translate('text.click_to_take_photo')}
                                </p>
                            </div>
                        </CameraCanvas>
                    )}
                </div>
            </ModalDefault>

            <ModalDefault
                show={isPreviewDialogVisible}
                widthClass={'max-w-3xl'}
                title={translate('text.ProfileImagePreview')}

                closeButtonLabel={translate('btn.close')}
                onClose={() => setIsPreviewDialogVisible(false)}
            >
                <div className={'p-3 flex justify-center h-dialog-body h-[47rem] h-max-auto'}>
                    {!!avatar && (
                        <img src={URL.createObjectURL(avatar)} alt="avatar"/>
                    )}

                    {!!imageID && !avatar && (
                        <img src={Env.getApiUrl('api/' + Resources.ContactImage, {
                            token: getJWT().access_token,
                            ContactID: imageID
                        })} alt="avatar"/>
                    )}
                </div>
            </ModalDefault>
        </React.Fragment>
    )
}
