import React, {Component} from "react";
import {scrollIntoView} from "../../../util/util-vanilla";

export const DEFAULT_FIELD_TEXTAREA_ROWS = 5

export default class FieldTextarea extends Component {
    handlePaste = (e) => {
        if (e.clipboardData.getData('text/html').search("<br>") > -1 && e.clipboardData.getData('text/html').search("<p") === -1) {
            e.preventDefault();

            const stringPartOne = e.target.value.slice(0, e.target.selectionStart);
            const stringPartTwo = e.target.value.slice(e.target.selectionEnd);

            let string  = (stringPartOne + e.clipboardData.getData('text/html') + stringPartTwo)

            this.props.onChange(this.props.name, string
                .replaceAll('<br', '\n<br')
                .replaceAll('<li', ' - <li')
                .replace(/<[^>]*>/g, ''));
        }
    }

    render() {
        const {readOnly, disabled} = this.props;

        return (
            <React.Fragment>
                <textarea
                    ref={this.props.innerRef}
                    rows={(this.props.rows ? this.props.rows : DEFAULT_FIELD_TEXTAREA_ROWS)}
                    className={this.props.addClass + (this.props.errorMessage ? " is-invalid" : "")}
                    onChange={(event) => {
                        this.props.onChange(this.props.name, event.target.value);
                    }}
                    onFocus={(event) => {
                        if (this.props.onFocus) {
                            this.props.onFocus(event);
                        }

                        scrollIntoView(event);
                    }}
                    onPaste={this.handlePaste} // TODO: Enable this from a prop
                    onBlur={this.props.onBlur}
                    name={this.props.name}
                    value={this.props.value}
                    placeholder={this.props.placeholder}
                    disabled={disabled}
                    id={this.props.id}
                    onKeyPress={this.props.onKeyPress}
                    maxLength={this.props.maxLength}
                    readOnly={readOnly}
                />
                {this.props.errorMessage && (
                    <div className={"text-red-600 font-semibold"}>
                        {this.props.errorLabelMessage ? this.props.errorLabelMessage : "Field required*"}
                    </div>
                )}
            </React.Fragment>
        )
    }
}
