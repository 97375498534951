import React, {useEffect, useRef, useState} from "react";
import {getLookup} from "../../../util/util-helpers";

const componentForm = {
    street_number: "short_name",
    route: "long_name",
    locality: "long_name",
    administrative_area_level_1: "short_name",
    country: "long_name",
    postal_code: "short_name",
};

const defaultFields = {
    route: "AddressName",
    locality: "CityName",
    administrative_area_level_1: "StateID",
    country: "CountryID",
    postal_code: "PostalCode",
}

// Example output
// street_number:               2110
// route:                       Test Drive
// locality:                    Chattanooga
// administrative_area_level_1: TN
// country:                     United States
// postal_code:                 37421

const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.defer = true;
    script.type = "text/javascript";

   
    // Check if script is already called
    const scripts = document.getElementsByTagName('script');
    for (let i = scripts.length; i--;) {
        if (scripts[i].src == url) {
            callback();
            return null;
        }
    }


    if (script.readyState) {
        script.onreadystatechange = function () {
            if (script.readyState === "loaded" || script.readyState === "complete") {
                script.onreadystatechange = null;
                callback();
            }
        };
    } else {
        script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
};

function handleScriptLoad(updateQuery, autoCompleteRef, props) {
    let stateFields = props.stateFields;
    console.log('autoCompleteRef', autoCompleteRef)

    let countryCode = "US";
    if (stateFields?.CountryID?.value) {
        countryCode = getLookup("Country", "CountryID", "Abbreviation");

        if (countryCode) {
            countryCode = countryCode[stateFields.CountryID.value];
        }
    }
    let autoComplete;
    autoComplete = new window.google.maps.places.Autocomplete(
        autoCompleteRef.current,

        Object.assign({}, {
            types: ["geocode", "establishment"],

        }, (!props.searchForAll) ? {componentRestrictions: {country: countryCode}} : {})
    );

    autoComplete.addListener("place_changed", () =>
        handlePlaceSelect(updateQuery, props, autoComplete)
    );
}

async function handlePlaceSelect(updateQuery, props, autoComplete) {
    const fields = props.fields ? props.fields : defaultFields;

    const place = autoComplete.getPlace();

    if (place === undefined || !place.address_components) {
        return false;
    }

    let street, streetNumber;

    const query = place.formatted_address;
    updateQuery(query);

    const states = props.states ?? getLookup('State', 'StateAbbreviation', 'StateID')

    let stateFields = props.stateFields;
    let returnFields = {};

    if (stateFields && place.name && stateFields.LegalName) {
        returnFields["LegalName"] = place.name;
    }

    if (stateFields && place.name && stateFields.LocationName) {
        returnFields["LocationName"] = place.name;
    }

    if (stateFields && place.name && stateFields.CompanyName) {
        returnFields["CompanyName"] = place.name;
    }

    for (const component of place.address_components) {
        const addressType = component.types[0];
        if (componentForm[addressType]) {
            const val = component[componentForm[addressType]];

            if (addressType === 'postal_code' && val) {
                returnFields[fields[addressType]] = val;
            }

            if (addressType === 'locality' && val) {
                returnFields[fields[addressType]] = val;
            }

            if (addressType === 'administrative_area_level_1' && val) {
                returnFields[fields[addressType]] = states[val];
            }

            if (addressType === 'route' && val) {
                street = val;
            }

            if (addressType === 'street_number' && val) {
                streetNumber = val;
            }
        }
    }

    if (!returnFields["CityName"]) {
        returnFields["CityName"] = "";
    }

    if (props.stateFields["AddressName"]) {
        returnFields["AddressName"] = "";
    }

    if (props.stateFields["AddressName2"]) {
        returnFields["AddressName2"] = ""; // clear AddressName2 field
    }

    if (place?.geometry?.location?.lat() && place?.geometry?.location?.lng() && props.stateFields?.Latitude && props.stateFields?.Longitude) {
        returnFields["Latitude"] = place.geometry.location.lat()
        returnFields["Longitude"] = place.geometry.location.lng()
    }

    if (stateFields && street) {
        returnFields["AddressName"] = (streetNumber ? streetNumber + " " : "") + street;
    }

    if (stateFields && stateFields.AreaCode && stateFields.CountryID) {
        const countryCode = props.countryCode ?? getLookup("Country", "CountryID", "CountryCode")
        returnFields["AreaCode"] = countryCode[stateFields.CountryID.value]
    }

    returnFields['query'] = query

    props.setLocations(returnFields)
}

function FieldGooglePlaces(props) {
    const [query, setQuery] = useState("");
    const [initialFocus, setInitialFocus] = useState(false);
    const autoCompleteRef = useRef(null);

    const cleanUp = () => {
        let autoCompleteContainer = document.querySelector('.pac-container.pac-logo');

        if (autoCompleteContainer) {
            autoCompleteContainer.remove();
            setQuery("");
        }
    }

    useEffect(() => {
        if (!initialFocus) return;

        if (props.autocompleteQuery) {
            setQuery(props.autocompleteQuery)
        }

        //cleanUp();

        if (autoCompleteRef) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places&callback=Function.prototype`,

                () => handleScriptLoad(setQuery, autoCompleteRef, props)
            );
        }

       

        if (props.autoFocus && autoCompleteRef) {
            autoCompleteRef.current.focus();
        }

        return () => {
            cleanUp();
        }
    }, [initialFocus]);

    return (
        <div className="search-location-input">
            <input
                onFocus={() => setInitialFocus(true)}
                className="form-control"
                ref={autoCompleteRef}
                onChange={event => setQuery(event.target.value)}
                placeholder={props.placeholderText}
                value={query}
            />
        </div>
    );
}

export default FieldGooglePlaces;
