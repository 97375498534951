import {checkPerm, getProp, openInNewTab} from "../../../util/util-helpers";
import React, {useEffect} from "react";
import {getDialogInfoResource} from "../../../../data/actions/dialogInfoResource";
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../../data/services/resources";
import {LoaderSmall} from "../../loader";
import DataCard from "../../display-data/data-card";
import ModalFooter from "../modal-footer";
import {UPDATE_PERM} from "../../../util/util-consts";
import {Field} from "../../../../data/services/fields";
import {classNames} from "../../../util/util-helpers";

export default function TrailerCard({translate, TrailerID, dialogInfoResource, dispatch, onClose}){

    const data = getProp(dialogInfoResource, "data", {});
    const isLoading = getProp(dialogInfoResource, "isLoading", true);
    const dataFields = Object.values(getFields()).filter(it => data[it.name] !== undefined);

    useEffect(() => {
        dispatch(getDialogInfoResource({
            user: LocalStorage.get("user"),
            resource: Resources.Trailer,
            query: {id: TrailerID}
        }));
    }, [])

    const handleUpdateButtonClick = () => {
        openInNewTab("/trailers/info/" + TrailerID)
    }

    return (
        <React.Fragment>
            <div className="relative p-3 min-h-[24rem]">
                {isLoading && (
                    <div className="text-center absolute inset-0 flex items-center justify-center">
                        <LoaderSmall/>
                    </div>
                )}

                {!isLoading && (
                    <React.Fragment>
                        <div className={'max-w-md mx-auto my-10'}>
                            <p className="text-2xl font-bold truncate mr-6 mb-2 text-tm-gray-900">
                                {data["TrailerNumber"]}
                            </p>

                            <div className="bg-inverse rounded-xl border-tm-gray-300 border shadow-card">
                                {dataFields.map((field, i) => {
                                    return (
                                        <DataCard
                                            key={field.name}
                                            displayField={field}
                                            fieldsData={data}
                                            className={classNames("py-1.5 flex items-center px-6", i < dataFields.length - 1 ? "border-b border-tm-gray-300" : undefined)}
                                            translate={translate}
                                        />
                                    )
                                })}
                            </div>

                            {!!data?.Notes && (
                                <div className="mt-6">
                                    <p className="font-bold leading-5 mb-2">{translate("field.Notes")}</p>
                                    <div
                                        className="bg-inverse rounded-xl border-tm-gray-300 border shadow-card px-6 py-3 flex items-center">
                                        {data?.Notes}
                                    </div>
                                </div>
                            )}
                        </div>
                    </React.Fragment>
                )}
            </div>

            <ModalFooter
                buttonLabel={checkPerm(Resources.Trailer, UPDATE_PERM) && translate("btn.view")}
                onButtonClick={handleUpdateButtonClick}
                closeButtonLabel={translate("btn.cancel")}
                onClose={onClose}
            />
        </React.Fragment>
    )
}


const getFields = () => {
    return {
        TrailerNumber: new Field('TrailerNumber', '', [''], false, "text",),
        Model: new Field('Model', '', [''], false, "text",),
        Year: new Field('Year', '', [''], false, "text", {label: "model_year"}),
        Manufacturer: new Field('Manufacturer', '', [''], false, "text",),
        State: new Field('State', '', [''], false, "text",),
        TrailerLicenceNumber: new Field('TrailerLicenceNumber', '', [''], false, "text"),
        VIN: new Field('VIN', '', [''], false, "text"),
        TrailerOwnershipType: new Field('TrailerOwnershipType', '', [''], false, "text"),
    }
};