import React from "react";
import {classNames} from "../../../util/util-helpers";
export default function ResourceTableTags({data, addClass}) {
    return <div className="flex gap-x-0.5 w-full">
        {
            data.split("|").map((it, i) => {
                const itData = it.split(",");
                it = {label: itData[0], color: itData[1]}
                return (
                    <div
                        key={i}
                        className={
                            classNames(
                                "leading-5 text-white items-center px-2.5 rounded-md text-xs uppercase font-normal flex-shrink-1 min-w-0",
                                addClass
                            )
                        }
                        style={{background: it.color}}
                    >
                        <div className="truncate">
                            {it.label}
                        </div>
                    </div>
                )
            })
        }
    </div>
}
