import React, {Component} from 'react'
import {Field, FieldsManager} from "../../../../data/services/fields";
import {createResource, deleteResource, getResource, updateResource} from "../../../../data/actions/resource";
import LocalStorage from "../../../util/localStorage";
import {fieldsToHtml, fillFieldsFromData} from '../../../util/util-fields'
import {download, uploadDocument} from "../../../../data/actions/download";
import Env from "../../../../util/env";
import icons, {HourglassIcon} from "../../../../data/themes/icons";
import moment from "moment-timezone";
import Resources from "../../../../data/services/resources";
import {ArrowDownTrayIcon, Bars4Icon, EnvelopeIcon, EyeIcon, TrashIcon} from '@heroicons/react/24/outline'
import NoRecords from "../../no-records-found/no-records";
import TableFilters from "../../resource-table/table-components/table-filters";
import Card from "../../card";
import Tippy from "@tippyjs/react";
import DocumentsBoardView from "./documents-board-view";
import {CheckCircleIcon, Squares2X2Icon} from "@heroicons/react/20/solid";
import DocumentPreviewModal from "./document-preview-modal";
import FieldCheckbox from "../../fields/field-checkbox";
import {
    DEFAULT_CRUD_STATE,
    DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY
} from '../../../../util/util-constants'
import {classNames, getDefaultTableOptions, getLookup, getProp} from "../../../util/util-helpers";
import {currentDate, toFrontDateNoTime} from "../../../util/util-dates";
import ModalConfirm from "../../modal/modal-confirm";
import TableCard from "../../resource-table/table-components/table-card";
import ModalDefault from "../../modal/modal-default";
import ModalSaveResource from '../../modal/modal-save-resource'
import FileViewer from "../../file-viewer/components";
import DocumentVersionsModal from "./document-versions-modal";
import InfoBar from "../../info-paragraph/info-bar";
import FieldSwitch from "../../fields/field-switch";
import PageHeader from "../../layout/layout-components/page/page-header";
import ActiveFilters from "../../resource-table/table-components/active-filters";
import ResourceTable from "../../resource-table";
import EmailDocumentDialog from "../../modal/modal-email-documents/modal-email-document";
import DropZone from "../../../../common/components/dropzone/drop-zone-alt";
import CardSubTitle from "../../card/card-sub-title";
import {getJWT} from "../../../util/util-auth";

export default class DocumentsUpdateTab extends Component {

    constructor(props) {
        super(props);
        this.pagePath = "documents"

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: this.props.sortBy ?? "",

            searchFields: this.props.searchFields ?? {},

            fields: this.getFields(),
            tableFields: this.getTableFields(),
            tableOptions: getDefaultTableOptions(this.getTableFields(), {}, this.pagePath, this.props.translate),

            files: [],
            file: null,

            selectedItem: null,
            uploadFilesModalOpen: false,
            confirmModalOpen: false,
            isUpdateModalOpen: false,
            emailModalOpen: false,

            canSubmit: false,

            dataView: "table",
            isDocumentDragged: false,
            fileTypeUploading: '',
            willCopyValues: true,
        };

        this.DropZoneClickRef = React.createRef();

        this.areCheckboxesTouched = false;
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();

        if (!!this.props?.fetchInfoData && !this.props?.info?.isLoading && this.props?.info?.data?.LoadID !== Number(this.props?.id)) {
            this.props.fetchInfoData();
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.resource !== this.props.resource && !!this.state.versionsModalOpen) {
            const item = this.getData().find(it => it.uuid === this.state.selectedItem.uuid);
            if (item) {
                this.setState({
                    selectedItem: item
                })
            } else {
                this.handleToggleVersionsModal()
            }
        }

        if (prevState.uploadFilesModalOpen !== this.state.uploadFilesModalOpen) {
            this.areCheckboxesTouched = false;
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        if (this.props.onFetchData) {
            this.props.onFetchData({query: this.getQuery(), resource: this.getResourceName()})
        } else {
            this.props.dispatch(getResource({
                user: LocalStorage.get("user"),
                query: this.getQuery(),
                resource: this.getResourceName()
            }))
        }
    };

    downloadDocument = (document) => {
        const fileExt = document?.DocumentName.split('.').pop();

        this.props.dispatch(download({
            user: LocalStorage.get("user"),
            resource: this.getResourceName(),
            query: Object.assign({
                [this.getPrimaryKey()]: document[this.getPrimaryKey()],
                id: this.getId(),
                format: fileExt,
                name: "document_" + currentDate() + "." + fileExt
            })
        }))
    }

    onUploadVersion = (document, params = {}) => {
        const files = [Object.assign(document, {
            params: Object.assign({}, {
                uuid: this.state.selectedItem.uuid,
                name: document.name
            }, params)
        })]

        this.props.dispatch(uploadDocument({
            user: LocalStorage.get("user"),
            files: files,
            id: this.getId(),
            query: Object.assign({}, {
                id: this.props.id,
            }, this.getQuery()),
            resource: this.getResourceName(),
            errorMessage: true,
            successMessage: `New Version has been uploaded successfully.`,
            piggyResource: this.getPiggyResourceName()
        }))
    }

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData());
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleToggleViewDocument = (item = null) => {
        let documentIndex = null
        if (item) {
            documentIndex = this.getData().findIndex(elem => elem.DocumentID === item.DocumentID)
        }

        this.setState({previewDocumentDialog: !this.state.previewDocumentDialog, documentIndex: documentIndex});
    }

    handleOnPreviousDocumentPreviewClick = () => {
        this.state.documentIndex > 0 && this.setState({documentIndex: this.state.documentIndex - 1})
    }

    handleOnNextDocumentPreviewClick = () => {
        this.state.documentIndex < (this.getData().length - 1) && this.setState({documentIndex: this.state.documentIndex + 1})
    }

    handleDownloadDocument = () => {
        this.downloadDocument(this.getData()[this.state.documentIndex])
        this.handleToggleViewDocument()
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleToggleVersionsModal = (item = null) => {
        this.setState({
            selectedItem: item,
            versionsModalOpen: !this.state.versionsModalOpen
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({searchFields: FieldsManager.updateField(this.state.searchFields, name, value)});
    }

    handleFilterClear = () => {
        this.setState({
            searchFields: this.props.searchFields ?? {},
        }, () => {
            this.fetchData()
        })
    }

    handleCancelUpload = () => {
        this.setState({
            uploadFilesModalOpen: false,
            files: [],
            fields: this.getFields()
        });
    }

    showPreviewModal = (item) => {
        this.setState({showPreviewModal: true, previewFile: item})
    }

    handleUploadDocumentType = (DocumentTypeID) => {
        this.setState({
            fileTypeUploading: DocumentTypeID
        }, () => {
            this.DropZoneClickRef?.current?.click();
        });
    }

    downloadPreview = (uri, name) => {
        const link = document.createElement("a");
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    handleRemoveDocumentFromUpload = (index) => {
        let files = this.state.files
        files.splice(index, 1)
        if (files.length === 0) {
            this.handleCancelUpload()
        } else {
            this.setState({files})
        }
    }

    handleInputChange = (name, value, i) => {
        let files = [...this.state.files];
        files[i].fields = FieldsManager.updateField(files[i].fields, name, value)
        let updateIsVisibleForCustomer = false;

        if (!i && name === "IsSendWithInvoice") {
            // In value is initialy false, checking IsSendWithInvoice will also check IsVisibleForCustomer
            if (!this.areCheckboxesTouched && value) {
                files[i].fields.IsVisibleForCustomer.value = value;
                updateIsVisibleForCustomer = true;
            }

            this.areCheckboxesTouched = true;
        }

        if (!i && this.state.willCopyValues) {
            files = files.map((file, fileIndex) => {
                if (fileIndex) {
                    file.fields[name].value = value;

                    if (updateIsVisibleForCustomer) {
                        file.fields.IsVisibleForCustomer.value = value;
                    }
                }

                return file
            });
        }

        this.setState({
            files,
            canSubmit: true
        })
    };

    handleUploadDocument = () => {
        this.setState({
            files: this.state.files.map((file) => Object.assign(file, {
                fields: FieldsManager.validateFields(file.fields)
            }))
        }, () => {
            if (this.state.files.reduce((memo, file) => (memo && FieldsManager.checkFieldsForErrors(file.fields)), true)) {
                const files = this.state.files.map(file => Object.assign(file, {
                    params: Object.assign({}, FieldsManager.getFieldKeyValues(file.fields), {
                        Version: 0
                    })
                }))

                this.props.dispatch(uploadDocument({
                    user: LocalStorage.get("user"),
                    files: files,
                    id: this.getId(),
                    query: Object.assign({}, {
                        id: this.props.id,
                    }, this.getQuery()),
                    resource: this.getResourceName(),
                    errorMessage: true,
                    successMessage: `Attachment${this.state.files.length > 1 ? "s" : ""} ${this.state.files.length > 1 ? "have" : "has"} been uploaded successfully.`,
                    piggyResource: this.getPiggyResourceName()
                }))
                this.handleCancelUpload()
            }
        })
    }

    handleReturnExpiryDate = (item) => {
        let ExpiryDate = item.ExpiryDate
        let today = moment().format("YYYY-MM-DD HH:mm:ss")
        let x = 10
        let xDaysBefore = moment(ExpiryDate).add(-x, 'days').format("YYYY-MM-DD HH:mm:ss")
        let styles = 'text-tm-gray-700 py-0.5 h-6'
        if (ExpiryDate) {
            if (xDaysBefore < today && today < ExpiryDate) {
                styles = 'btn-warning text-inverse'
            }
            if (today > ExpiryDate) {
                styles = 'btn-danger'
            }
        }
        return (
            <>
                <button className={`btn btn-xs active ${styles}`}>
                    {toFrontDateNoTime(ExpiryDate)}
                </button>
            </>
        )
    }

    toggleSendEmailDialog = (item = null) => {
        // ignore event
        item = item?.current ? null : item;

        this.setState({
            emailModalOpen: !this.state.emailModalOpen,
            selectedItem: item
        })
    }

    handleSetDataView = (view) => {
        LocalStorage.set("dataView", view)
        this.setState({dataView: view}, this.fetchData)
    }

    handleToggleUpdateModal = (item = null) => {
        this.setState({
            isUpdateModalOpen: !this.state.isUpdateModalOpen,
            selectedItem: item,
        })
    }

    /** Helpers
     ================================================================= */
    getDocumentUploadFields = (index = 0) => {
        let fields;
        const DocumentTypeID = this.state.fileTypeUploading ?? '';

        if (this.props.documentUploadFields) {
            fields = Object.assign({}, this.props.documentUploadFields(index))
        } else {

            fields = {
                DocumentTypeID: new Field("DocumentTypeID", '', ['empty'], false, 'select'),
                ExpiryDate: new Field("ExpiryDate", '', [], false, 'date'),
                Description: new Field("Description", '', [], false, 'textarea'),
            }
        }

        if (!!DocumentTypeID && !!fields.DocumentTypeID) {
            fields.DocumentTypeID.value = DocumentTypeID;
        }

        return fields;
    }

    getId = () => {
        return this.props.id;
    }

    getPrimaryKey = () => {
        return this.props.documentID ?? "DocumentID";
    }

    getResourceName = () => {
        return this.props.resourceName ?? "";
    }

    getPiggyResourceName = () => {
        return this.props.piggyResourceName ?? this.getResourceName()
    }

    getQuery = () => {
        return {
            id: this.getId(),
            limit: 50,
            offset: 0,
            sort: this.state.sort,
            sortBy: this.state.sortBy,

            ...FieldsManager.getFieldKeyValues(this.state.searchFields),
            // searchFields: JSON.stringify({DocumentTypeID: this.state.searchFields.DocumentTypeID?.value})
        }
    }

    getFields = () => {
        return {
            DocumentTypeID: new Field("DocumentTypeID", '', ['empty'], false, 'select'),
        }
    };

    getUpdateFields = (item = null) => {
        let fieldTemplates = Object.assign({}, this.props.documentUpdateFields);


        return fillFieldsFromData(fieldTemplates, item);
    }

    getEmailFields = () => {
        return {
            EmailTemplateID: new Field("EmailTemplateID", '', [''], false, 'select-search', {addContainerClass: "relative col-span-3 md:col-span-6"}),
            emails: new Field("emails", '', ['empty'], false, 'creatable', {
                addContainerClass: "col-span-8",
                onEnterDown: true
            }, {isMulti: true, components: {DropdownIndicator: null}, menuIsOpen: false, placeholder: "Type email..."}),
            AddContactID: new Field("AddContactID", '', [''], false, 'select-search', {addContainerClass: "col-span-4 -ml-px"}, {placeholder: "Filter contacts..."}),
            subject: new Field("subject", '', ['empty'], false, 'text', {addContainerClass: "col-span-full"}),
            emailMessage: new Field("emailMessage", '', [], false, 'rich-text', {addContainerClass: "col-span-full"}),
        }
    }

    getTableFields = () => {
        return Object.assign({}, {
            Description: new Field("Description", '', [], false, 'textarea'),
            DocumentTypeID: new Field("DocumentTypeID", '', ['empty'], false, 'select'),
            UpdatedByContact: new Field("UpdatedByContact", '', [''], false, 'custom', {
                render: (item) => <>{item.UpdatedByContact ?? this.props.translate("text.system")}</>,
                omitSort: true
            }),
            CreateUpdateDate: new Field("CreateUpdateDate", '', ['empty'], false, 'date'),
            ExpiryDate: new Field("ExpiryDate", '', [], false, 'custom', {
                render: (item) => this.handleReturnExpiryDate(item)
            })
        }, this.props.additionalTableFields ?? {})
    };

    getFileExtension = (fileName = '') => {
        const ext = fileName.split('.').pop();
        const documentExt = ['doc', 'docx', 'xlsx', 'txt', 'pdf', 'png', 'jpg'];
        if (documentExt.includes(ext)) {
            return icons[ext];
        }

        return icons.DefaultDocumentIcon
    }

    onDragEnter = () => {
        this.setState({
            dropzoneActive: true
        });
    }

    onDragLeave = () => {
        this.setState({
            dropzoneActive: false
        });
    }

    onDrop = () => {
        this.setState({
            dropzoneActive: false,
            uploadFilesModalOpen: true
        });
    }

    onlyUnique = (value, index, self) => {
        return self.indexOf(value) === index;
    }

    getData = () => {
        return getProp(this.props, "resource.data.list", [])
            .filter(it => {
                let extention = it?.OriginalFilename ? it.OriginalFilename.split('.').pop() : '';
                const {
                    DocumentTypeID,
                    DocumentExtension,
                    IsSendWithInvoice,
                    IsVisibleForCustomer,
                    query
                } = FieldsManager.getFieldKeyValues(this.state.searchFields)

                if (!!DocumentTypeID && (DocumentTypeID != it.DocumentTypeID)) {
                    return false;
                }

                if (!!DocumentExtension && (DocumentExtension != extention)) {
                    return false;
                }

                if (!!query && !it.DocumentName.includes(query)) {
                    return false;
                }

                if (!!IsVisibleForCustomer && !it.IsVisibleForCustomer) {
                    return false
                }

                if (!!IsSendWithInvoice && !it.IsSendWithInvoice) {
                    return false
                }

                return true;
            })
    }

    handleDocumentDragEnter = () => {
        this.setState({
            isDocumentDragged: true
        })
    }

    handleDocumentDragStop = () => {
        this.setState({
            isDocumentDragged: false
        })
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource, createOnly, disableDelete, disableSendViaEmail, readOnly, download} = this.props;

        const data = this.getData()

        const LoadInfo = getProp(resource, "data.info", false)

        const documentIDs = getProp(this.props, "resource.data.list", []).map(it => it.DocumentTypeID)
        const requiredDocumentIDs = getProp(this.props, "resource.data.requiredDocuments", []).map(it => it.DocumentTypeID)
        let intersectionArray = documentIDs
            .filter(x => requiredDocumentIDs.indexOf(x) !== -1)
            .filter(this.onlyUnique);

        const requiredDocuments = getProp(this.props, "resource.data.requiredDocuments", []).map((item, i) => {
            const hasType = documentIDs.includes(item.DocumentTypeID);

            return (
                <div
                    key={item?.CustomerRequiredDocumentsID ?? i}
                    className={
                        classNames(
                            "cursor-pointer flex gap-x-2 items-center text-sm font-medium px-2.5 py-0.5 rounded",
                            hasType ? "bg-green-600/10 text-tm-gray-900" : "bg-red-600/10 text-tm-gray-900"
                        )
                    }
                    onClick={() => hasType
                        ? this.handleFilterInputChange("DocumentTypeID", item.DocumentTypeID)
                        : this.handleUploadDocumentType(item.DocumentTypeID)
                    }
                >
                    {hasType && (
                        <CheckCircleIcon className="text-green-600 w-5 h-5"/>
                    )}
                    {item.DocumentType}
                </div>
            )
        })

        const isLoading = resource.isLoading || download.isLoading

        return (
            <div
                onDragEnter={this.handleDocumentDragEnter}
            >
                {!readOnly &&
                    (
                        <PageHeader
                            title={translate("page.heading.Documents")}
                            titleClass="mb-5 text-2xl ml-4"
                        />
                    )}

                {!readOnly && LoadInfo && (
                    <>
                        <InfoBar addClass="mb-6">
                            {translate("text.LoadDocumentsInfoParagraph")}
                        </InfoBar>

                        <div className="mb-4 mt-4">
                            <ActiveFilters
                                filterFields={this.state.searchFields}
                                onLabelClick={this.handleFilterInputChange}
                                onClearFiltersClick={this.handleFilterClear}
                                translate={translate}
                            />
                        </div>
                    </>
                )}

                <Card addClass="relative pb-7">
                    {!readOnly && (
                        <DropZone
                            className={
                                classNames(
                                    "absolute",
                                    this.state.isDocumentDragged ? "inset-0 z-50" : "left-0 right-0 bottom-0"
                                )
                            }
                            maxFilesAccepted={10}
                            accept={DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS}
                            translate={translate}
                            onAcceptFiles={(acceptedFiles) => {
                                this.setState({
                                    files: acceptedFiles.map((file, index) => Object.assign(file, {
                                        preview: URL.createObjectURL(file),
                                        errorMessage: '',
                                        fields: Object.assign({}, this.getDocumentUploadFields(index))
                                    })),
                                    uploadFilesModalOpen: true,
                                    isDocumentDragged: false
                                }, () => {
                                    this.setState({
                                        fileTypeUploading: ''
                                    })
                                });
                            }}
                            content={(isDragAccept, isDragReject) => {
                                return (
                                    <React.Fragment>
                                        <div
                                            onDragLeave={this.handleDocumentDragStop}
                                            className={classNames(
                                                isDragAccept ? "border-2 border-dashed border-green-600 bg-green-600 bg-opacity-10" : undefined,
                                                isDragReject ? " border-2 border-dashed border-red-600 bg-red-600 bg-opacity-10" : undefined,
                                                !isDragAccept && !isDragReject ? "border-transparent" : undefined,
                                                "pb-4 absolute inset-0 rounded-card"
                                            )}>

                                        </div>

                                        <div
                                            className="absolute bottom-0 left-0 right-0 px-6 cursor-pointer py-1 border-t border-primary border-dotted bg-primary-transparent">
                                            Attach files by dragging and dropping them or
                                            <span className="text-primary-tint font-semibold"
                                                  ref={this.DropZoneClickRef}>{" "} click here</span> to
                                            select them.
                                        </div>
                                    </React.Fragment>
                                )
                            }}
                        />
                    )}

                    {!createOnly && (
                        <div className="grid gap-4 xl:grid-cols-12">
                            <div className="col-span-full">
                                <TableFilters
                                    className="flex"
                                    hideLimit
                                    filterFields={this.state.searchFields}
                                    handleInputChange={this.handleFilterInputChange}
                                    translate={translate}
                                    onRefreshTable={this.fetchData}
                                >
                                    {data.length > 0 && (
                                        <div className="flex justify-end items-start">
                                            <Tippy content={translate("text.table_view")}>
                                                <button
                                                    onClick={() => this.handleSetDataView("table")}
                                                    className={classNames(this.state.dataView === "table" ? "text-primary bg-inverse" : "bg-disabled text-tm-gray-400", "-mr-px relative inline-flex items-center px-4 py-2 rounded-l-btn border border-tm-gray-300 text-sm font-medium focus:z-10 focus:outline-none focus:ring-1")}
                                                >
                                                    <Bars4Icon className="h-5 w-5" aria-hidden="true"/>
                                                </button>
                                            </Tippy>
                                            <Tippy content={translate("text.grid_view")}>
                                                <button
                                                    onClick={() => this.handleSetDataView("grid")}
                                                    className={classNames(this.state.dataView === "grid" ? "text-primary bg-inverse" : "bg-disabled text-tm-gray-400", "-ml-px relative inline-flex items-center px-4 py-2 rounded-r-btn border border-tm-gray-300 text-sm font-medium focus:z-10 focus:outline-none focus:ring-1")}
                                                >
                                                    <Squares2X2Icon className="h-5 w-5" aria-hidden="true"/>
                                                </button>
                                            </Tippy>
                                        </div>
                                    )}
                                </TableFilters>
                            </div>
                        </div>
                    )}

                    {!createOnly && (
                        <>
                            <div className="flex justify-between items-center">
                                <div className="flex flex-wrap gap-4">
                                    {!!requiredDocuments.length && (
                                        <InfoBar className="flex gap-2 px-2 h-6 rounded-md items-center">
                                            {translate("text.RequiredDocumentsUploaded", [intersectionArray.length, requiredDocuments.length])}
                                        </InfoBar>
                                    )}

                                    {requiredDocuments.length > 0 && requiredDocuments}

                                    {(!readOnly && LoadInfo?.IsPODReady) ? (
                                        <div
                                            className="gap-2 h-6 flex items-center">
                                            <FieldSwitch
                                                id="IsPODReviewed"
                                                onChange={(_, value) => {
                                                    this.props.dispatch(updateResource({
                                                        user: LocalStorage.get("user"),
                                                        params: {
                                                            LoadID: this.getId(),
                                                            IsPODReviewed: value ? 1 : 0
                                                        },
                                                        resource: Resources.LoadPODReviewed,
                                                        piggyResource: this.getPiggyResourceName(),
                                                        piggyQuery: this.getQuery()
                                                    }))
                                                }}
                                                value={!!LoadInfo?.IsPODReviewed}
                                                name={"IsPODReviewed"}
                                            />
                                            <label className={""}
                                                   htmlFor="IsPODReviewed">{translate("field.IsPODReviewed")}</label>
                                        </div>
                                    ) : ""}
                                </div>
                            </div>
                        </>
                    )}

                    {createOnly && (
                        <>
                            <InfoBar addClass="mb-6">
                                {translate("text.LoadDocumentsDropOffOnly")}
                            </InfoBar>
                        </>
                    )}

                    {!createOnly &&
                        (<>
                                {this.state.dataView === "table" && (
                                    <TableCard addClass={'relative z-0'}>
                                        <ResourceTable
                                            data={data}
                                            fields={this.state.tableFields}

                                            limit={this.getQuery()?.limit}

                                            translate={translate}
                                            isLoading={isLoading}
                                            sort={this.state.sort}
                                            sortBy={this.state.sortBy}
                                            onSortChange={this.handleUpdateSort}

                                            options={this.state.tableOptions}

                                            onRowClick={this.handleToggleViewDocument}
                                            onView={this.handleToggleViewDocument}
                                            onDelete={!disableDelete && this.handleToggleConfirmModal}
                                            onEdit={this.props.documentUpdateFields ? this.handleToggleUpdateModal : null}
                                            actions={[
                                                {
                                                    action: this.toggleSendEmailDialog,
                                                    icon: EnvelopeIcon, // make this a function
                                                    visible: () => !disableSendViaEmail,
                                                    label: false, // make this a function
                                                    title: translate('btn.send_document_via_email'),
                                                    disabled: false,
                                                    class: false,
                                                    iconClass: false
                                                },
                                                {
                                                    action: this.handleToggleVersionsModal,
                                                    customContent: () => <HourglassIcon className="w-5 h-5"/>, // make this a function
                                                    visible: () => !readOnly,
                                                    label: false, // make this a function
                                                    title: translate('text.version_manager'),
                                                    disabled: false,
                                                    class: false,
                                                    iconClass: false
                                                },
                                                {
                                                    action: this.downloadDocument,
                                                    icon: ArrowDownTrayIcon, // make this a function
                                                    visible: () => true,
                                                    label: false, // make this a function
                                                    title: translate('btn.Download'),
                                                    disabled: false,
                                                    class: false,
                                                    iconClass: false
                                                },
                                            ]}
                                        />
                                    </TableCard>
                                )}

                                {this.state.dataView === "grid" && (
                                    <div>
                                        <DocumentsBoardView
                                            disableDelete={disableDelete}
                                            disableSendViaEmail={disableSendViaEmail}
                                            data={data}
                                            isLoading={isLoading}
                                            translate={this.props.translate}
                                            onGetFileExtension={this.getFileExtension}
                                            onGetReturnExpiryDate={this.handleReturnExpiryDate}
                                            onToggleViewDocument={this.handleToggleViewDocument}
                                            onToggleSendEmailDialog={this.toggleSendEmailDialog}
                                            onToggleConfirmModal={this.handleToggleConfirmModal}
                                            onDownloadDocument={this.downloadDocument}
                                        />
                                    </div>
                                )}

                                <NoRecords
                                    show={(data.length === 0) && !isLoading}
                                    title={translate("text.no_records")}
                                    addClass={'pt-10 pb-10'}
                                />
                            </>
                        )}

                    <ModalConfirm
                        title={"Confirm"}
                        show={!!this.state.confirmModalOpen}
                        text={translate("text.confirm_delete_document")}
                        onClose={() => this.setState({confirmModalOpen: false})}
                        buttonLabel={translate("btn.confirm")}
                        closeButtonLabel={translate('btn.cancel')}
                        translate={translate}
                        onConfirm={() => {
                            let id = this.state.selectedItem[this.getPrimaryKey()]
                            this.props.dispatch(deleteResource({
                                user: LocalStorage.get("user"),
                                query: {
                                    [this.getPrimaryKey()]: id
                                },
                                piggyQuery: this.getQuery(),
                                resource: this.getResourceName(),
                                piggyResource: this.getPiggyResourceName(),
                                errorMessage: true, successMessage: this.props.dialogTitle + " delete."
                            }));
                            this.handleToggleConfirmModal()
                        }}
                    />

                    <DocumentPreviewModal
                        show={this.state.previewDocumentDialog}
                        translate={translate}
                        item={data[this.state.documentIndex]}

                        onButtonClick={this.handleDownloadDocument}
                        buttonLabel={translate("btn.Download")}
                        closeButtonLabel={translate("btn.close")}
                        onClose={this.handleToggleViewDocument}
                        isLoading={this.props.download.isLoading}

                        showPreviousButton={this.state.documentIndex > 0}
                        showNextButton={this.state.documentIndex < (data.length - 1)}
                        onNextDocumentPreviewClick={this.handleOnNextDocumentPreviewClick}
                        onPreviousDocumentPreviewClick={this.handleOnPreviousDocumentPreviewClick}

                        primaryKey={this.getPrimaryKey()}
                        id={this.getId()}
                        resourceName={this.getResourceName()}
                        onReturnExpiryDate={this.handleReturnExpiryDate}
                    />

                    <ModalDefault
                        show={this.state.showPreviewModal}
                        widthClass={'max-w-7xl'}
                        title={translate("modal_heading.Preview")}
                        limitHeight={true}

                        close={() => this.setState({showPreviewModal: false, previewFile: null})}
                        closeButtonLabel={translate('btn.close')}
                        onClose={() => this.setState({showPreviewModal: false, previewFile: null})}
                        buttonLabel={translate('Download')}
                        onButtonClick={() => {
                            this.downloadPreview(URL.createObjectURL(this.state.previewFile), this.state.previewFile.name)
                        }}
                    >
                        {!!this.state.previewFile && (
                            <FileViewer
                                filePath={URL.createObjectURL(this.state.previewFile)}
                                fileType={this.state.previewFile.name.split('.').pop()}
                                onError={(e) => {
                                    console.log(e)
                                }}
                            />
                        )}
                    </ModalDefault>

                    <ModalDefault
                        show={this.state.versionsModalOpen}
                        widthClass={'max-w-5xl'}
                        title={translate("text.version_manager")}

                        close={this.handleToggleVersionsModal}
                        closeButtonLabel={translate('btn.close')}
                        onClose={this.handleToggleVersionsModal}
                    >
                        <DocumentVersionsModal
                            translate={translate}
                            isLoading={isLoading}
                            selectedItem={this.state.selectedItem}
                            onUploadVersion={this.onUploadVersion}
                            id={this.getId()}
                            documentTypes={this.props.documentTypes}
                            resourceName={this.getResourceName()}
                            handleDownloadDocument={this.downloadDocument}
                            onReturnExpiryDate={this.handleReturnExpiryDate}
                            onEditDocument={(params) => {
                                this.props.dispatch(updateResource({
                                    user: LocalStorage.get('user'),
                                    params: params,
                                    query: this.getQuery(),
                                    resource: this.getResourceName(),
                                    piggyResource: this.getPiggyResourceName(),
                                    errorMessage: true,
                                    successMessage: `Document has been updated`,
                                }))
                            }}
                            onDocumentDelete={(document) => {
                                let id = document[this.getPrimaryKey()]
                                this.props.dispatch(deleteResource({
                                    user: LocalStorage.get("user"),
                                    query: {
                                        [this.getPrimaryKey()]: id
                                    },
                                    piggyQuery: this.getQuery(),
                                    resource: this.getResourceName(),
                                    piggyResource: this.getPiggyResourceName(),
                                    errorMessage: true, successMessage: this.props.dialogTitle + " delete."
                                }));
                            }}
                        />
                    </ModalDefault>

                    <ModalDefault
                        show={this.state.uploadFilesModalOpen}
                        widthClass={'max-w-5xl'}
                        title={translate("modal_heading.upload_confirm")}
                        limitHeight={true}

                        close={this.handleCancelUpload}
                        closeButtonLabel={translate('btn.cancel')}
                        onClose={this.handleCancelUpload}

                        buttonDisabled={!this.state.canSubmit}
                        buttonLabel={translate('Upload')}
                        onButtonClick={this.handleUploadDocument}
                    >
                        <div className="px-5 pt-5 pb-40">
                            {this.state.files.map((it, i) => {
                                const itemToHtml = fieldsToHtml(Object.values(Object.assign({}, it.fields)), translate, (name, value) => this.handleInputChange(name, value, i), {
                                    DocumentTypeID:
                                        this.props.documentTypes ? this.props.documentTypes :
                                            getLookup('DocumentType')
                                })

                                return (
                                    <div key={i}>
                                        {!!i && (
                                            <div className={'border-b border-tm-gray-300 mb-4 p-4 -ml-4 -mr-4'}/>
                                        )}

                                        <div className="flex items-center mb-4 justify-between">
                                            <div
                                                className="flex items-center text-sm font-semibold text-tm-gray-900 break-all"
                                            >
                                                {this.state.files.length > 1 && (
                                                    <div
                                                        className='w-10 h-10 mr-2 rounded-full flex items-center justify-center bg-tm-gray-50'>
                                                        <CardSubTitle
                                                            text={i + 1}
                                                        />
                                                    </div>
                                                )}

                                                <label>{translate("text.desc_for_file")}: {this.state.files[i].name}</label>
                                            </div>
                                            <div className="text-right">
                                                <button
                                                    className="btn btn-icon"
                                                    title={translate('text.preview')}
                                                    onClick={() => this.showPreviewModal(it)}
                                                >
                                                    <EyeIcon className="h-4 w-4"/>
                                                </button>

                                                <button
                                                    className="btn btn-icon"
                                                    title={translate('text.delete')}
                                                    onClick={() => this.handleRemoveDocumentFromUpload(i)}
                                                >
                                                    <TrashIcon className="h-4 w-4"/>
                                                </button>
                                            </div>
                                        </div>

                                        <div className="grid gap-4">
                                            {this.state.files.length > 1 && !i && (
                                                <label
                                                    className="h-9 flex items-center justify-start px-2 -ml-2 w-full cursor-pointer hover:bg-tm-gray-50"
                                                >
                                                    <FieldCheckbox
                                                        className={
                                                            classNames(
                                                                "checkbox",
                                                                this.state.willCopyValues
                                                                    ? "border-primary"
                                                                    : "border-tm-gray-400"
                                                            )
                                                        }
                                                        onChange={() => this.setState(
                                                            {willCopyValues: !this.state.willCopyValues}
                                                        )}
                                                        value={this.state.willCopyValues}
                                                    />

                                                    <div
                                                        className="ml-2 flex flex-col text-sm font-semibold select-none text-tm-gray-900"
                                                    >
                                                        {translate("text.copy_to_other_fields")}
                                                    </div>
                                                </label>
                                            )}

                                            {itemToHtml}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </ModalDefault>

                    <ModalSaveResource
                        title={"Edit document"}
                        gridColsClass={"grid-cols-12"}
                        show={this.state.isUpdateModalOpen}
                        keyid={this.props.primaryKey}
                        value={this.state.selectedItem}
                        translate={translate}
                        fields={this.getUpdateFields(this.state.selectedItem)}
                        onClose={this.handleToggleUpdateModal}
                        onSubmit={(params) => {
                            params.id = this.state.selectedItem.DocumentID ?? this.state.selectedItem[this.props.primaryKey]
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get('user'),
                                params: params,
                                query: this.getQuery(),
                                resource: this.getResourceName(),
                                piggyResource: this.getPiggyResourceName(),
                                errorMessage: true,
                                successMessage: `Document has been updated`,
                            }))
                            this.handleToggleUpdateModal()
                        }}
                        resourceName={this.getResourceName()}
                        metadata={{}}
                        {...this.props}
                    />

                    {!disableSendViaEmail && (
                        <EmailDocumentDialog
                            show={this.state.emailModalOpen}
                            title={translate("modal_heading.send_document_via_email")}
                            widthClass="max-w-5xl"
                            onClose={this.toggleSendEmailDialog}
                            fields={this.getEmailFields()}
                            metadata={{
                                AddContactID: {
                                    api: "api/" + Resources.ContactsQuick,
                                    query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                    searchMap: (item) => ({
                                        value: item.Email,
                                        label: item.FirstName + " " + item.LastName
                                    })
                                },
                                EmailTemplateID: {
                                    api: 'api/' + Resources.EmailTemplates,
                                    query: {offset: 0, sort: "ASC", limit: 10},
                                    searchMap: (item) => ({
                                        value: item.EmailTemplateID,
                                        label: item.EmailTemplateName
                                    })
                                }
                            }}
                            onSubmit={(params) => {
                                if (params) {
                                    let emails = params.emails.filter(it => !it.invalid).reduce((memo, it) => {
                                        memo += it.value + ",";
                                        return memo
                                    }, "");

                                    this.props.dispatch(createResource({
                                        user: LocalStorage.get("user"),
                                        query: this.getQuery(),
                                        params: Object.assign({}, params, {
                                            PhysicalLocation: this.state.selectedItem?.PhysicalLocation,
                                            emails: emails.substring(0, emails.length - 1)
                                        }),
                                        resource: Resources.DocumentsSend,
                                        errorMessage: true, successMessage: "Emails sent."
                                    }));

                                    this.toggleSendEmailDialog()
                                }
                            }}
                            document={this.state.selectedItem}
                            fileType={this.state.selectedItem?.OriginalFilename?.split('.')?.pop()}
                            filePath={Env.getApiUrl("api/" + this.getResourceName(), Object.assign({}, {
                                [this.getPrimaryKey()]: this.state?.selectedItem && this.state.selectedItem[this.getPrimaryKey()],
                                token: getJWT().access_token,
                                id: this.getId()
                            }))}
                            fetchEmailTemplateQuery={this.props.fetchEmailTemplateQuery}
                            {...this.props}
                        />
                    )}
                </Card>
            </div>
        )
    }
}
