import React, {useEffect, useRef, useState} from "react";
import { classNames } from "../../../util/util-helpers";
import TableHeader from "../table/table-header";
import MarkerLastRowVisited from "../table-components/marker-last-row-visited";

export default function VirtualRows(
    {
        addTableClass,
        headerData,
        headerRender,
        headerClass,
        headerRowClass,
        data,
        options,
        isInitialLoadingRef,
        tableStyle,
        isLoading,
        rowStyle,
        rowClass,
        tableRef,
        tableBodyStyle,
        isGPUAccelerated,
        maxHeightClass,
        tableClass,
        bodyClass,
        onRowClick,
        rowHeight = 48,
        columnRender,
        overscanY = 4,
        setAreActionsVisible,
        tfoot,
        getFooterRowClass,
        footerCellRender,
        footerRowStyle,
        tableHeightRef,
        sizeChangeCount,
        tableContainerRef,
        limit,
        tableBodyHeight,
        lastVisitedRowIndex,
        tableID,
        tableKey,
        onClearLastVisitedRowID,
        updatedRows
    }
) {
    const [rowRenderLimit, setRowRenderLimit] = useState(1);
    const prevScrollTopRef = useRef(0);
    const updateRef = useRef(0);
    const updateConfirmRef = useRef(0)
    const prevIndexRef = useRef(-1);
    const timerRef = useRef();
    const [rowRange, setRowRange] = useState(undefined);
    const [lastVisitedMarkerHeight, setLastVisitedMarkerHeight] = useState(0);
    const getVisibleRows = (scrollTop) => {
        let currentRowIndex = Math.trunc(scrollTop / rowHeight);

        currentRowIndex = (currentRowIndex % 2 === 1) ? currentRowIndex - 1 : currentRowIndex;

        if ((currentRowIndex !== prevIndexRef.current || updateRef.current !== updateConfirmRef.current)) {
            prevIndexRef.current = currentRowIndex;
            // Hide actions on scrolling
            if (options?.style?.floatingActions) {
                setAreActionsVisible(false);
                clearTimeout(timerRef.current);
                timerRef.current = setTimeout(() => setAreActionsVisible(true), 300);
            }
            updateConfirmRef.current = updateRef.current
            ////
            setRowRange([currentRowIndex - overscanY < 0 ? 0 : currentRowIndex - overscanY, currentRowIndex + rowRenderLimit + overscanY]);
        }
    }

    const handleTableScroll = (e) => {
        const top = e.target.scrollTop;

        if (prevScrollTopRef.current !== top && e.target === tableContainerRef.current) {
            getVisibleRows(top);
            prevScrollTopRef.current = top;
        }
    }

    const generateLoaderRows = () => {
        let loaderRowLimitNumber = Number(rowRenderLimit);

        if (isInitialLoadingRef.current) {
            loaderRowLimitNumber = 1;
        }

        return new Array(loaderRowLimitNumber).fill("").map((it, i) => {
            it = {};
            it.dataIndex = prevIndexRef.current + i;
            return it;
        });
    }

    useEffect(() => {
        if (rowRenderLimit) {
            getVisibleRows(prevScrollTopRef.current);
        }
    }, [rowRenderLimit]);

    useEffect(() => {
        if (tableHeightRef.current) {
            let upperLimit = Math.ceil(tableHeightRef.current.offsetHeight / rowHeight);

            if (limit) {
                upperLimit = upperLimit > limit ? limit : upperLimit;
            }

            if (data?.length) {
                upperLimit = upperLimit > data.length ? data.length : upperLimit
            }

            if (!isLoading) {
                setRowRenderLimit(upperLimit);
                updateRef.current = updateRef.current + 1;
            }
        }

        if (tableBodyHeight > rowHeight) {
            setLastVisitedMarkerHeight(lastVisitedRowIndex * rowHeight);
        }
    }, [tableHeightRef, rowHeight, sizeChangeCount, data, isLoading, tableBodyHeight, lastVisitedRowIndex])

    if (!isLoading && !data?.length) {
        return null
    }

    return (
        <React.Fragment>
            <div
                ref={tableContainerRef}
                className={classNames(
                    addTableClass,
                    "w-full border-b border-tm-gray-200",
                    maxHeightClass ?? 'max-h-full',
                    isLoading ? "overflow-hidden" : "overflow-auto",
                    isGPUAccelerated ? 'transform-gpu' : ''
                )}
                onScroll={handleTableScroll}
            >
                <div
                    className={classNames(tableClass)}
                    ref={tableRef}
                    style={tableStyle}
                >
                    <TableHeader
                        headerRender={headerRender}
                        headerData={headerData}
                        headerClass={headerClass}
                        headerRowClass={headerRowClass}
                    />

                    {!!rowRange && (
                        <div
                            className={bodyClass}
                            style={tableBodyStyle}
                        >
                            {(isLoading
                                    ? generateLoaderRows()
                                    : data.slice(rowRange[0], rowRange[1])
                            ).map((row) => {
                                let rowKey = row[tableKey] ?? row.dataIndex;

                                if (updatedRows?.[row[tableKey]]) {
                                    rowKey = updatedRows[row[tableKey]];
                                }

                                return <div
                                    key={rowKey}
                                    className={rowClass(row)}
                                    style={rowStyle(row.dataIndex)}
                                    onClick={() => onRowClick(row.dataIndex, rowKey)}
                                    data-index={row.dataIndex}
                                >
                                    {headerData.map((header) => {
                                        return columnRender
                                            ? columnRender(row, header)
                                            : <td
                                                key={header.key}
                                                style={header.style}
                                            >
                                                {typeof header === 'string' ? row[header] : row[header.key]}
                                            </td>
                                    })}

                                    {updatedRows?.[row[tableKey]] && (
                                        <div
                                            key={rowKey}
                                            className="absolute z-20 inset-0 animate-fade-out bg-primary pointer-events-none"
                                        />
                                    )}
                                </div>
                            })
                            }

                            {!!tfoot && !isLoading && (
                                <div
                                    className={getFooterRowClass()}
                                    style={footerRowStyle((tableContainerRef.current?.clientHeight ?? rowHeight))}

                                    data-index={data.length}
                                >
                                    {headerData.map((header) => {
                                        return footerCellRender
                                            ? footerCellRender(tfoot, header)
                                            : <td
                                                key={header.key}
                                                style={header.style}
                                            >
                                                {typeof header === 'string' ? header : header?.key}
                                            </td>
                                    })}
                                </div>
                            )}

                            {!!lastVisitedMarkerHeight > -1 && lastVisitedRowIndex !== -1 && (
                                <MarkerLastRowVisited
                                    rowHeight={rowHeight}
                                    lastVisitedMarkerHeight={lastVisitedMarkerHeight}
                                    onClearLastVisitedRowID={onClearLastVisitedRowID}
                                    tableID={tableID}
                                    tableKey={tableKey}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>

            <div
                ref={tableHeightRef}
                className={
                    classNames(
                        maxHeightClass,
                        "absolute inset-0 z-[-1]"
                    )
                }
            />
        </React.Fragment>
    )
}
