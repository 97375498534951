import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../data/services/fields'
import {
    getProp,
    updateContractFields,
    updateStateFields
} from '../../../util/util'
import {getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";
import {UPDATE_PERM} from '../../../util/util-constants'
import {checkPerm} from '../../../common/util/util-helpers'
import CardSubTitle from "../../../common/components/card/card-sub-title";
import Card from "../../../common/components/card";
import {fieldsToHtml, fillFieldsFromData, includeFields} from "../../../common/util/util-fields";

export default class InfoTab extends Component {

    constructor(props) {
        super(props)
        this.state = {
            fields: this.getFields(),
            canSubmit: false,

            isGroupInfoDialogOpen: false,
            groupInfoData: {},
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount = () => {
        this.fetchData()
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.resource.isLoading && (!this.props.resource.isLoading)) {
            this.setState({
                fields: this.getFields(getProp(this.props.resource, 'data', {}))
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.TrackingRoutes,
            query: {
                id: this.getID(),
            },
        }))
    }

    onSubmit = () => {
        if (this.state.canSubmit) {
            this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
                if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                    this.setState({canSubmit: false}, () => {
                        this.props.canSwapTabsHandler(false)
                        this.props.dispatch(updateResource({
                            user: LocalStorage.get('user'),
                            params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {
                                id: this.getID(),
                            }),
                            query: {
                                id: this.getID(),
                            },
                            errorMessage: true,
                            successMessage: `Route ${this.getID()} updated`,
                            resource: Resources.TrackingRoutes,
                            piggyResource: Resources.TrackingRoutes,
                        }))
                    })
                }
            })
        }
    }

    /** Helpers
     ================================================================= */
    getID = () => {
        return this.props.ID
    }

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        let fields = FieldsManager.updateField(this.state.fields, name, value)

        if (!this.state.canSubmit) {
            this.props.canSwapTabsHandler()
        }

        fields = updateContractFields(fields, name, value)
        fields = updateStateFields(fields, name, value)

        this.setState({canSubmit: true, fields: fields})
    }

    handleFormCancel = () => {
        if (this.state.canSubmit) {
            this.setState({
                canSubmit: false,
                fields: this.getFields(getProp(this.props.resource, 'data', {}))
            }, () => this.props.canSwapTabsHandler(true))
        }
    }

    /** Fields/Data Definitions
     ================================================================= */
    getFields = (item = {}) => {
        let fieldTemplates = {
            DriverName: new Field('DriverName', '', [''], false, "text", {addContainerClass: "col-span-6"}),
        }

        return item ? fillFieldsFromData(fieldTemplates, item) : fieldTemplates
    }

    getFieldsToHtmlForIncludedFields = (arr = []) => {
        return fieldsToHtml((Object.values(Object.assign({}, includeFields(this.state.fields, arr)))), this.props.translate, this.handleInputChange, {})
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props

        const fieldsHtmlBasicInfo = this.getFieldsToHtmlForIncludedFields(['DriverName'])

        console.log("id", this.getID())
        return (
            <React.Fragment>
                {/*{this.props?.resource?.isLoading && (*/}
                {/*    <Card>*/}
                {/*        <div className="text-center">*/}
                {/*            <LoaderSmall/>*/}
                {/*        </div>*/}
                {/*    </Card>*/}
                {/*)}*/}

                {(
                    <React.Fragment>

                        <div className="grid grid-cols-2 gap-4">
                            <div className="">
                                <Card addClass={'h-full pb-4'}>
                                    <CardSubTitle
                                        text={translate('text.BasicInfo')}
                                    />
                                    <div className="grid grid-cols-12 gap-5">
                                        {fieldsHtmlBasicInfo}
                                    </div>
                                </Card>
                            </div>
                            {/*<div className="">*/}
                            {/*    <Card addClass={'h-full pb-4'}>*/}
                            {/*        <CardSubTitle*/}
                            {/*            text={translate('text.OrganizationInfo')}*/}
                            {/*        />*/}
                            {/*        <div className="grid grid-cols-2 gap-5">*/}
                            {/*            {fieldsHtmlOrganizationInfo}*/}
                            {/*        </div>*/}
                            {/*    </Card>*/}
                            {/*</div>*/}
                            {/*<div className="">*/}
                            {/*    <Card addClass={'h-full pb-4'}>*/}
                            {/*        <CardSubTitle*/}
                            {/*            text={translate('text.Notes')}*/}
                            {/*        />*/}
                            {/*        <div className="">*/}
                            {/*            {fieldsHtmlNotes}*/}
                            {/*        </div>*/}
                            {/*    </Card>*/}
                            {/*</div>*/}
                        </div>

                        <PageFooter
                            translate={translate}
                            canSubmit={this.state.canSubmit}
                            actionCancel={this.handleFormCancel}
                            actionSubmit={checkPerm(Resources.CustomersInfo, UPDATE_PERM) && this.onSubmit}
                        />
                    </React.Fragment>
                )}

                {/*<Modal*/}
                {/*    show={this.state.historyModalOpen}*/}
                {/*    widthClass={"max-w-5xl"}*/}
                {/*    onClose={() => this.setState({historyModalOpen: false})}*/}
                {/*    translate={translate}*/}
                {/*>*/}
                {/*    {this.state.historyModalOpen && (*/}
                {/*        <HistoryModal*/}
                {/*            onClose={() => this.setState({historyModalOpen: false})}*/}
                {/*            translate={translate}*/}
                {/*            title={translate("text." + Resources.CustomerHistory)}*/}
                {/*            tableFields={DEFAULT_HISTORY_TABLE_FIELDS(translate, this.getFieldTypes())}*/}
                {/*            filterFields={() => cloneDeep(DEFAULT_HISTORY_TABLE_FILTER_FIELDS)}*/}
                {/*            onFetchData={(query) => {*/}
                {/*                this.props.dispatch(getHistoryResource({*/}
                {/*                    user: LocalStorage.get('user'),*/}
                {/*                    query: Object.assign({}, query, {*/}
                {/*                        id: this.props.match.params.OrganizationID*/}
                {/*                    }),*/}
                {/*                    resource: Resources.CustomerHistory*/}
                {/*                }))*/}
                {/*            }}*/}
                {/*            data={getProp(historyResource, "data.list", [])}*/}
                {/*            count={getProp(historyResource, "data.count", 0)}*/}
                {/*            isLoading={getProp(historyResource, "isLoading", false)}*/}
                {/*            showUserAvatar*/}

                {/*            onRowClick={(item) => {*/}
                {/*                this.props.dispatch(showModal('ViewContactCard', {ContactID: item.ContactID}))*/}
                {/*            }}*/}
                {/*            fieldTypes={this.getFieldTypes()}*/}
                {/*        />*/}
                {/*    )}*/}
                {/*</Modal>*/}

                {/*<GroupsViewDialog*/}
                {/*    groupID={this.state.fields?.ContactGroupID?.value?.value}*/}
                {/*    isGroupInfoDialogOpen={this.state.isGroupInfoDialogOpen}*/}
                {/*    groupInfoData={this.state.groupInfoData}*/}
                {/*    onClose={() => this.setState({*/}
                {/*        isGroupInfoDialogOpen: false*/}
                {/*    })}*/}
                {/*    translate={translate}*/}
                {/*/>*/}
            </React.Fragment>
        )
    }
}
