import React, {Component} from "react";
import {CREATE_PERM, DEFAULT_CRUD_STATE, DELETE_PERM, READ_PERM} from "../../../../util/util-constants";
import {createDialogResource, getDialogResource} from "../../../../data/actions/dialogResource";
import LocalStorage from "../../../util/localStorage";
import {showModal} from "../../../../data/actions/ui";
import {Field, FieldsManager} from "../../../../data/services/fields";
import PageHeader from "../../layout/layout-components/page/page-header";
import {ArrowPathIcon} from "@heroicons/react/24/outline";
import {checkPerm,  getProp} from "../../../util/util-helpers";
import {resourceIsUpdated, returnOffsetAndPagination}  from "../../../util/util-helpers";
import ActiveFilters from "../../resource-table/table-components/active-filters";
import TableCard from "../../resource-table/table-components/table-card";
import ResourceTable from "../../resource-table";
import Pagination from "../../resource-table/table-components/pagination";
import NoRecordsTable from "../../no-records-found/no-records-table";
import ModalConfirm from "../../modal/modal-confirm";
import Resources from "../../../../data/services/resources";
import ContactSelectCreateModal from "../../modal/contact-select-create-modal";
import {fieldsToHtml} from "../../../util/util-fields";


export default class ContactsTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: this.props.sortBy ?? "",
            sort: this.props.sort ?? "ASC",
            fields: this.getFields(),
            queryFilterFields: this.props.queryFilterFields ?? {},

            // Create/Edit modal
            createModalShow: false,
            createModalShowAnimation: false,

            // Delete modal
            confirmModal: false,
            confirmModalAnimation: false,

            // Tabs
            selectedTab: "contacts"
        }
    }

    componentDidMount = () => {
        this.getOrganizationId() && this.fetchContacts();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (resourceIsUpdated(this.props.contactResource, prevProps.contactResource)) {
            this.fetchData();
        }
    }

    fetchData = () => {
        if (this.state.selectedTab === "contacts") {
            this.fetchContacts()
        } else if (this.state.selectedTab === "groups") {
            this.fetchGroups()
        }
    }

    fetchContacts = () => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get("user"),
            resource: this.getResourceName(),
            query: this.getQuery(),
        }));
    };

    fetchGroups = () => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get("user"),
            resource: this.props.resourceGroupName,
            query: this.getQuery(),
        }));
    };

    archiveResource = (item) => {
        const text = this.state.selectedTab === "contacts" ?
            `text.confirm_delete_contact_from_${this.getResourceName()}` :
            `text.confirm_delete_group_from_${this.getResourceName()}`
        this.setState({
            confirmText: this.props.translate(text),
            selectedItem: item,
            confirmModal: true
        })
    }

    handleShowDetails = (item) => {
        this.props.dispatch(showModal('ViewContactCard', {ContactID: item.ContactID}));
    };

    handleShowCreateModal = () => {
        this.setState({
            createModalShow: true
        })
    }

    handleShowConfirmDialog = (submit = null) => {
        this.setState({
            confirmModal: submit
        })
    };

    hideConfirmDialog = () => {
        this.setState({confirmModal: false})
    };

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.fetchData()
        })
    }

    handleFilterClear = () => {
        this.setState({
            queryFilterFields: this.props.queryFilterFields,
        }, () => {
            this.fetchData()
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.fetchData();
        })
    };

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => {
            this.fetchData();
        })
    };


    handleTabChange = (tab) => {
        this.setState({
            sort: "ASC",
            sortBy: '',
            offset: 0,
            limit: 10,
            selectedTab: tab
        }, () => {
            this.fetchData()
        })
    }

    getGroupFields = () => ({
        ContactGroup: new Field('ContactGroup', '', [], false, 'text'),
        ContactGroupEmail: new Field('ContactGroupEmail', '', []),
        SetDate: new Field('SetDate', '', [], false, 'date'),
    })

    getFields = () => {
        return this.props.fields
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            limit: this.state.limit,
            id: this.getOrganizationId(),
            ...this.props.additionalQuery ?? {},
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    getPrimaryKey = () => {
        return this.props.primaryKey
    }

    getOrganizationId = () => {
        return this.props.organizationID;
    }

    getResourceName = () => {
        return this.props.resourceName
    }

    render() {
        const {translate, dialogResource, customActions, hideDefaultDeleteAction = false, hideDefaultViewAction = false} = this.props;

        const dataItems = this.props.isCreate ? this.props.Contacts : getProp(dialogResource, "data.list", [])
        const queryFilterFieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.queryFilterFields,{limit: new Field('limit', 10, [''], false, 'hidden', {hideLabel: true, labelType: "float"})})), translate, this.handleFilterInputChange, {})
        const isSortHidden = this.props.isCreate

        const count = getProp(dialogResource, "data.count", 0);
        const isLoading = dialogResource.isLoading;

        return (
            <React.Fragment>
                <div className={"m-3"}>
                    <PageHeader
                        title={this.props.title ?? translate("page.heading.Contacts")}
                        titleClass="text-tm-gray-900 mr-5 text-2xl"
                        buttonLabel={translate("btn.add/create")}
                        onButtonClick={() => this.handleShowCreateModal()}
                        buttonHidden={!checkPerm(this.getResourceName(), CREATE_PERM)}
                    >
                        <button className="btn btn-header" onClick={this.fetchData}>
                            <ArrowPathIcon className="w-5 h-5" />
                        </button>

                    </PageHeader>
                </div>


                {this.props.resourceGroupName && (
                    <ul className="flex border-y border-tm-gray-300 my-5">
                        <li className="nav-item">
                            <button
                                className={" nav-link" + (this.state.selectedTab === "contacts" ? " active" : "")}
                                onClick={() => this.handleTabChange("contacts")}>{translate("tab.Contacts")}</button>
                        </li>
                        <li className="nav-item">
                            <button
                                className={" nav-link" + (this.state.selectedTab === "groups" ? " active" : "")}
                                onClick={() => this.handleTabChange("groups")}>{translate("tab.Groups")}</button>
                        </li>
                    </ul>
                )}

                {this.props.queryFilterFields && (
                    <ActiveFilters
                        filterFields={this.state.queryFilterFields}
                        onLabelClick={this.handleFilterInputChange}
                        onClearFiltersClick={this.handleFilterClear}
                        translate={translate}
                    />
                )}

                {this.state.selectedTab === "contacts" && (
                    <TableCard addClass={'relative'}>
                        {this.props.queryFilterFields && (
                            <div className="grid grid-cols-5 p-3">
                                {queryFilterFieldsHtml}
                            </div>
                        )}

                        <ResourceTable
                            addClass="rounded-card"
                            data={dataItems}
                            count={this.props.isCreate ? 0 : count}

                            commonTable={true}

                            fields={this.state.fields}
                            translate={this.props.translate}
                            isLoading={isLoading}

                            limit={this.state.limit}
                            offset={this.state.offset}
                            page={this.state.paginationPage}
                            paginationButtonLimit={5}
                            onOffsetChange={this.handleUpdateOffset}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={isSortHidden ? null : this.handleUpdateSort}

                            onView={(!hideDefaultViewAction && checkPerm(Resources.Contacts, READ_PERM)) ? this.handleShowDetails : null}
                            onDelete={(!hideDefaultDeleteAction && checkPerm(Resources.Contacts, DELETE_PERM)) ? this.archiveResource : null}
                            actions={customActions}
                        />

                        {!(!dataItems.length && !isLoading) && (
                            <div
                                className="bg-inverse px-4 py-3 flex items-center justify-between border-t border-tm-gray-300 sm:px-6 h-16 rounded-b-lg"
                            >

                                <Pagination
                                    count={count}
                                    isLoading={isLoading}
                                    handleQueryChange={
                                        (name, value, currentPage) => name === "offset"
                                            ? this.handleUpdateOffset(value, currentPage)
                                            : this.handleFilterInputChange(name, value)
                                    }
                                    pageOffset={this.state.offset}
                                    queryFields={this.state.queryFilterFields}
                                    translate={translate}
                                />
                            </div>
                        )}

                        <NoRecordsTable
                            show={(dataItems.length === 0) && !isLoading}
                            canCreate={false}
                            title={translate("text.no_matching_records")}
                        />
                    </TableCard>
                )}

                {this.state.selectedTab === "groups" && (
                    <TableCard>
                        <ResourceTable
                            addClass="rounded-card"
                            data={dataItems}
                            count={this.props.isCreate ? 0 : count}

                            commonTable={true}

                            fields={this.getGroupFields()}
                            translate={this.props.translate}
                            isLoading={isLoading}

                            limit={this.state.limit}
                            offset={this.state.offset}
                            page={this.state.paginationPage}
                            paginationButtonLimit={5}
                            onOffsetChange={this.handleUpdateOffset}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onDelete={checkPerm(Resources.ContactGroups, DELETE_PERM) ? this.archiveResource : null}
                        />

                        <NoRecordsTable
                            show={(dataItems.length === 0) && !isLoading}
                            canCreate={false}
                            title={translate("text.no_matching_records")}
                        />
                    </TableCard>
                )}

                {this.state.createModalShow && (
                    <ContactSelectCreateModal
                        {...this.props}
                        show={this.state.createModalShow}
                        resourceGroupName={this.props.resourceGroupName}
                        title={'Add Contact'}
                        widthClass={'max-w-7xl'}
                        hideSystemUser={this.props.hideSystemUser}
                        disableContactCreate={this.props.disableContactCreate}
                        disableMemberOfOrganization={this.props.disableMemberOfOrganization}
                        dialogTableSelectFields={this.props.dialogTableSelectFields}
                        selectableCollumn={this.props.selectableCollumn}
                        systemUserQuery={this.props.systemUserQuery}
                        // ADDITIONAL FIELDS
                        additionalFields={this.props.additionalFields ?? {
                            DepartmentID: new Field("DepartmentID", '', ['empty'], false, 'select', {
                                addContainerClass: 'relative md:col-span-2 mb-3'
                            }),
                            Notes: new Field("Notes", '', []),
                            OrganizationID: new Field("OrganizationID",  this.getOrganizationId(), !!this.props.organizationID ? ['empty'] : [''], false, 'hidden'),
                        }}

                        // HELPERS
                        query={this.getQuery()}
                        ExcludeIDs={this.state.selectedTab === "contacts" ? dataItems.map(it => it.ContactID) : dataItems.map(it => it.ContactGroupID)}

                        // HANDLERS
                        onClose={() => this.setState({createModalShow: false})}
                        handleAddExistingGroup={(item, additionalFields) => {
                            if (this.props.onAddExistingGroup) {
                                this.props.onAddExistingGroup(item, additionalFields)
                                this.setState({createModalShow: false})
                            } else {
                                this.props.dispatch(createDialogResource({
                                    user: LocalStorage.get('user'),
                                    params: {
                                        ContactGroupID: item.ContactGroupID,
                                        id: this.getOrganizationId(),
                                        DriverID: this.getOrganizationId(),
                                        ...additionalFields,
                                    },
                                    query: this.getQuery(),
                                    resource: this.props.resourceGroupName,
                                    piggyResource: this.props.resourceGroupName,
                                    errorMessage: true, successMessage: `You added ${item.ContactGroupName}`,
                                }));
                                this.setState({createModalShow: false})
                            }
                        }}
                        handleAddExistingContact={(item, additionalFields) => {
                            if (this.props.onAddExistingContact) {
                                this.props.onAddExistingContact(item, additionalFields)
                                this.setState({createModalShow: false})
                            } else {
                                this.props.dispatch(createDialogResource({
                                    user: LocalStorage.get('user'),
                                    params: {
                                        ContactID: item.ContactID,
                                        id: this.getOrganizationId(),
                                        ...additionalFields,
                                        ContactLocationNotes: additionalFields.Notes
                                    },
                                    query: this.getQuery(),
                                    resource: this.getResourceName(),
                                    piggyResource: this.getResourceName(),
                                    errorMessage: true, successMessage: `You added ${item.FirstName} ${item.LastName}`,
                                }));
                                this.setState({createModalShow: false})
                            }
                        }}

                        handleCreateNewContact={(fields, phones, files, additionalFields) => {
                            if (this.props.onCreateNewContact) {
                                this.props.onCreateNewContact(fields, phones, files, additionalFields)
                            } else {
                                let filesToUpload = null;
                                if (!!files) {
                                    filesToUpload = [files]
                                }

                                this.props.dispatch(createDialogResource({
                                    user: LocalStorage.get('user'),
                                    params: Object.assign({},
                                        {
                                            ...additionalFields,
                                            ...fields,
                                            Phones: phones,
                                            HighImportance: fields.HighImportance?.value ? 1 : 0,
                                            Roles: fields.Roles ?? [],
                                        }, {
                                            ContactID: -1,
                                            id: this.getOrganizationId()
                                        },
                                    ),
                                    errorMessage: true,
                                    successMessage: `You created and added ${fields.FirstName} ${fields.LastName}`,
                                    query: this.getQuery(),
                                    resource: this.getResourceName(),
                                    piggyResource: this.getResourceName(),
                                    file: filesToUpload,
                                    fileResource: Resources.ContactImage
                                }));
                                this.setState({createModalShow: false});
                            }
                        }}
                    />
                )}

                <ModalConfirm
                    title={translate("text.Confirm")}
                    show={!!this.state.confirmModal}
                    text={this.state.confirmText}
                    onClose={this.hideConfirmDialog}
                    buttonLabel={translate("btn.confirm")}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={() => {
                        this.setState({
                            ...returnOffsetAndPagination(getProp(dialogResource, 'data.list', []), this.getQuery(), this.state.paginationPage),
                        }, () => {
                            if (this.state.selectedTab === "contacts") {
                                this.props.onDelete(this.state.selectedItem, this.getQuery())
                            } else {
                                this.props.onDeleteGroup(this.state.selectedItem, this.getQuery())
                            }
                            this.hideConfirmDialog(false)
                        })
                    }}
                />
            </React.Fragment>
        )
    }
}
