import React from "react";
import {XCircleIcon} from "@heroicons/react/20/solid";
import {classNames} from "../../../../util/util-helpers";
import Badge from "../../../badge";

export default function ActiveFilters(props) {
    const {clearButtonLabel, translate, filterFields, onLabelClick, persistFilters, excludedFields = ['limit', 'offset', 'sort', 'sortBy']} = props;

    const activeFilters = Object.values(filterFields).filter(it => {
        return !excludedFields.includes(it.name) && (it.value === 0 || !!it.value) && !it?.metadata?.isRequiredFilter;
    });

    const filterLabels = activeFilters
        .filter((item) => !item?.metadata?.isRequiredFilter)
        .map((item, i) => {
            const canRemove = !(persistFilters ? persistFilters.includes(item.name) : false);

            return (
                <div
                    key={i}
                    className="sm:mt-0"
            >
                    <div className="flex flex-wrap items-center">
                        <Badge
                            onClick={() => canRemove ? onLabelClick(item.name, "", "active-filters") : null}
                        type="custom"
                        addClass={
                            classNames(
                                `bg-inverse border border-tm-gray-300 flex items-center space-x-2 group`,
                                canRemove ? "cursor-pointer hover:bg-tm-gray-100" : undefined
                            )}
                    >
                        <span>
                            {
                                translate(`text.${item?.metadata?.activeFilterLabel ?? (item?.metadata?.label ? item.metadata.label : item.name)}`)}
                        </span>
                        {canRemove && (
                            <XCircleIcon className={'h-5 w-5 text-tm-gray-400 relative -right-1 group-hover:text-red-600'}/>
                        )}
                    </Badge>
                </div>
            </div>
        )
    })

    const clearButtonLabelText = clearButtonLabel ?? translate("text.ClearAll")

    return (
        !!activeFilters.length && (
            <div
                className={
                    classNames(
                        "px-1 sm:flex sm:items-center space-x-3",
                        props.addClass
                    )
                }
            >
                <h3 className="text-xs font-semibold uppercase flex flex-shrink-0 flex-nowrap tracking-wide text-tm-gray-500">
                    Active filters
                </h3>

                <div className="hidden w-px h-5 bg-tm-gray-300 sm:block sm:ml-4"/>

                {filterLabels}

                {filterLabels.length > 1 && (
                    <React.Fragment>
                        <div className="hidden w-px h-5 bg-tm-gray-300 sm:block sm:ml-4"/>

                        <button
                            className="btn btn-text font-semibold uppercase flex flex-shrink-0 flex-nowrap"
                            onClick={() => props.onClearFiltersClick()}
                        >
                            {clearButtonLabelText}
                        </button>
                    </React.Fragment>
                )}
            </div>
        )
    )
}
