import React, {useEffect} from 'react';
import {HandRaisedIcon} from '@heroicons/react/24/outline'
import {classNames, getProp} from "../../../util/util-helpers";
import {useDispatch, useSelector} from "react-redux";
import LocalStorage from "../../../../util/localStorage";
import Resources from "../../../../data/services/resources";
import {showGlobalModal} from "../../../../data/actions/ui";
import {LoaderSmall} from "../../loader";
import {getGrabTaskResource} from "../../../../data/actions/taskResource";

const TaskGrab = () => {
    const dispatch = useDispatch();
    const secondTask = useSelector((state) => state.secondTask)
    const tasks = getProp(secondTask, 'grab', [])
    const grabCount = tasks.grabCount;
    const hasGrabCountTwoDigits = grabCount > 9;
    const isGrabLoading = secondTask.isGrabLoading;

    const notifications = useSelector((state) => state.notifications)
    const notificationsCount = getProp(notifications, "data.notifications.count", 0)
    const notificationsList = getProp(notifications, "data.notifications.list", [])

    const fetchData = () => {
        dispatch(getGrabTaskResource({
            user: LocalStorage.get("user"),
            resource: Resources.DashboardTasks,
            request: 'GET'
        }))
    }

    const grabTask = () => {
        dispatch(getGrabTaskResource({
            user: LocalStorage.get("user"),
            resource: Resources.TaskGrab,
            request: 'POST'
        }));
    }

    useEffect(() => {
        if (tasks.task?.TaskID) {
            dispatch(showGlobalModal('GrabbedTask', tasks))
        }
        if (tasks.task === null) {
            dispatch(showGlobalModal('GrabbedTask', tasks))
        }
    }, [tasks]);

    useEffect(() => {
        if (notificationsList[notificationsCount]?.Title === "New tasks on a board") {
            fetchData()
        }
    }, [notifications])

    return (
        <React.Fragment>
            <div className="flex ml-auto">
                <>
                    <div className="relative">
                        <button
                            type="button"
                            className={"icon-btn z-20"}
                            disabled={!grabCount}
                            onClick={() => grabTask()}
                        >
                            {!!grabCount && (
                                <span
                                    className={
                                        classNames(
                                            "absolute z-10 flex -top-2.5 -right-2",
                                            hasGrabCountTwoDigits ? "h-6 w-6" : "h-5 w-5"
                                        )
                                    }
                                >
                                                  <div
                                                      className="absolute z-10 font-bold inset-0 flex items-center justify-center text-primary-contrast">{[grabCount]}</div>
                                                  <span
                                                      className="animate-ping absolute z-10 inline-flex h-full w-full rounded-full bg-primary opacity-25"></span>
                                                  <span
                                                      className={
                                                          classNames(
                                                              "relative inline-flex rounded-full bg-primary",
                                                              hasGrabCountTwoDigits ? "h-6 w-6" : "h-5 w-5"
                                                          )
                                                      }
                                                  />
                                            </span>
                            )}

                            {!isGrabLoading && (
                                <>
                                    {(tasks.task !== null && !tasks?.task?.TaskID) && (
                                        <HandRaisedIcon className="h-6 w-6"/>

                                    )}

                                    {(tasks.task === null || !!tasks?.task?.TaskID) && (
                                        <div>
                                             <span className="flex flex-inline">
                                                 <LoaderSmall addClass={"mt-1"} disableContainer/>
                                             </span>
                                        </div>
                                    )}
                                </>
                            )}

                            {!!isGrabLoading && (
                                <div>
                                     <span className="flex flex-inline">
                                         <LoaderSmall addClass={"mt-1"} disableContainer/>
                                     </span>
                                </div>
                            )}
                        </button>
                    </div>
                </>
            </div>
        </React.Fragment>
    )
}

export default TaskGrab;
