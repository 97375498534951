import React, {Component} from "react";
import {connect} from "react-redux";
import {Field, FieldsManager} from "../../../../../data/services/fields";
import {updateResource} from "../../../../../data/actions/resource";
import LocalStorage from "../../../../util/localStorage";
import {LoaderSmall} from "../../../loader";
import Cron from "../../../cron";
import FieldDate from "../../../fields/field-date";
import FieldText from "../../../fields/field-text";
import FieldTextarea from "../../../fields/field-textarea";
import ModalFooter from "../../modal-footer";
import {toggleBodyScroll} from "../../../../util/util-vanilla";
import Resources from "../../../../../data/services/resources";
import {hideModal} from "../../../../../data/actions/ui";

class UpdateRepeatTasksDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: this.getFields(),
            selectedRepeatOption: this.props.items.EndDate ? "EndDate" : this.props.items.EndAfter ? "EndAfter" : "none",
            canSubmit: false
        };
        this.escFunction = this.escFunction.bind(this);
    }

    componentDidMount() {
        toggleBodyScroll()
        document.addEventListener("keydown", this.escFunction, false);
    }

    componentWillUnmount() {
        toggleBodyScroll()
        document.removeEventListener("keydown", this.escFunction, false);
    }

    escFunction(event) {
        if (event.keyCode === 27) {
            this.hideModal()
        }
    }

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value), canSubmit: true});
    };

    createItem = () => {
        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                this.props.dispatch(updateResource({
                    user: LocalStorage.get('user'),
                    params: FieldsManager.getFieldKeyValues(this.state.fields),
                    query: {
                        offset: 0,
                        limit: 10,
                        sort: "ASC",
                        sortBy: "TaskName",
                        query: '',
                    },
                    resource: Resources.RepeatTasks,
                    piggyResource: Resources.RepeatTasks,
                    errorMessage: true, successMessage: `Repeat Task has been updated.`,
                }));
                this.hideModal();
            }
        })
    }

    getFields = () => {
        return {
            Cron: new Field('Cron', this.props.items.Cron, ['empty']),
            id: new Field('id', this.props.items.TaskRepeatID, ['empty']),
            EndDate: new Field("EndDate", this.props.items.EndDate, []),
            EndAfter: new Field("EndAfter", this.props.items.EndAfter, ['integer_not_require']),
            Notes: new Field("Notes", this.props.items.Notes, []),
        }
    }

    hideModal = () => {
        this.props.dispatch(hideModal())
    }

    render() {
        const {translate} = this.props;

        return (
            <React.Fragment>
                <div className="modal-open">
                    <div className={`modal anim-drop-down ${this.props.ui.HideModalAnimation ? "anim-up" : ""}`}>
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="text-lg">{translate("text.update_repeat_task")}</h5>
                                    <button type="button" className="close" aria-label="Close"
                                            onClick={() => {
                                                this.hideModal()
                                            }}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                {this.props.isLoading && (
                                    <div className="p-5 text-center">
                                        <LoaderSmall/>
                                    </div>
                                )}
                                <div className="modal-body">
                                    <div className="row mt-2">
                                        <div className="col-12">
                                            <Cron
                                                onChange={(e) => this.handleInputChange(this.state.fields.Cron.name, e)}
                                                value={this.state.fields.Cron?.value}
                                                showResultText={true}
                                                options={{
                                                    //headers: [HEADER.MONTHLY, HEADER.WEEKLY, HEADER.DAILY]
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col">
                                            <p className="text-tm-gray-500 text-small ">{translate("field.None")}</p>
                                            <input
                                                type={"radio"}
                                                value={"none"}
                                                name={'Repeat'}
                                                checked={this.state.selectedRepeatOption === "none"}
                                                onChange={() => {
                                                    let fields = this.state.fields
                                                    fields.EndDate.value = ''
                                                    fields.EndAfter.value = ''
                                                    this.setState({fields: fields, selectedRepeatOption: "none"})
                                                }}
                                            />
                                        </div>
                                        <div className="col">
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col">
                                            <p className="text-tm-gray-500 text-small ">{translate("field.EndDate")}</p>
                                            <input
                                                type={"radio"}
                                                value={"EndDate"}
                                                name={'Repeat'}
                                                checked={this.state.selectedRepeatOption === "EndDate"}
                                                onChange={() => this.setState({
                                                    selectedRepeatOption: "EndDate",
                                                    fields: FieldsManager.updateField(this.state.fields, "EndAfter", '')
                                                })}
                                            />
                                        </div>
                                        <div className="col">
                                            <span className="form-group has-float-label mb-3">
                                                <FieldDate
                                                    showTimeSelect={false}
                                                    addClass={"form-control text-center"}
                                                    onChange={this.handleInputChange}
                                                    {...this.state.fields.EndDate}
                                                    disabled={this.state.selectedRepeatOption !== "EndDate"}
                                                />
                                                <span>{translate('field.EndDate')}</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col">
                                            <p className="text-tm-gray-500 text-small ">{translate("field.EndAfter")}</p>
                                            <input
                                                type={"radio"}
                                                value={"EndAfter"}
                                                name={'Repeat'}
                                                checked={this.state.selectedRepeatOption === "EndAfter"}
                                                onChange={(e) => this.setState({
                                                    selectedRepeatOption: "EndAfter",
                                                    fields: FieldsManager.updateField(this.state.fields, "EndDate", '')
                                                })}
                                            />
                                        </div>
                                        <div className="col">
                                            <label className="form-group has-float-label mb-3">
                                                <FieldText
                                                    showTimeSelect={false}
                                                    addClass={"form-control text-center"}
                                                    onChange={this.handleInputChange}
                                                    {...this.state.fields.EndAfter}
                                                    disabled={this.state.selectedRepeatOption !== "EndAfter"}
                                                />
                                                <span>{translate('field.EndAfter')}</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <label className='form-group has-float-label mb-3'>
                                                <FieldTextarea
                                                    {...this.state.fields.Notes}
                                                    onChange={this.handleInputChange}
                                                    addClass={"form-control whitespace-pre-wrap"}
                                                />
                                                <span>{translate("field.Notes")}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <ModalFooter
                                    closeButtonLabel={translate("btn.cancel")}
                                    onClose={this.hideModal}
                                    buttonDisabled={!this.state.canSubmit}
                                    buttonLabel={translate("btn.save")}
                                    onButtonClick={this.state.canSubmit && this.createItem}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {!this.props.hideBackdrop && (
                    <div
                        className={`modal-backdrop anim-fade-in ${this.props.ui.HideModalAnimation ? "anim-fade-out" : ""}`}/>
                )}
            </React.Fragment>
        );
    }
}

export default connect(state => state)(UpdateRepeatTasksDialog);
