import React, {Component} from 'react'
import Resources from '../../../data/services/resources'
import InfoTab from './infoTab'
import {showGlobalModal} from '../../../data/actions/ui'
import LocalStorage from '../../../util/localStorage'
import {Field} from '../../../data/services/fields'
import {getCountTaskResource} from '../../../data/actions/taskResource'
import GoBackButton from "../../../common/components/layout/layout-components/go-back-button";
import {download} from '../../../data/actions/download'
import Page from "../../../common/components/layout/layout-components/page";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import MobileTabs from "../../../common/components/tabs-navigation/mobile-tabs";
import {currentDate} from '../../../common/util/util-dates'
import {getThirdResource} from "../../../data/actions/thirdResource";
import Layout from "../../../common/components/layout";
import NavResponsive from "../../../common/components/nav-responsive";
import {connect} from "react-redux";
import {getResource} from "../../../data/actions/resource";


class TrackingSingle extends Component {

    constructor(props) {
        super(props)

        const tabs = [
            {
                name: 'TrackingInfo',
                resource: "tracking",
                current: true,
                visible: true
            }
        ]

        const currentTab = this.getCurrentTab();
        tabs.map(it => {
            it.current = it.resource === currentTab;

            return it;
        })

        this.state = {
            // Tabs
            tabs: tabs,
            selectedTab: currentTab,

            // Fields
            isDirty: false,
            // Modals
            breakpoint: {}
        }
    }

    /** Lifecycle
     ================================================================= */
    // componentDidMount() {
    //     this.fetchData()
    // }

    /** Data Events
     ================================================================= */
    // fetchData = () => {
    //     this.props.dispatch(getResource({
    //         user: LocalStorage.get('user'),
    //         resource: Resources.TrackingRoutes,
    //         query: {
    //             id: this.getID()
    //         }
    //     }))
    // }

    /** UI Events
     ================================================================= */
    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        })
    }

    handleUploadDocument = (files) => {
        let fields = this.state.fields_documents
        fields = fields.concat(files)
        this.setState({fields_documents: fields})
    }

    handleDeleteDocument = (item) => {
        let fields = this.state.fields_documents.filter(it => it.preview !== item.preview)
        this.setState({fields_documents: fields})
    }

    handleTabChange = (resource) => {
        if (resource === this.state.selectedTab) {
            return false
        }

        if (this.state.isDirty) {
            this.setState({
                confirmModal: true,
                reserveTab: resource
            })
        } else {
            this.tabChange(resource)
        }
    }

    tabChange = (resource) => {
        this.props.history.push('?tab=' + resource)
        this.setState({
            confirmModal: false,
            isDirty: false,
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource
                return it
            }),
            selectedTab: resource
        })
    }

    handleCreateNewTask = () => {
        this.props.dispatch(showGlobalModal('createTaskDialog', {taskResource: this.props.match.url}))
    }

    handleToggleResourceTasksModal = () => {
        this.setState({
            resourceTaskModalOpen: !this.state.resourceTaskModalOpen
        })
    }

    /** Helpers
     ================================================================= */
    canSwapTabsHandler = (isDirty = true) => {
        this.setState({isDirty: isDirty})
    }

    getNotificationFields = () => {
        return {
            ContactID: new Field('ContactID', '', []),
            ContactIDs: new Field('ContactIDs', '', []),
            CustomerLoadBooked: new Field('CustomerLoadBooked', '', [], false, 'checkbox'),
            CustomerPickupComplete: new Field('CustomerPickupComplete', '', [], false, 'checkbox'),
            CustomerDeliveryComplete: new Field('CustomerDeliveryComplete', '', [], false, 'checkbox'),
            EDI: new Field('EDI', '', [], false, 'checkbox'),
            ShipperDeliveryComplete: new Field('ShipperDeliveryComplete', '', [], false, 'checkbox'),
            ConsigneePickupComplete: new Field('ConsigneePickupComplete', '', [], false, 'checkbox'),
            ShipperTruckRadiusCheckbox: new Field('ShipperTruckRadiusCheckbox', '', []),
            ShipperTruckRadius: new Field('ShipperTruckRadius', '', ['integer_not_require']),
            ConsigneeTruckRadius: new Field('ConsigneeTruckRadius', '', ['integer_not_require']),
            ConsigneeTruckRadiusCheckbox: new Field('ConsigneeTruckRadiusCheckbox', '', []),
            SendCustomerNotifications: new Field('SendCustomerNotifications', '', [], false, 'checkbox'),
        }
    }

    getID = () => {
        return this.props.match.params.TrackingRoutesID
    }

    getCurrentTab = () => {
        const query = new URLSearchParams(this.props.location.search)
        const tab = query.get('tab');

        return tab ?? 'tracking'
    }

    getCustomerNameFromQuery = () => {
        const query = new URLSearchParams(this.props.location.search)
        return query.get('customer');
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, history, resource} = this.props;

        console.log("id",this.getID())
        return (
            <Layout
                onBreakpoinChange={this.handleBreakpointChange}
                {...this.props}
                hasFooter={
                    this.state.selectedTab === 'tracking'
                }
                canSubmit={!this.state.isDirty}
            >
                <div>
                    <div className="flex justify-between items-center">
                        <div className="flex justify-start items-center">
                            <GoBackButton
                                canSubmit={this.state.isDirty}
                                translate={translate}
                                history={history}
                                path={'/home'}
                            />
                            <h1 className={'mr-5 text-3xl ml-2'}>
                                {translate('text.tracking') + " - " + this.getID()}
                            </h1>
                        </div>
                    </div>

                    <div className="mb-5 hidden sm:block relative z-[33]">
                        <NavResponsive
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>

                    <div className="mb-5">
                        <MobileTabs
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>
                </div>

                <div className="">
                    <div className="">
                        {this.state.selectedTab === 'tracking' && (
                            <InfoTab
                                canSwapTabsHandler={this.canSwapTabsHandler}
                                resource={resource}
                                translate={translate}
                                dispatch={this.props.dispatch}
                                ID={this.getID()}
                            />
                        )}

                    </div>
                </div>


                <ModalConfirm
                    type="default"
                    title={this.props.translate("dialog_heading.unsaved_changes_on_tab")}
                    show={!!this.state.confirmModal}
                    onClose={() => this.setState({confirmModal: false})}
                    buttonLabel={translate("btn.proceed")}
                    closeButtonLabel={translate('btn.stay_on_current_tab')}
                    translate={translate}
                    onConfirm={() => this.setState({
                        selectedTab: this.state.reserveTab,
                        confirmModal: false,
                        isDirty: false,
                        tabs: this.state.tabs.map((it) => {
                            it.current = it.resource === this.state.reserveTab
                            return it
                        })
                    })}
                >
                    <p>{translate("text.unsaved_changes_on_tab")}</p>
                    <p>{translate("text.unsaved_changes_on_tab_line_2")}</p>
                </ModalConfirm>
            </Layout>
        )
    }
}


export default connect(state => state)(TrackingSingle)
