import React, {Component} from 'react';
import Select, {components, createFilter} from 'react-select';
import {getCustomStyles} from "../../../../data/themes";
import {scrollIntoView} from "../../../util/util-vanilla";
import { classNames } from "../../../util/util-helpers";

const CustomOption = props => {
    return <components.Option {...props} />;
};

export default class FieldSelectSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const pairs = this.props.values ? this.props.values : {};

        let values = Object.keys(pairs);

        if (!this.props.omitSort) {
            values = values.sort((a, b) => {
                return (pairs[a] == pairs[b]) ? 0 : ((pairs[a] > pairs[b]) ? 1 : -1);
            });
        }

        if (!!this.props.valueSort) {
            values = Object.keys(pairs).sort((a, b) => {
                return pairs[a] - pairs[b]
            });
        }

        if (!!this.props.reverseValueSort || this.props.name === "Year") {
            values = Object.keys(pairs).sort((a, b) => {
                return pairs[b] - pairs[a]
            });
        }

        if (!!this.props.keySort) {
            values = values.sort((a, b) => a - b);
        }

        if (!!this.props.reverseKeySort) {
            values = values.sort((a, b) => b - a);
        }

        values = values.map((key, i) => ({value: key, label: pairs[key]}));

        const filterConfig = {
            ignoreCase: true,
            ignoreAccents: true,
            trim: true,
            matchFrom: 'any',
        };

        return (
            <React.Fragment>
                <Select
                    ref={this.props.innerRef}
                    placeholder={this.props.placeholder ? this.props.placeholder : "Select..."}
                    isMulti={this.props.isMulti}
                    onChange={(selectedOption) => {
                        if (this.props.isMulti) {
                            this.props.onChange(this.props.name, selectedOption);
                        } else {
                            this.props.onChange(this.props.name, selectedOption ? (!this.props.options ? selectedOption.value : selectedOption) : "");
                        }
                    }}
                    onBlur={this.props.onBlur}
                    onFocus={(event) => {
                        if (this.props.onFocus) {
                            this.props.onFocus(event);
                        }

                        scrollIntoView(event);
                    }}
                    className={
                        classNames(
                            "select-css-search p-0",
                            !!this.props.addClass ? this.props.addClass : undefined,
                            this.props.errorMessage ? "is-invalid" : undefined
                        )
                    }
                    styles={getCustomStyles()}
                    value={!this.props.options && !this.props.isMulti ? (this.props.value ? {
                        value: this.props.value,
                        label: pairs[this.props.value]
                    } : null) : this.props.value}
                    isClearable={!!this.props.all || this.props.isClearable}
                    isSearchable={true}
                    isDisabled={this.props.disabled}
                    name={this.props.name}
                    options={this.props.options ? this.props.options : values}
                    filterOption={createFilter(filterConfig)}
                    components={{Option: CustomOption}}
                    autoFocus={this.props.autoFocus}
                    menuPlacement={this.props.menuPlacement}
                    menuPortalTarget={this.props.hasPortal ? document.body : null}
                    defaultMenuIsOpen={this.props.defaultMenuIsOpen}
                    inputValue={this.props.inputValue} // input for filtering options
                    onInputChange={(value) => {
                        if (this.props.onInputChange) {
                            this.props.onInputChange(this.props.name, value);
                        }
                    }}
                    readonly={this.props.readOnly}
                />

                {!!this.props.errorMessage && (
                    <div className="text-red-600 font-semibold">
                        {this.props.translate ? this.props.translate(this.props.errorMessage) : "Field required*"}
                    </div>
                )}
            </React.Fragment>
        );
    }
}
