import LocalStorage from "./localStorage";
import moment from 'moment';
import {
    COUNTRY_ID_CANADA,
    COUNTRY_ID_USA,
    DEFAULT_DATABASE_DATETIME_FORMAT,
    DEFAULT_LIMIT,
    DEFAULT_OFFSET,
    DEFAULT_QUERY,
    DEFAULT_SORT,
    INVOICE_STATUS_OPEN,
    INVOICE_STATUS_PAID,
    INVOICE_STATUS_SENT,
    TABLE_OPTIONS_SUF
} from "./util-constants";
import {getUserTimeFormat, toFrontDate} from "../common/util/util-dates";
import React from "react";
import {cloneDeep} from "../common/util/util-vanilla";
import {BarsArrowDownIcon, BarsArrowUpIcon} from "@heroicons/react/24/solid";
import {_ENV_MODE} from "./env";
import {Field, FieldsManager} from "../data/services/fields";
import jwt_decode from "jwt-decode";
import Cookie from "./cookie";
import Tippy from "@tippyjs/react";
import printJS from "print-js";
import {getColumns, mergeColumns} from "../common/components/resource-table/table-utils";
import Badge from "../common/components/badge";

export function checkPerm(key, check) {
    const perm = parseInt(getProp(LocalStorage.get('user'), 'permissions.' + key, 0));
    return (perm & check) !== 0;
}

export function getJWT() {
    return ["access_token", "expires_in", "refresh_token", "scope", "token_type"]
        .reduce((memo, it) => {
            memo[it] = Cookie.getCookie(it + "_" + _ENV_MODE);

            return memo;
        }, {});
}

export function openInNewTab(url) {
    window.open(url, '_blank', 'noopener, noreferrer');
}

export function getDecodedJWTJson() {
    let token = getJWT().access_token;
    return token ? jwt_decode(token) : {}
}

export function saveJWTToken(token) {
    for (const [key, value] of Object.entries(token)) {
        Cookie.setCookie(
            key,
            value,
            token?.expires_in
        );
    }
}

export function clearCookies() {
    ["access_token", "expires_in", "refresh_token", "scope", "token_type"].forEach(it => {
        Cookie.setCookie(
            it,
            "",
            -1
        );
    })
}


export function timePickerValueToServerTime(time) {
    if (!time) return "00:00:00";

    return moment(time, getUserTimeFormat()).format("HH:mm:ss");
}

export function timeZoneToUTC(date) {
    let timezone = getUser("Contact.Timezone");

    if (!date || !timezone || !moment(date, DEFAULT_DATABASE_DATETIME_FORMAT).isValid()) return undefined;


    const m = moment.tz(date, DEFAULT_DATABASE_DATETIME_FORMAT, timezone);

    m.utc();

    return m.format(DEFAULT_DATABASE_DATETIME_FORMAT);
}

export function userDateFormatToDateTime(date) {
    const userTimeFormat = getUser("Contact?.DateTimeFormat");

    const dateTime = moment(date, userTimeFormat);

    if (dateTime.isValid()) {
        return dateTime.format(DEFAULT_DATABASE_DATETIME_FORMAT);
    }

    return undefined;
}

export function getProp(object, keys, defaultVal) {
    if (object === undefined || object === null) {
        return defaultVal;
    }
    keys = Array.isArray(keys) ? keys : keys.split('.');
    object = object[keys[0]];
    if (object && keys.length > 1) {
        return getProp(object, keys.slice(1), defaultVal);
    }
    return (object === undefined || object === null) ? defaultVal : object;
}

export function getCurrentTimeSeconds() {
    const d = new Date();
    return Math.round(d.getTime() / 1000);
}

export function numberWithCommasInputChange(n) {
    if (n) {
        let original = n.toString().split(".");
        if (original[1] && original[1].length > 1) {
            return original[0] + (original[1] ? "." + original[1].charAt(0) + original[1].charAt(1) : "");
        } else {
            n = n.replace(/[^0-9.]/g, '');
            n = n.replace(/,/g, "");
            n = n.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return n;
        }
    }
}

export function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
}

// TODO: Put this into useQuery hook
export function getQuery(customQuery, location, primaryKey) {
    const key = location;
    let queries = LocalStorage.get(key) ?? {};
    let storedQuery = queries[key] ?? Object.assign(DEFAULT_QUERY, {sortBy: primaryKey});

    return Object.assign(storedQuery, customQuery);
}


export const resourceIsLoaded = (resource, prevResource) => {
    return !!resource.data && !resource.isLoading && prevResource.isLoading;
}

export const resourceIsCreated = (resource, prevResource = undefined, key = "id") => {
    if (!!prevResource) { // prevResource is for functional components
        if (!!resource?.create?.[key] && resource.create?.[key] !== prevResource?.create?.[key]) {
            return !!resource?.create?.[key];
        }
    } else {
        return !!resource?.create?.id || !!resource?.create?.ids
    }

    return false;
}

export const resourceIsUpdated = (resource, prevResource) => {
    if (prevResource) { // prevResource is for functional components
        if (!!resource?.update && !prevResource?.update) {
            return !!resource?.update;
        }
    } else {
        return !!resource?.update
    }

    return false;
}

export const resourceIsDeleted = (resource, prevResource) => {
    if (prevResource) { // prevResource is for functional components
        if (prevResource.deleteResource === null && resource.deleteResource === true) {
            return !!resource?.deleteResource;
        }
    } else {
        return !!resource?.deleteResource;
    }

    return false;
}

export function getTimeFromServerDate(date) {
    let dateFormat = "HH:mm:ss";

    if (date?.length > 8) {
        dateFormat = "yyyy-mm-dd HH:mm:ss";
    }

    if (!date || date === 'Invalid date') {
        date = "";
    }

    if (moment(date, dateFormat).format(getUserTimeFormat()) === 'Invalid date') {
        return date;
    }

    return moment(date, dateFormat).format(getUserTimeFormat());
}

export function getDefaultTableOptions(fields, tableCustomDefaults, pagePath, translate) {
    const localStorageKey = pagePath + TABLE_OPTIONS_SUF;
    let options = LocalStorage.get(localStorageKey);

    if (!options) {
        options = getDefaultTableOptionsJSON(fields, tableCustomDefaults, translate);
    } else {
        Object.assign(options, {
            icons: {
                IconSortAsc: BarsArrowUpIcon,
                IconSortDesc: BarsArrowDownIcon,
            }
        });
    }

    if (options?.behaviour?.hasVirtualRows === undefined) {
        options.behaviour.hasVirtualRows = true;
    }

    if (options?.style?.isTableLayoutFixed === undefined) {
        if (!options?.style) {
            options.style = {}
        }

        options.style.isTableLayoutFixed = true;
    }

    return options;
}

export function getDefaultTableOptionsJSON(fields, tableCustomDefaults, translate) {
    let options = {
        behaviour: {
            stickyHeader: true,
            rowSelect: false,
            rowHover: true,
            hasVirtualRows: true
        },
        style: {
            stripedRows: true,
            horizontalLines: false,
            verticalLines: false,
            condensed: false,
            floatingActions: true,
            frozenActionColumn: false,
        },
        classes: {
            tdPadding: "h-12 px-3",
            tdWithSubColumnsPadding: "h-16 pl-3 pr-6",
            tdCondensedWithSubColumnsPadding: "h-12 pl-3 pr-6",

            tdPaddingActions: "align-middle pl-3 pr-6",
            verticalLinesClass: "divide-x divide-tm-gray-300",
            horizontalLinesClass: "border-b border-tm-gray-300",
            maxHeightClass: "max-h-[calc(100vh-15.5rem)]"
        },
        columns: getColumns(fields, translate),
        icons: {
            IconSortAsc: BarsArrowUpIcon,
            IconSortDesc: BarsArrowDownIcon,
        }
    };

    options = Object.keys(options).reduce((memo, option) => {
        if (tableCustomDefaults[option]) {
            if (option === "columns") {
                memo["columns"] = mergeColumns(options, tableCustomDefaults);
            } else {
                memo[option] = Object.assign(options[option], tableCustomDefaults[option]);
            }
        } else {
            memo[option] = options[option];
        }

        return memo;
    }, {})

    return options;
}

export function returnOffsetAndPagination(list, query, paginationPage) {
    const {offset, limit, archived} = query

    return archived ? {
        offset: offset,
        paginationPage: paginationPage
    } : {
        offset: (list.length === 1) ? ((offset - limit) >= 0 ? offset - limit : offset) : offset,
        paginationPage: list.length === 1 ? paginationPage === 1 ? paginationPage : paginationPage - 1 : paginationPage
    }
}

export function getUser(keys, defaultVal) {
    const user = LocalStorage.get('user');

    if (!keys && !defaultVal) {
        return user;
    }

    if (!!keys) {
        return getProp(user, keys, defaultVal);
    }

    return defaultVal;
}

export function updateQuery(queryFields, name, value) {
    let queryClone = cloneDeep(queryFields);

    if ("sortBy" === name) {
        if (value === queryClone.sortBy.value) {
            queryClone.sort.value = queryClone.sort.value === 'ASC' ? 'DESC' : 'ASC'
        }
    }

    if ("ALL_FIELDS" === name) {
        Object.values(queryClone).forEach(field => {
            if (!!field?.metadata?.hasActiveBadge) {
                FieldsManager.updateField(queryClone, field.name, "")
            }
        })
    } else {
        queryClone = FieldsManager.updateField(queryClone, name, value);
    }

    return queryClone;
}


export function groupListBySCAC(list, itemName) {
    let groupedList = {};
    list.sort((a, b) => (a.SCAC > b.SCAC) ? 1 : -1).forEach((it) => {
        if (!groupedList[it.SCAC]) {
            groupedList[it.SCAC] = {
                label: "SCAC: " + it.SCAC,
                options: []
            };
        }

        groupedList[it.SCAC].options.push({
            label: it.SCAC + " " + it[itemName + "Number"],
            value: it[itemName + "ID"],
            metadata: it
        });
    });

    return Object.values(groupedList);
}

export function getFullAddressName(it) {
    const address = it?.AddressName

    return [address, it.CityName, [it.State, it.PostalCode].filter(it => !!it).join(" "), it.CountryName].filter(it => !!it).join(", ")
}

export function getDefaultQueryFields(primaryField, translate) {
    return {
        query: new Field('query', '', [''], false, 'search', {
            hideLabel: true,
            hasActiveBadge: true,
            labelType: "float"
        }, {placeholder: translate("text.search")}),
        sort: new Field('sort', DEFAULT_SORT, [''], false, 'hidden'),
        sortBy: new Field('sortBy', primaryField, [''], false, 'hidden'),
        offset: new Field('offset', DEFAULT_OFFSET, [''], false, 'hidden'),
        limit: new Field('limit', DEFAULT_LIMIT, [''], false, 'select', {
            hideLabel: true,
            labelType: "float"
        }, {
            hasPortal: true,
            placeholder: "100",
            menuPlacement: "top"
        })
    }
}

export function getErrorNotificationWithExceptions(result, action) {

    switch (result.data) {
        case "DRIVER_TRUCK_ALREADY_DISPATCH_ANOTHER_LOAD":
            return ({
                title: "Error updating load",
                content: `Driver/Truck already dispatched on load ${result?.metadata[0]?.LoadNumber}.`,
                messageType: "error",
                actions: {
                    hasDismissAction: true,
                    buttons: [
                        {
                            className: "btn-text text-sm",
                            label: `Open load ${result?.metadata[0]?.LoadNumber}`,
                            onClick: () => openInNewTab("/loads/info/" + result?.metadata[0]?.LoadNumber),
                        }
                    ]
                }
            })
        default:
            return ({
                title: action.data.errorMessage === true ? result.data : action.data.errorMessage,
                messageType: "error"
            })
    }
}

export function translateConstant(constant, translate) {
    return Object.keys(constant).reduce((memo, key) => {
        memo[key] = translate("option." + constant[key]);
        return memo;
    }, {});
}

export function concatFullPhoneNumber(phone) {
    const areaCode = phone.AreaCode ? "(" + phone.AreaCode + ")" : "";
    const phoneExtension = phone.PhoneExtension ? "[" + phone.PhoneExtension + "]" : "";
    return [areaCode, phone.PhoneNumber, phoneExtension].join(" ");
}

export function saveTableColumns(pagePath = "", tableOptions = {}) {
    let tableOptionsUpdate = cloneDeep(tableOptions);

    if (tableOptionsUpdate.icons) {
        delete tableOptionsUpdate.icons;
    }

    LocalStorage.set(pagePath + TABLE_OPTIONS_SUF, tableOptionsUpdate)
}

export const renderInvoiceStatusBadge = (it) => {
    if (!it.InvoiceStatus) {
        switch (it.InvoiceStatusID) {
            case INVOICE_STATUS_OPEN:
                it.InvoiceStatus = 'Open';
                break;
            case INVOICE_STATUS_SENT:
                it.InvoiceStatus = 'Sent';
                break;
            case INVOICE_STATUS_PAID:
                it.InvoiceStatus = 'Paid';
                break;
        }
    }
    if (
        (it.InvoiceStatus !== 'Paid')
        &&
        it.IsRevised
        &&
        !!it?.InvoiceSentDate
        &&
        !!it?.RevisedDate
        &&
        moment(it.InvoiceSentDate, DEFAULT_DATABASE_DATETIME_FORMAT).isBefore(moment(it.RevisedDate, DEFAULT_DATABASE_DATETIME_FORMAT))
    ) {
        return (
            <div className="inline-block space-y-0.5">
                <Badge type="warning"
                       className="block flex-grow-0 text-center px-3 leading-4 rounded-btn text-sm font-medium">
                    Revised
                </Badge>

                <div className="text-red-600 font-bold leading-3">
                    {(it?.RevisedDate ? ' ' + toFrontDate(it.RevisedDate) : '')}
                </div>
            </div>
        )
    } else {
        switch (it.InvoiceStatus) {
            case 'Sent':
                return <div className="inline-block space-y-0.5">
                    {!!it.LastDigitalSendEmail && (
                        <Tippy content={it.LastDigitalSendEmail}>
                            <span className="relative">
                                <Badge type="warning"
                                       className="block flex-grow-0 text-center px-3 leading-4 rounded-btn text-sm font-medium">
                                    {it.InvoiceStatus}
                                </Badge>

                                <div className="text-yellow-600 font-bold leading-3">
                                    {(it?.InvoiceSentDate ? ' ' + toFrontDate(it.InvoiceSentDate) : '')}
                                </div>
                            </span>
                        </Tippy>
                    )}
                    {!it.LastDigitalSendEmail && (
                        <>
                            <Badge type="warning"
                                   className="block flex-grow-0 text-center px-3 leading-4 rounded-btn text-sm font-medium">
                                {it.InvoiceStatus}
                            </Badge>

                            <div className="text-yellow-600 font-bold leading-3">
                                {(it?.InvoiceSentDate ? ' ' + toFrontDate(it.InvoiceSentDate) : '')}
                            </div>
                        </>
                    )}
                </div>
            case 'Paid':
                return <div className="inline-block space-y-0.5">
                    <Badge type="success"
                           className="block flex-grow-0 text-center px-3 leading-4 rounded-btn text-sm font-medium">
                        {it.InvoiceStatus}
                    </Badge>

                    <div className="text-green-600 font-bold leading-3">
                        {(it?.InvoicePaidDate ? ' ' + toFrontDate(it.InvoicePaidDate) : '')}
                    </div>
                </div>
            default:
                return <Badge type="info">{it.InvoiceStatus}</Badge>
        }
    }
}

export function downloadDocument(path, documentName) {
    fetch(path)
        .then(response => response.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', documentName);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        })
        .catch(error => {
            console.error('Error downloading PDF:', error);
        });
}

export const onDocumentPrint = (url, type) => {
    switch (type) {
        case 'pdf':
            printJS({
                printable: url,
                type: 'pdf'
            })
            break;
        case 'jpg':
        case 'jpeg':
        case 'gif':
        case 'bmp':
        case 'png':
            printJS({
                printable: url,
                type: 'image'
            })
            break;
        default:
            break;
    }
}

export function getHeaderFilterValues(filters) {
    return filters ? Object.keys(filters)
        .reduce((memo, it) => {
            memo[it] = filters[it].value ? 1 : 0
            return memo
        }, {}) : {}
}

export const getPreFillFromQuery = () => {
    let prefilled = (new URL(document.location)).searchParams;
    prefilled = prefilled.get('prefilled');
    window.history.replaceState({}, document.title, window.location.href.split('?')[0]);

    if (prefilled === "undefined" || !prefilled) {
        return "";
    }

    return prefilled;
}


export const updateContractFields = (fields, name, value) => {
    if (name === 'ContractOnFile') {
        fields.ContractStartDate.disabled = !value
        fields.ContractEndDate.disabled = !value
        fields.ContactNumber.disabled = !value

        fields.ContractStartDate.value = ''
        fields.ContractEndDate.value = ''
        fields.ContactNumber.value = ''

        fields.ContractEndDate.props.minDate = ''
        fields.ContractStartDate.props.maxDate = ''
    }
    if (name === "ContractStartDate") {
        if (!fields.ContractEndDate.props) fields.ContractEndDate.props = {}
        fields.ContractEndDate.props.minDate = value
    }
    if (name === "ContractEndDate") {
        if (!fields.ContractStartDate.props) fields.ContractEndDate.props = {}
        fields.ContractStartDate.props.maxDate = value
    }

    return fields
}

export const updateStateFields = (fields, name, value) => {
    if (name === "CountryID") {
        if ((Number(value) === COUNTRY_ID_USA) || (Number(value) === COUNTRY_ID_CANADA)) {
            fields.StateID.type = 'select'
        } else {
            fields.StateID.type = 'hidden'
            fields.StateID.value = ''
        }
    }

    return fields
}
